const QuizParticipantViewState = {
  NOT_STARTED: 0,
  ANSWERING: 1,
  SUBMITTED: 2,
  SUBMISSION_CONFIRMATION: 3, // Removing
  VIEWING: 4,
  TIMEUP_NOTIFICATION: 5,
}

export default QuizParticipantViewState
