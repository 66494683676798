import axios from 'axios'
import {QuizParticipantViewState} from '../enums'
import QuizSettings from '../model/quiz/QuizSettings'
import QuizQuestionAnswer from '../model/quiz/QuizQuestionAnswer'
const Serializer = require('../JSCore/util/Serializer')

const retrieveQuizForParticipant = async (quizId, isReattempt = false) => {
  try {
    const url = `/api/quiz/getQuizForParticipant?quizId=${quizId}&isLearner=true&isReattempt=${isReattempt}`
    const quizSubmitedData = await axios.get(url)
    console.log(quizSubmitedData?.data)
    const quizParticipantData = quizSubmitedData.data
    const quizQuestionAnswers = []
    const dataToReturn = {
      analyticsId: quizParticipantData.analyticsId,
      attemptCount: quizParticipantData.attemptCount,
      viewStatus:
        quizParticipantData.participantStatus ||
        QuizParticipantViewState.NOT_STARTED,
      quizQuestionAnswers: quizQuestionAnswers,
      isRevealed: quizParticipantData.isRevealed,
    }
    let isMultiSelectEnabledForLearner = false

    quizParticipantData.questionAnswers.forEach(function (questionAnswer) {
      const questionObject = Serializer.default.deSerialize(
        questionAnswer.serializedString,
      )
      questionObject.curateData() // Reg backward compatibility for Quiz question object with z_correctAnswers
      const quizQuestionAnswer = new QuizQuestionAnswer({
        questionObject: questionObject,
        difficultyLevel: questionAnswer.difficultyLevel,
        participantAnswerData: questionAnswer.participantAnswerData
          ? JSON.parse(questionAnswer.participantAnswerData)
          : null,
        isCorrect: questionAnswer.isCorrect,
        questionNo: questionAnswer.questionNo,
        sectionName: questionAnswer.sectionName,
        questionExplanationText: questionAnswer.questionExplanationText,
      })
      if (questionObject.getCorrectAnswers().length > 1) {
        isMultiSelectEnabledForLearner = true
      }
      dataToReturn.isMultiSelectEnabledForLearner = isMultiSelectEnabledForLearner
      quizQuestionAnswers.push(quizQuestionAnswer)
    })

    dataToReturn.quizSettings = new QuizSettings(
      JSON.parse(quizParticipantData.quizMetaData),
    )
    return dataToReturn
  } catch (e) {
    console.log(e)
  }
}

const submitQuizResponsesForParticipant = async (
  quizId,
  participantUserId,
  questionAnswersToSubmit,
  timeSpent,
  isLastAttempt,
  onComplete,
) => {
  try {
    const url = `/api/quiz/submitQuizResponsesForParticipant`
    const dataToSave = {
      quizId: parseInt(quizId, 10),
      participantUserId: participantUserId,
      questionAnswers: [],
      timeSpent: timeSpent,
      isLastAttempt,
    }
    questionAnswersToSubmit.forEach(function (questionAnswer, i) {
      const isCorrect = questionAnswer.isRight(true)

      questionAnswer.setCorrect(isCorrect)
      dataToSave.questionAnswers.push({
        questionNo: questionAnswer.questionNo,
        participantAnswerData: JSON.stringify(
          questionAnswer.getParticipantAnswerData(),
        ),
        isCorrect: isCorrect,
      })
    })
    const resposne = await axios.post(url, dataToSave)
    if (resposne.data) {
      const evaluatedQuestionAnswers = [].concat(questionAnswersToSubmit)
      return {
        evaluatedQuestionAnswers,
        assignedRemedialModules: resposne.data.assignedRemedials,
      }
    }
  } catch (e) {
    console.log(e)
  }
}

const updateQuizParticipantStatus = async (quizId, onUpdateCompleted) => {
  try {
    const url = `/api/quiz/startParticipantQuiz/${quizId}`
    const isOfflineEnabled = false
    const response = await axios.get(url, {
      headers: {
        isOfflineEnabled,
      },
    })
    const result = JSON.parse(response.data)
    if (result) {
      return result
    }
  } catch (e) {
    console.log(e)
  }
}

const saveQuizResponsesForParticipant = async (
  quizId,
  participantUserId,
  questionAnswersToSubmit,
  timeSpent,
  onComplete,
) => {
  try {
    const dataToSave = {
      quizId,
      participantUserId,
      questionAnswers: [],
      timeSpent,
    }

    questionAnswersToSubmit.forEach((questionAnswer, index) => {
      const isCorrect = questionAnswer.isRight(true)
      questionAnswer.setCorrect(isCorrect)
      dataToSave.questionAnswers.push({
        questionNo: questionAnswer.getQuestionNo(),
        participantAnswerData: JSON.stringify(
          questionAnswer.getParticipantAnswerData(),
        ),
        isCorrect: isCorrect,
      })
    })

    const url = `/api/quiz/saveQuizResponsesForParticipant`

    const submitOperation = await axios.post(url, dataToSave)
    console.log(submitOperation.data)
    return submitOperation.data
  } catch (e) {
    console.log(e)
  }
}

const reAttemptQuizForParticipant = async (quizId) => {
  try {
    const url = `/api/quiz/archiveExistingQuizResponse?quizId=${quizId}`
    const response = await axios.get(url)
    console.log(response.data)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export {
  retrieveQuizForParticipant,
  submitQuizResponsesForParticipant,
  updateQuizParticipantStatus,
  saveQuizResponsesForParticipant,
  reAttemptQuizForParticipant,
}
