import QuizQuestionContentItem from './QuizQuestionContentItem'

class AbstractQuizQuestion {
  constructor(config = {}) {
    this.instructions = config.instructions || ''
    this.question = config.question || new QuizQuestionContentItem()
  }

  getInstructions() {
    return this.instructions
  }

  getConstructor() {
    return 'AbstractQuizQuestion'
  }
}

export default AbstractQuizQuestion
