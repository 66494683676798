import React from 'react'
import PropTypes from 'prop-types'
import {useCountDownTimer} from '../../customhooks'

// Returns back string formatted as MM:SS
const formatToTimeString = (milliseconds) => {
  const totalSeconds = Math.round(milliseconds / 1000)
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60

  // Converting 0:60 to 1:00
  if (seconds === 60) {
    seconds = 0
    minutes++
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return `${minutes}:${seconds}`
}

const QuizCountDownTimer = ({allowedDuration, onTimeUpNotification}) => {
  const {remainingDuration, colorThresholdToDuration} = useCountDownTimer({
    onCountDownComplete: onTimeUpNotification,
    countDownDuration: allowedDuration * 60 * 1000,
  })

  const hasReachedThreshold = remainingDuration <= colorThresholdToDuration

  return (
    <div className={'timer'}>
      <div
        className={`remaining-time ${
          hasReachedThreshold ? 'timer-alert' : 'timer-progress'
        }`}>
        {formatToTimeString(remainingDuration)}
      </div>
    </div>
  )
}

QuizCountDownTimer.propTypes = {}

export default QuizCountDownTimer
