import React, {Component} from 'react'

export default class PlaybackIndicator extends Component {
  render() {
    console.log('Play Back', this.props.playbackData)
    if (
      this.props.playbackData.length ===
      0 /*||
            (this.props.playbackData.length === 1 && this.props.playbackData[0].start === 0)*/
    ) {
      return null
    }

    const sections = []
    let marginStart = 0
    this.props.playbackData.forEach((sectionData, i) => {
      const marginLeft = `${this.getAsPercentageOfPlaybackDuration(
          sectionData.start - marginStart,
        )}%`,
        sectionWidth = `${this.getAsPercentageOfPlaybackDuration(
          sectionData.end - sectionData.start,
        )}%`
      sections.push(
        <div
          className="played-section"
          style={{marginLeft: marginLeft, width: sectionWidth}}
          key={i}>
          <div className="section-info">
            {/* {`${Date.formatToTimeString(
							sectionData.start
						)} to ${Date.formatToTimeString(sectionData.end)}`} */}
          </div>
        </div>,
      )
      marginStart = sectionData.end
    }, this)

    return <div className="playback-indicator">{sections}</div>
  }

  getAsPercentageOfPlaybackDuration = (value) => {
    return this.props.playbackDuration > 0
      ? ((100 * value) / this.props.playbackDuration).toFixed(2)
      : 0
  }
}
