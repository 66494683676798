import axios from 'axios'

function getAuthTypeInfoFromUserRef(authToken) {
  if (!authToken) {
    return Promise.reject()
  }
  return getApiUrlFromAuthService(authToken)
    .then((baseUrl = '') => {
      return axios
        .post(
          '/api/account/fetchAuthTypeInfoFromUserRef',
          {
            referrer: document.referrer || '',
            uniqueID: new Date().valueOf(),
            stringParameter: authToken,
          },
          {
            headers: {
              'Content-Type': 'application/json;charset=UTF-8',
              Accept: 'application/json',
            },
          },
        )
        .then((response) => {
          sessionStorage.setItem('baseUrl', baseUrl)
          if (response.data?.authResponse) {
            axios.defaults.headers.common['Access-Token'] =
              response.data.authResponse.accessToken
            sessionStorage.setItem(
              'token',
              response.data.authResponse.accessToken,
            )
            return Promise.resolve(response)
          }
          return Promise.reject(response)
        })
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}

function getApiUrlFromAuthService(authToken) {
  return axios
    .post('https://learntronwebhooks.azurewebsites.net/api/AuthService', {
      stringParameter: authToken,
    })
    .then(({data}) => {
      if (data.isAuthenticated) {
        axios.defaults.baseURL = data.apiUrl
      }
      return Promise.resolve(data.apiUrl)
    })
}

async function getAuthDetailsFromToken() {
  try {
    const accessToken = sessionStorage.getItem('token')
    const baseUrl = sessionStorage.getItem('baseUrl')
    axios.defaults.headers.common['Access-Token'] = accessToken
    axios.defaults.baseURL = baseUrl
    const response = await axios.post(`${baseUrl}/api/account/retrieveUser`, {
      accessToken: accessToken,
    })
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export {
  getAuthTypeInfoFromUserRef,
  getAuthDetailsFromToken,
  getApiUrlFromAuthService,
}
