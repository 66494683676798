import axios from 'axios'

const fetchDocumentActivity = async (activityId) => {
  try {
    const url = `/api/activity/getDocumentActivityDetails/${activityId}/false`
    const isOfflineEnabled = false
    const response = await axios.get(url, {
      headers: {
        isOfflineEnabled,
      },
    })
    console.log(response.data)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

const fetchInteractiveActivity = async (activityId) => {
  try {
    const url = `/api/activity/getDocumentActivityDetails/${activityId}/false`
    const isOfflineEnabled = false
    const response = await axios.get(url, {
      headers: {
        isOfflineEnabled,
      },
    })
    console.log(response.data)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

const fetchVideoActivity = async (activityId) => {
  try {
    const url = `/api/activity/getVideoActivityDetails/${activityId}/false`
    const isOfflineEnabled = false
    const response = await axios.get(url, {
      headers: {
        isOfflineEnabled,
      },
    })
    console.log(response.data)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

const updateVideoInteractiveUserResponse = (interactiveQuestionVideoToSave) => {
  return axios
    .post(`/api/activity/submitInteractiveVideoResponse`, {
      ...interactiveQuestionVideoToSave,
    })
    .then((response) => {
      return response.data
    })
}

export {
  fetchDocumentActivity,
  fetchInteractiveActivity,
  fetchVideoActivity,
  updateVideoInteractiveUserResponse,
}
