import {useState, useMemo, useCallback, useEffect, useRef} from 'react'

const useCountDownTimer = ({
  countDownDuration,
  colorThreshold = 20,
  canPauseCountDown = false,
  onCountDownComplete = () => {},
}) => {
  const [remainingDuration, setRemainingDuration] = useState(countDownDuration)
  const colorThresholdToDuration = useMemo(() => {
    return (countDownDuration * colorThreshold) / 100
  }, [countDownDuration, colorThreshold])

  const countDownTimeOutId = useRef(-1)

  const onSetCountDownInterval = useCallback(() => {
    countDownTimeOutId.current = setInterval(() => {
      setRemainingDuration(
        (prevRemainingDuration) => prevRemainingDuration - 1000,
      )
    }, 1000)
  }, [])

  const onClearInterval = useCallback(() => {
    clearInterval(countDownTimeOutId.current)
    countDownTimeOutId.current = -1
  }, [])

  useEffect(() => {
    if (remainingDuration === 0) {
      onClearInterval()
      onCountDownComplete()
    }
  }, [remainingDuration, onClearInterval, onCountDownComplete])

  useEffect(() => {
    if (canPauseCountDown) {
      onClearInterval()
    } else {
      onSetCountDownInterval()
    }
    return () => onClearInterval()
  }, [canPauseCountDown, onClearInterval, onSetCountDownInterval])

  return {
    remainingDuration: remainingDuration,
    colorThresholdToDuration,
  }
}

export default useCountDownTimer
