import React, {Component} from 'react'
import moment from 'moment'

class ImaginarySeekbar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      seekbarWidth: 0,
    }

    this.x = 0

    this.isDragging = false
    this.seekbarImaginary = React.createRef()
  }

  componentDidMount = () => {
    const seekbarWidth = this.seekbarImaginary?.current?.getBoundingClientRect()
      .width
    this.setState({
      seekbarWidth: seekbarWidth,
    })
    window.addEventListener('resize', this.updateDimensions)
  }
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    const seekbarWidth = this.seekbarImaginary?.current?.getBoundingClientRect()
      .width
    this.setState({
      seekbarWidth: seekbarWidth,
    })
  }

  renderButton = (question, disabled) => {
    // <AppButton
    //   clickHandler={this.triggerQuestionMarkerSelection}
    //   buttonIconCls="icon-question-timer-solid"
    //   buttonData={question.questionId}
    //   disabled={disabled}
    // />

    //

    const buttonIcon = (
      <svg
        className="app-icon "
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 40 40"
        width="100%"
        height="100%">
        <path d="M24.1,19.2c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.7,0.9-1.3,1.5c-0.4,0.4-0.7,0.7-0.9,0.8  c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.1,0.6-0.1,1.2h-1.3c0-0.2,0-0.3,0-0.4c0-0.5,0.1-1,0.2-1.3c0.1-0.3,0.3-0.6,0.5-0.9  c0.2-0.2,0.5-0.5,1-0.9c0.5-0.4,0.8-0.7,0.9-1c0.1-0.2,0.2-0.5,0.2-0.8c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.5-0.6  c-0.6,0-1.1,0.2-1.4,0.5c-0.4,0.4-0.6,0.9-0.8,1.7l-1.4-0.2c0.1-1,0.5-1.8,1.1-2.3c0.6-0.5,1.4-0.8,2.5-0.8c1.1,0,1.9,0.3,2.6,0.9  C23.7,17.7,24.1,18.3,24.1,19.2 M19.7,26.5c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8  C20.1,27.2,19.7,26.9,19.7,26.5 M29.7,13c0.6-0.6,0-1.2,0-1.2c-0.6-0.6-1.2,0-1.2,0L27,13.3c-1.6-1.2-3.5-2-5.6-2.2V9.7h1.1  c1,0,1-0.9,1-0.9c0-0.9-1-0.9-1-0.9h-4c-1,0-1,0.9-1,0.9c0,0.9,1,0.9,1,0.9h1.1V11c-2.1,0.2-4.1,1-5.6,2.2l-1.5-1.4  c0,0-0.6-0.6-1.2,0c0,0-0.6,0.6,0,1.2l1.4,1.4c-1.7,1.9-2.8,4.4-2.8,7.1C10,27.3,14.7,32,20.5,32S31,27.3,31,21.5  c0-2.7-1.1-5.2-2.8-7.1L29.7,13z" />
      </svg>
    )
    const disabledClass = disabled ? 'btn-disabled' : ''
    return (
      <div style={{display: 'inline-block'}}>
        {/*}	<button
					title={this.props.tooltipText}
					className={"hidden-xs app-button icon-only"}
					onClick={e => {
						this.triggerQuestionMarkerSelection(e, question.questionId);
					}}>
					{buttonIcon}
				</button>*/}
        <button
          className={
            'hidden-sm hidden-md hidden-lg app-button icon-only ' +
            disabledClass
          }
          onClick={(e) => {
            if (!disabled) {
              this.triggerQuestionMarkerSelection(e, question.questionId)
            }
          }}>
          {buttonIcon}
        </button>
      </div>
    )
  }

  render() {
    const questionMarkers = []
    this.props.interactiveQuestions.forEach(function (question) {
      const questionLocation = question.timeToProjectQuestion,
        key = `${question.questionId}-${questionLocation}`,
        disabled =
          !this.props.editable &&
          !this.props.userSubmittedCorrectAnswers.includes(
            questionLocation / 1000,
          )
      questionMarkers.push(
        <div
          className={'questions-icon ' + (disabled ? 'disabled' : '')}
          key={key}
          style={{left: this.calculateMarkerPosition(questionLocation)}}>
          <div className="diamond-marker">
            {this.renderButton(question, disabled)}
            <svg
              className="app-icon marker-question"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 40 40">
              <polygon points="19.5,11.7 19.5,21.7 16.5,24.7 20,28.3 23.5,24.7 20.5,21.7 20.5,11.7 " />
            </svg>
          </div>
          <div className="display-time">
            {this.calculateTime(questionLocation)}
          </div>
        </div>,
      )
    }, this)

    return (
      <div className="seekbar-full-length">
        <div
          className="seekbar-imaginary"
          id="seekbar-imaginary"
          ref={this.seekbarImaginary}>
          {/*{this.props.allowDownload && (
            <button
              title={this.props.tooltipText}
              className={'download-button app-button icon-only'}
              onClick={(e) => {
                this.props.onDownload()
              }}>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                xmlSpace="preserve">
                <path
                  d="M49.7,25.3v11.1c0,3.5-2.8,6.3-6.3,6.3h-37c-3.5,0-6.3-2.8-6.3-6.3V25.3c0-1.5,1.2-2.7,2.7-2.7c1.5,0,2.7,1.2,2.7,2.7v9.2
        	c0,1.5,1.2,2.7,2.7,2.7h33.2c1.5,0,2.7-1.2,2.7-2.7v-9.2c0-1.5,1.2-2.7,2.7-2.7C48.5,22.6,49.7,23.8,49.7,25.3z M23.1,33
        	c0.5,0.5,1.2,0.8,1.9,0.8c0.7,0,1.4-0.2,1.9-0.8l10.6-10.6c1-1,1-2.8,0-3.8c-1-1-2.8-1-3.8,0l-6,6v-18C27.7,5.2,26.5,4,25,4
        	s-2.7,1.2-2.7,2.7v18l-6-6c-1-1-2.8-1-3.8,0c-1,1-1,2.8,0,3.8L23.1,33z"
                />
              </svg>
            </button>
          )}*/}

          {this.props.totalDuration > 0 && questionMarkers}
        </div>
      </div>
    )
  }

  triggerQuestionMarkerSelection = (e, questionId) => {
    // if (this.isDragging) {
    //     return;
    // }
    this.props.onQuestionMarkerSelection(questionId)
  }

  calculateMarkerPosition = (markerLocation) => {
    markerLocation += this.x // For continuous UI update
    //		}

    const markerPosition =
      (this.state.seekbarWidth * markerLocation) / this.props.totalDuration
    // if (Skilltron.app.AppHelper.isRtl()) {
    // 	return -1 * markerPosition;
    // }
    return markerPosition
  }

  calculateTime = (questionLocation) => {
    // if (this.refs.seekbarImaginary) {
    //   this.state.seekbarWidth = this.refs.seekbarImaginary.getBoundingClientRect().width;
    // }
    const deltaTimeInMs =
      (this.x * this.props.totalDuration) / this.state.seekbarWidth
    const time = moment('2015-01-01')
      .startOf('day')
      .milliseconds(deltaTimeInMs + questionLocation)
      .format('mm:ss')
    return time
  }
}

export default ImaginarySeekbar
