import '../../styles/DisplayModule.scss'
import {CompletedIcon, InProgressIcon, PlayIcon} from '../../icons'
import moment from 'moment/moment'

const DisplayModule = ({module, handleModuleClick}) => {
  const moduleStatus =
    module.completionPercentage === 0
      ? 'Not Started'
      : module.completionPercentage > 0 && module.completionPercentage < 100
      ? 'In-Progress'
      : 'Completed'

  const renderIcon = () => {
    return moduleStatus === 'Completed' ? (
      <CompletedIcon />
    ) : moduleStatus === 'In-Progress' ? (
      <InProgressIcon />
    ) : (
      <PlayIcon />
    )
  }

  const dueDate = !!module.completionDueDate
    ? moment(module.completionDueDate).format('Do MMM, YY')
    : ''

  const getDueDateStatusDisplay = () => {
    const now = moment()
    const dueData = moment(module.completionDueDate)
    let message = dueData.from(now)
    if (dueData.year() === now.year() && dueData.month() === now.month()) {
      const dateDiff = dueData.date() - now.date()
      if (dateDiff === 0) {
        message = dueData.format('LT')
      } else if (dateDiff === 1) {
        message = dueData.format('LT')
      }
    }
    return message
  }

  return (
    <div
      className="module-box"
      onClick={() => handleModuleClick(module.moduleId)}>
      <div className="module-info">
        <div className="module-img">
          <img src={module.moduleImageUrl} alt="" className="image-module" />
        </div>
        <div className="module-gradient"></div>
        <div className="module-details">
          <div className="name">{module.name}</div>
          <div className="description">{module.description}</div>
          <div className="skill">
            <div className="skill-name">{module.skillName}</div>
          </div>
        </div>
      </div>
      <div className="module-gist">
        <div className="self-paced">Self-Paced Module</div>
        <div className="activity-count-container">
          <div className="dot" />
          <div className="activity-count">
            {module.activities.length}{' '}
            {module.activities.length > 1 ? 'Activities' : 'Activity'}
          </div>
        </div>
        <div className="people-completed-container">
          <div className="dot" />
          <div className="people-completed">{`${module.completedLearnersCount} people completed`}</div>
        </div>
      </div>
      <div className="break-line"></div>
      <div className="module-status">
        <div className="status-img">{renderIcon()}</div>
        <div className="status-text">{moduleStatus}</div>
        <div className="status-dot"></div>
        <div className="due-date">{dueDate}</div>
        <div className="months-left">{`Due ${getDueDateStatusDisplay()}`}</div>
      </div>
    </div>
  )
}

export default DisplayModule
