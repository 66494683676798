import SubmitPermission from './SubmitPermission'
import '../../../styles/DisplayQuestions.scss'
import {QuizParticipantViewState} from '../../enums'

const DisplayQuestions = ({
  quizQuestionAnswers,
  isRevealed,
  quizSettings,
  quizViewMode,
  multiSelectOptionEnabled,
  showValidationError,
  questionToDisplay,
  selectedOptions,
  askPermission,
  displaySubmit,
  handleOptionClick,
  onNext,
  onPrevious,
  onSubmitQuiz,
  onInstantSubmit,
  handleValidationOK,
  handleCancelAskPermission,
}) => {
  const questionTag = questionToDisplay?.questionObject?.question?.itemText
  const question = questionTag?.replace('auto', '100%')

  const questionType =
    multiSelectOptionEnabled &&
    questionToDisplay.doesQuestionHaveMultipleCorrectAnswers()
      ? `This question may have more than one correct answer`
      : null

  const getParticipantMultiAnswers = (optionNo) => {
    return (
      questionToDisplay.questionObject.z_correctAnswers.indexOf(optionNo) !== -1
    )
  }

  let msg = null
  let msgStyle = null
  if (
    quizViewMode === QuizParticipantViewState.VIEWING ||
    questionToDisplay.participantAnswerSaved
  ) {
    if (questionToDisplay.isRight(true)) {
      msg = 'Correct! You are spot on'
      msgStyle = 'correct'
    } else {
      msgStyle = 'wrong'
      const participantAnswers = questionToDisplay.participantAnswerData.answers
      if (participantAnswers.length > 0) {
        const correctAnswerList = ['A', 'B', 'C', 'D', 'E', 'F']
        const sortedParticipantAnswers = [].concat(participantAnswers)
        sortedParticipantAnswers.sort((a, b) => a - b)
        const selectedAnswerList = sortedParticipantAnswers.map((item) => {
          return correctAnswerList[item - 1]
        })
        let answerString = selectedAnswerList.join(',')
        const position = answerString.lastIndexOf(',')
        if (position !== -1) {
          answerString =
            answerString.substring(0, position) +
            ` and ` +
            answerString.substring(position + 1)
        }
        msg = `Wrong! Your answer was ${answerString}`
      } else {
        msg = `Wrong! Your answer was wrong`
      }
    }
  }

  return (
    <>
      <div className="outer-div">
        <div className="inner-div">
          <div className="section-name">{questionToDisplay?.sectionName}</div>
          <div className="question-type">{questionType}</div>
          <div
            className="question"
            dangerouslySetInnerHTML={{
              __html: question,
            }}
          />
          {questionToDisplay?.questionObject?.options.map((o, i) => (
            <div
              className={
                getParticipantMultiAnswers(i + 1) &&
                (quizViewMode === QuizParticipantViewState.VIEWING ||
                  (quizSettings.shouldRevealExplanationByQuestion &&
                    questionToDisplay.participantAnswerSaved))
                  ? 'correct-options-div options-div'
                  : selectedOptions.includes(i + 1) &&
                    !getParticipantMultiAnswers(i + 1) &&
                    (quizViewMode === QuizParticipantViewState.VIEWING ||
                      (quizSettings.shouldRevealExplanationByQuestion &&
                        questionToDisplay.participantAnswerSaved))
                  ? 'wrong-options-div options-div'
                  : selectedOptions.includes(i + 1)
                  ? 'selected-options-div options-div'
                  : 'options-div'
              }
              onClick={() => handleOptionClick(i + 1)}>
              <div
                className={
                  getParticipantMultiAnswers(i + 1) &&
                  (quizViewMode === QuizParticipantViewState.VIEWING ||
                    (quizSettings.shouldRevealExplanationByQuestion &&
                      questionToDisplay.participantAnswerSaved))
                    ? 'correct-option-name-div option-name-div'
                    : selectedOptions.includes(i + 1) &&
                      !getParticipantMultiAnswers(i + 1) &&
                      (quizViewMode === QuizParticipantViewState.VIEWING ||
                        (quizSettings.shouldRevealExplanationByQuestion &&
                          questionToDisplay.participantAnswerSaved))
                    ? 'wrong-option-name-div option-name-div'
                    : selectedOptions.includes(i + 1)
                    ? 'selected-option-name-div option-name-div'
                    : 'option-name-div option-name-div'
                }>
                <div
                  className={
                    getParticipantMultiAnswers(i + 1) &&
                    (quizViewMode === QuizParticipantViewState.VIEWING ||
                      (quizSettings.shouldRevealExplanationByQuestion &&
                        questionToDisplay.participantAnswerSaved))
                      ? 'correct-option-name option-name'
                      : selectedOptions.includes(i + 1) &&
                        !getParticipantMultiAnswers(i + 1) &&
                        (quizViewMode === QuizParticipantViewState.VIEWING ||
                          (quizSettings.shouldRevealExplanationByQuestion &&
                            questionToDisplay.participantAnswerSaved))
                      ? 'wrong-option-name option-name'
                      : selectedOptions.includes(i + 1)
                      ? 'selected-option-name option-name'
                      : 'option-name'
                  }>
                  {String.fromCharCode(65 + i)}
                </div>
              </div>
              <div
                className={
                  getParticipantMultiAnswers(i + 1) &&
                  (quizViewMode === QuizParticipantViewState.VIEWING ||
                    (quizSettings.shouldRevealExplanationByQuestion &&
                      questionToDisplay.participantAnswerSaved))
                    ? 'correct-option-text option-text'
                    : selectedOptions.includes(i + 1) &&
                      !getParticipantMultiAnswers(i + 1) &&
                      (quizViewMode === QuizParticipantViewState.VIEWING ||
                        (quizSettings.shouldRevealExplanationByQuestion &&
                          questionToDisplay.participantAnswerSaved))
                    ? 'wrong-option-text option-text'
                    : selectedOptions.includes(i + 1)
                    ? 'selected-option-text option-text'
                    : 'option-text'
                }
                dangerouslySetInnerHTML={{
                  __html: o.itemText,
                }}
              />
            </div>
          ))}
          {(questionToDisplay.participantAnswerSaved ||
            quizViewMode === QuizParticipantViewState.VIEWING) && (
            <>
              <div className={`display-msg ${msgStyle}-msg`}>{msg}</div>
              {(quizSettings.shouldRevelExplanation ||
                quizViewMode === QuizParticipantViewState.VIEWING) && (
                <>
                  {questionToDisplay.questionExplanationText && (
                    <div className="explanation-title">Explanation</div>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: questionToDisplay.questionExplanationText,
                    }}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {(quizSettings.shouldRevealExplanationByQuestion
        ? questionToDisplay.participantAnswerSaved
        : true) &&
        quizQuestionAnswers.length > 0 && (
          <div className="action-button">
            {questionToDisplay?.questionNo !== 1 && (
              <div
                className="navigation-button previous-text"
                onClick={onPrevious}>
                Previous
              </div>
            )}
            <div
              className={
                displaySubmit
                  ? 'navigation-button submit-text'
                  : 'navigation-button next-text'
              }
              onClick={onNext}>
              {displaySubmit && questionToDisplay.participantAnswerSaved
                ? 'Show Report'
                : displaySubmit &&
                  quizViewMode !== QuizParticipantViewState.VIEWING
                ? 'Submit'
                : displaySubmit &&
                  quizViewMode === QuizParticipantViewState.VIEWING
                ? 'Show Report'
                : 'Next'}
            </div>
          </div>
        )}
      {quizSettings.shouldRevealExplanationByQuestion &&
        !questionToDisplay.participantAnswerSaved && (
          <div className="action-button">
            <div
              className="navigation-button submit-text"
              onClick={onInstantSubmit}>
              Submit Answer
            </div>
          </div>
        )}
      {askPermission && quizViewMode !== QuizParticipantViewState.VIEWING && (
        <SubmitPermission
          onSubmitQuiz={onSubmitQuiz}
          handleCancelAskPermission={handleCancelAskPermission}
        />
      )}
      {showValidationError && (
        <div className="validation-error-background">
          <div className="validation-error-modal">
            <div className="validation-error-content-div">
              <div className="validation-erorr-modal-body">
                Please answer all the questions!
              </div>
              <div
                className="validation-erorr-action-div"
                onClick={handleValidationOK}>
                OK
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DisplayQuestions
