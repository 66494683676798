const ActivityType = {
  DIGITAL_WHITEBOARD: 1,
  ASSIGNMENT: 2,
  QUIZ: 3,
  GROUPWORK: 4,
  DISCUSSION: 5,
  WEBLINK: 6,
  FLIPPED_CLASS: 7,
  VIDEO: 8,
  INTERACTIVE: 9,
  APP: 10,
  YOUTUBE_VIDEO: 11,
  FLASHCARD: 12,
}

export default ActivityType
