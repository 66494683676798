import { Component } from 'react'
import { fetchDocumentActivity } from '../axios'
import VerticalCarousel from '../core/VerticalCarousel/VerticalCarousel'
import { ActivityType, LoadingState } from '../enums'
import CarouselNavigation from '../module/CarouselNavigation'
import ActivityFooter from './ActivityFooter'
import ActivityHeader from './ActivityHeader'
import AnalyticsTracker from './AnalyticsTracker'
import ActivityItemsUsageAnalyticsModel from './model/ActivityItemsUsageAnalyticsModel'
import DocumentActivity from './model/DocumentActivity'

class DocumentActivityView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usageAnalyticsModel: null,
      currentIndex: 0,
      documentDetails: null,
      usageTimestamp: new Date().valueOf(),
      loadingState: LoadingState.FETCHING,
      currentProgress: 0,
      isCompleted: false,
      defaultIndex: 0,
      activeLanguage: 'en',
      screenOrientation: 1
    }
    this.analyticsTracker = null
    this.currentLanguageActivityId = this.props.activityId
  }

  componentDidMount() {
    this.retrieveDocumentInfo()
    window.addEventListener('orientationchange', this.setScreenOrientation);
  }

  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.setScreenOrientation);
  }

  setScreenOrientation = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
      this.setState({
        screenOrientation: 2
      });
    }

    if (window.matchMedia("(orientation: landscape)").matches) {
      this.setState({
        screenOrientation: 1
      });
    }
  }

  retrieveDocumentInfo = async () => {
    const response = await fetchDocumentActivity(this.props.activityId)
    if (response) {
      const documentDetails = new DocumentActivity(response)
      const usageAnalyticsModel = new ActivityItemsUsageAnalyticsModel({
        activityItemsCount: response.convertedPagesInfo.length,
        previousUsageAnalytics: documentDetails.usageAnalytics,
      })
      this.setState(
        {
          documentDetails: documentDetails,
          isCompleted: documentDetails.isComplete,
          usageAnalyticsModel: usageAnalyticsModel,
          loadingState: LoadingState.AVAILABLE,
        },
        () => {
          this.analyticsTracker = new AnalyticsTracker({
            updateCurrentUsageAnalytics: this.updateCurrentUsageAnalytics,
            analyticsId: response.analyticsId,
            activityId: this.props.activityId,
            activityType: ActivityType.DIGITAL_WHITEBOARD,
          })
          this.analyticsTracker.setControlTimeout()
          this.updateCurrentUsageAnalytics()
          this.setState({
            defaultIndex: usageAnalyticsModel.getCurrentUsageIndex(),
          })
          this.onIndexChanged(usageAnalyticsModel.getCurrentUsageIndex())
        },
      )
    } else {
      this.setState({
        loadingState: LoadingState.EMPTY,
      })
    }
  }

  componentWillUnmount() {
    this.analyticsTracker?.stopTracking()
    this.analyticsTracker = null
  }

  onIndexChanged = (index) => {
    this.updateCurrentUsageAnalytics()
    this.setState(
      {
        currentIndex: index,
      },
      () => {
        this.updateCurrentUsageAnalytics()
      },
    )
  }

  updateCurrentUsageAnalytics = () => {
    return new Promise((resolve, reject) => {
      const usageAnalyticsModel = this.state.usageAnalyticsModel
      const now = new Date().valueOf()
      usageAnalyticsModel.updateCurrentUsageAnalytics(
        this.state.currentIndex + 1,
        now - this.state.usageTimestamp,
      )
      this.setState({
        usageAnalyticsModel: usageAnalyticsModel,
        usageTimestamp: now,
        currentProgress: !this.state.documentDetails.isComplete
          ? usageAnalyticsModel.getUsageCompletionPercentage(true)
          : 100,
      })
      const analyticData = {
        metaData: usageAnalyticsModel.getCurrentUsageAnalytics(),
        markComplete:
          !this.state.documentDetails.isComplete &&
          (usageAnalyticsModel.isUsageAlreadyComplete() ||
            usageAnalyticsModel.isUsageComplete()),
      }
      if (analyticData.markComplete) {
        this.setState({
          isCompleted: true,
        })
        /*this.props.onUpdateActivityConsumed(
          this.props.activityId,
          this.currentLanguageActivityId,
          this.props.displayedActivityId,
        )*/
      }
      resolve(analyticData)
    })
  }

  onNext = () => {
    this.onIndexChanged(this.state.currentIndex + 1)
  }

  onPrev = () => {
    this.onIndexChanged(this.state.currentIndex - 1)
  }

  render() {
    if (this.state.loadingState === LoadingState.FETCHING) {
      return (
        <div className={'flexCentered background fullHeight'}>Fetching...</div>
      )
    }

    if (this.state.loadingState === LoadingState.EMPTY) {
      return <div className={'flexCentered background fullHeight'}>Empty</div>
    }

    return (
      <>
        <ActivityHeader
          activityName={this.state.documentDetails?.activityName}
        />
        <VerticalCarousel currentPageIndex={this.state.currentIndex}>
          <>
            <img
              src={
                this.state.documentDetails?.convertedPagesInfo[
                  this.state.currentIndex
                ].pageUrl
              }
              className="document-container"
            />
            {this.state.documentDetails.convertedPagesInfo.length > 1 && (
              <CarouselNavigation
                screenOrientation={this.state.screenOrientation}
                totalPage={this.state.documentDetails.convertedPagesInfo.length}
                currentPageIndex={this.state.currentIndex}
                onNext={this.onNext}
                onPrev={this.onPrev}
              />
            )}
          </>
        </VerticalCarousel>
        <ActivityFooter
          nextActivity={this.props.nextActivity}
          handleOnNextClick={this.props.handleOnNextClick}
        />
      </>
    )
  }
}

DocumentActivityView.propTypes = {}

export default DocumentActivityView
