import PropTypes from 'prop-types'
import AuthContext from './AuthContext'

const AuthProvider = ({appUser, children}) => {
  return <AuthContext.Provider value={appUser}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
  appUser: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
}

export default AuthProvider
