/* eslint-disable eqeqeq */
import {ClientSegment, ProductPermission, UserContext} from '../../enums'
import User from './User'

class CorpUser extends User {
  constructor(config = {}) {
    config.clientSegment = ClientSegment.CORPORATE
    super(config)

    this.hasReportees = config.hasReportees || false
    this.hasManagersAboveUser = config.hasManagersAboveUser || false
    this.isAssignedSkills = true
    this.skillPermissions = null
    this.skillPermissionsWithMeta = null

    this.reportees = config.reportees || []
    this.targetAudienceForBanners = config.targetAudienceForBanners || []
  }

  determineUserContext(preference) {
    let baseUserContext
    if (preference.lastLoggedInMode == 0) {
      if (this.allowedContexts.length === 0) {
        throw 'Authenticated user {0} has NO allowed contexts!'.format(
          this.userName,
        )
      }
      baseUserContext = this.allowedContexts[0]
    } else {
      baseUserContext = preference.lastLoggedInMode
    }

    return baseUserContext
  }

  getPermittedUserContexts() {
    return this.allowedContexts
  }

  getSubjectsMethodName(userContext) {
    let apiMethod = 'api/skill/'
    if (userContext == UserContext.SKILLMASTER) {
      apiMethod += 'getSkillsForSkillMaster'
    } else if (userContext == UserContext.LEARNER) {
      apiMethod += 'getSkillsForLearner'
    }
    return apiMethod
  }

  getSubjectInfoMethodName() {
    return 'api/skill/getSkillById'
  }

  // Sub-class methods

  setSubjectLevelPermissions(subjectLevelPermissions) {
    this.skillPermissions = subjectLevelPermissions
  }

  setSubjectLevelPermissionsWithMeta(subjectLevelPermissionsWithMeta) {
    this.skillPermissionsWithMeta = subjectLevelPermissionsWithMeta
  }

  canAddPrograms() {
    return this.productPermissions.includes(ProductPermission.ADDING_PROGRAMS)
  }

  canAddSubjects() {
    return this.productPermissions.includes(ProductPermission.ADDING_SKILLS)
  }

  canEditActivity(activityObject) {
    return this.canAddSelfPacedModules()
  }

  canEditActivitySettings() {
    return this.canAddSelfPacedModules()
  }

  canPerformAdministration() {
    return this.productPermissions.includes(
      ProductPermission.APP_ADMINISTRATION,
    )
  }

  canPerformCorpAdministration() {
    return this.productPermissions.includes(
      ProductPermission.APP_ADMINISTRATION,
    )
  }

  canPerformOnlyCorpAdministration() {
    return (
      this.canPerformCorpAdministration() &&
      this.productPermissions.length === 1
    )
  }

  canLearn() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.LEARNING_SKILLS,
      )
    }
  }

  canSelfEnroll() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.SELF_ENROLLING_SKILLS,
      )
    }
  }

  canAddSelfPacedModules() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.ADD_SELFPACED_MODULES,
      )
    }
  }

  canAddBuzz() {
    if (this.skillPermissions) {
      return (
        this.skillPermissions.includes(
          CorpUser.SkillLevelPermissions.ADDING_BUZZ,
        ) ||
        this.skillPermissions.includes(
          CorpUser.SkillLevelPermissions.LEARNER_ADDING_BUZZ,
        )
      )
    } else {
      return false
    }
  }

  canRespondToBuzz() {
    if (this.skillPermissions) {
      return (
        this.skillPermissions.includes(
          CorpUser.SkillLevelPermissions.REPLY_TO_BUZZ,
        ) ||
        this.skillPermissions.includes(
          CorpUser.SkillLevelPermissions.LEARNER_REPLY_TO_BUZZ,
        )
      )
    } else {
      return false
    }
  }

  canScheduleLiveModules() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.SCHEDULE_LIVE_MODULES,
      )
    }
  }

  canDeliverLiveModules() {
    if (this.skillPermissions) {
      return (
        this.skillPermissions.includes(
          CorpUser.SkillLevelPermissions.DELIVER_LIVE_MODULES,
        ) ||
        this.skillPermissions.includes(
          CorpUser.SkillLevelPermissions.LEARNER_DELIVER_LIVE_MODULES,
        )
      )
    }
  }

  canModerateLiveModules() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.MODERATE_LIVE_MODULES,
      )
    }
  }

  canAddMoocModules() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.ADD_MOOC_MODULES,
      )
    }
  }

  canViewSkillAnalytics() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.VIEW_SKILL_ANALYTICS,
      )
    }
  }

  canManageSkillUsers() {
    if (this.skillPermissions) {
      return this.skillPermissions.includes(
        CorpUser.SkillLevelPermissions.MANAGE_SKILL_USERS,
      )
    }
  }

  getBuzzPermissionMetaData() {
    const permissionData =
      this.skillPermissionsWithMeta &&
      this.skillPermissionsWithMeta.find(
        (skillPermission) =>
          skillPermission.permissionId ===
            CorpUser.SkillLevelPermissions.ADDING_BUZZ ||
          skillPermission.permissionId ===
            CorpUser.SkillLevelPermissions.LEARNER_ADDING_BUZZ,
      )
    // console.log(permissionData);
    return !!permissionData ? JSON.parse(permissionData.permissionMetaData) : {}
  }

  toDisplayOrgAnalytics = () => {
    // return ApplicationSettingsHelper.isOrgAnalyticsEnabled && this.hasReportees
  }

  getTargetAudienceOfABanner(bannerId) {
    return this.targetAudienceForBanners.find(
      (banner) => banner.contextId === bannerId,
    )
  }
}
export default CorpUser
