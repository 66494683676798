class QuizQuestionDifficultyLevel {
  constructor(config = {}) {
    this.difficultyLevelId = config.difficultyLevelId || 0
    this.difficultyLevelName = config.difficultyLevelName || ''
    this.positivePoints = config.positivePoints || 0
    this.negativePoints = config.negativePoints || 0
    if (this.negativePoints > 0) {
      this.negativePoints = this.negativePoints * -1 // Ensuring this.negativePoints is a negative value
    }
  }

  setPoints(pointType, points) {
    switch (pointType) {
      case QuizQuestionDifficultyLevel.PointTypes.POSITIVE:
        this.positivePoints = points
        break
      case QuizQuestionDifficultyLevel.PointTypes.NEGATIVE:
        this.negativePoints = points
        break
    }
  }
}

QuizQuestionDifficultyLevel.PointTypes = {
  POSITIVE: 1,
  NEGATIVE: 2,
}

export default QuizQuestionDifficultyLevel
