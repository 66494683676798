import '../../../styles/ActivityView.scss'
import * as PropTypes from 'prop-types'
import {useState, useEffect, Component} from 'react'
import {fetchVideoActivity} from '../../axios'
import {LoadingState} from '../../enums'
import ActivityFooter from '../ActivityFooter'
import ActivityHeader from '../ActivityHeader'
import VideoPlayer from './VideoPlayer'
import VideoActivityModel from '../model/VideoActivity'

class VideoActivityView extends Component {
  render() {
    return (
      <>
        <ActivityHeader activityName={this.props.name} />
        <VideoPlayer
          activityId={this.props.activityId}
          userId={this.props.appUser.userId}
        />
        <ActivityFooter
          nextActivity={this.props.nextActivity}
          handleOnNextClick={this.props.handleOnNextClick}
        />
      </>
    )
  }
}

VideoActivityView.propTypes = {id: PropTypes.any}

export default VideoActivityView
