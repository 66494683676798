import axios from 'axios'
import {UserContext} from '../enums'

const fetchActivities = async (moduleId) => {
  try {
    const isOfflineEnabled = false
    const moduleType = 1
    const url = `/api/activity/getModuleWithActivitiesForLearner/${moduleId}/${
      moduleType === 4 //Module.ModuleTypes.CLASSROOM
        ? 0
        : UserContext.LEARNER
    }`
    const response = await axios.get(url, {
      headers: {
        isOfflineEnabled,
      },
    })
    console.log(response)
    return response.data
  } catch (e) {
    console.log(e)
  }
}

export {fetchActivities}
