import React from 'react'
import PropTypes from 'prop-types'

const AppButton = ({children, className, onClick}) => {
  return (
    <button type="button" className={className} onClick={onClick}>
      {children}
    </button>
  )
}

AppButton.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

AppButton.defaultProps = {
  onClick: () => {},
}

export default AppButton
