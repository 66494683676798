import {useContext} from 'react'
import AuthContext from './AuthContext'
import PropTypes from 'prop-types'

const AuthConsumer = ({children}) => {
  const auth = useContext(AuthContext)
  return <>{children(auth)}</>
}

AuthConsumer.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthConsumer
