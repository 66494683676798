import { useEffect, useRef } from 'react'
import Card from './Card'

const VerticalCarousel = ({
  children,
  currentPageIndex
}) => {

  const scrollRef = useRef(null);

  useEffect(() => {
    scrollRef.current.scrollTop = 0;
  }, [currentPageIndex])

  return (
    <div className={'vertical-carousel-area'} ref={scrollRef}>
      <Card>{children}</Card>
    </div>
  )
}

VerticalCarousel.propTypes = {}

export default VerticalCarousel
