import {useCallback, useState, useEffect} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
  useHistory,
} from 'react-router-dom'
import '../styles/App.scss'
import {AuthenticationView, AuthProvider, useAuthContext} from './auth'
import {getAuthDetailsFromToken} from './axios'
import HomeView from './HomeView'
import {ModuleListingView, ModuleView, ActivityView} from './module/'
import ThankYouView from './ThanYouView'
import CorpUser from './user/model/CorpUser'

function PrivateRoute({children, ...rest}) {
  const auth = useAuthContext()
  return (
    <Route
      {...rest}
      render={({location}) =>
        !!auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: location},
            }}
          />
        )
      }
    />
  )
}

function App() {
  //disprz://auth/HuIEZ+bvNG1pu2zTakxg7Vv7WdlFFTuL
  const [appUser, setAppUser] = useState(null)
  const [nextActivity, setNextActivity] = useState({})
  const [activityData, setActivityData] = useState({})
  const [allId, setAllId] = useState([])
  const [allType, setAllType] = useState([])
  const [activityName, setActivityName] = useState('')

  const history = useHistory()

  const updateAppUser = useCallback((authenticatedUser) => {
    const user =
      authenticatedUser?.authResponse?.authenticatedUser ||
      authenticatedUser?.authenticatedUser
    setAppUser(new CorpUser(user))
  }, [])

  useEffect(() => {
    if (!window.location.href.includes('auth/')) {
      getAuthDetailsFromToken()
        .then((response) => {
          onAuthenticate(response, history)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAuthenticate = useCallback(
    (authenticatedUser, history) => {
      updateAppUser(authenticatedUser)
      history?.push('/modules/')
    },
    [updateAppUser],
  )

  const onLogout = useCallback(() => {
    setAppUser(null)
    window.parent.postMessage({ type: 'disprz_close' }, '*');
  }, [])

  const handleOnNextClick = useCallback((nextActivity, history) => {
    const loc = allId.indexOf(nextActivity.id)
    const nextActivityId = loc !== allId.length - 1 ? allId[loc + 1] : null
    const nextType = loc !== allId.length - 1 ? allType[loc + 1] : null
    const temp = {
      id: nextActivityId,
      current: loc,
      totalActivity: allId.length,
      type: nextType,
    }
    history.push(`/activity/${nextActivity.type}/${nextActivity.id}`)
    setNextActivity(temp)
  }, [])

  return (
    <AuthProvider
      appUser={{appUser, onLogout: onLogout, updateAppUser: updateAppUser}}>
      <Router>
        <Switch>
          <Route path={`/auth/:authToken`}>
            <AuthenticationView onAuthenticate={onAuthenticate} />
          </Route>
          <PrivateRoute path="/modules">
            <ModuleListingView onLogout={onLogout} />
          </PrivateRoute>
          <PrivateRoute path="/module/:id">
            <ModuleView
              activityData={activityData}
              setActivityData={setActivityData}
              allId={allId}
              setAllId={setAllId}
              allType={allType}
              setAllType={setAllType}
              setNextActivity={setNextActivity}
              setActivityName={setActivityName}
            />
          </PrivateRoute>
          <PrivateRoute path="/activity/:type/:id">
            <ActivityView
              handleOnNextClick={handleOnNextClick}
              nextActivity={nextActivity}
              activityName={activityName}
            />
          </PrivateRoute>
          <PrivateRoute path="/thankyou">
            <ThankYouView />
          </PrivateRoute>
          <Route path="/">
            <AuthenticationView />
          </Route>
        </Switch>
      </Router>
    </AuthProvider>
  )
}

export default App
