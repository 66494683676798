import axios from 'axios'

class AnalyticsTracker {
  constructor(props) {
    this.analyticsId = props.analyticsId
    this.isAnalyticsSavingInProgress = false
    this.activityId = props.activityId
    this.activityType = props.activityType
    this.intervalId = null
    this.updateCurrentUsageAnalytics = props.updateCurrentUsageAnalytics
  }

  clearControlTimeout() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      clearInterval(this.analyticsRejectionOnSaveTimerId)
    }
  }

  setControlTimeout() {
    this.intervalId = setInterval(() => {
      this.onSaveCurrentUsageAnalytics()
    }, 2 * 60 * 1000) // every 2 mins
  }

  stopTracking() {
    if (!this.isAnalyticsSavingInProgress) {
      this.onSaveCurrentUsageAnalyticsAndStopTracking(true)
    } else {
      this.onWaitUntilPreviousSaveRequestCompletes()
        .then(() => {
          this.onSaveCurrentUsageAnalyticsAndStopTracking(true)
        })
        .catch(() => {
          this.stopTracking()
        })
    }
    this.clearControlTimeout()
  }

  onWaitUntilPreviousSaveRequestCompletes() {
    return new Promise((resolve, reject) => {
      if (this.isAnalyticsSavingInProgress) {
        this.analyticsRejectionOnSaveTimerId = setTimeout(() => {
          clearInterval(this.analyticsRejectionOnSaveTimerId)
          reject()
        }, 1000)
      } else {
        resolve()
      }
    })
  }

  saveData = (analyticData, stopTracking) => {
    // const user = AppHelper.getAppUser()
    const dataToSave = {
      analyticsId: this.analyticsId,
      metaData:
        analyticData.metaData === ''
          ? ''
          : JSON.stringify(analyticData.metaData),
      markComplete: analyticData.markComplete,
      isAccessedOnline: true,
      closingActivity: stopTracking,
      isXAPI: analyticData.isXAPI || false,
      lrsConfig: analyticData.lrsConfig || {},
    }
    this.isAnalyticsSavingInProgress = true
    const accessToken = sessionStorage.getItem('token')

    axios
      .post(
        '/api/activity/updateAnalytics',
        {
          ...dataToSave,
        },
        {
          headers: {
            'Access-Token': accessToken,
          },
        },
      )
      .then(({data}) => {
        this.isAnalyticsSavingInProgress = false
        if (dataToSave.markComplete) {
        } else {
        }
        const result = JSON.parse(data)
      })

    /*const updateAnalyticsOperation = new AjaxCaller({
      serviceUrl: AppHelper.getApiUrl('api/activity/updateAnalytics'),
      parameterString: JSON.stringify(dataToSave),
      isOfflineEnabled: false,
      userName: user.userName,
      failSilentlyOffline: true,
      callback: (data) => {
        this.isAnalyticsSavingInProgress = false
        if (dataToSave.markComplete) {
        } else {
        }
        const result = JSON.parse(data)
      },
    })
    updateAnalyticsOperation.submitRequest()*/
  }

  onSaveCurrentUsageAnalytics(stopTracking = false) {
    this.updateCurrentUsageAnalytics().then((analyticData) => {
      this.saveData(analyticData, stopTracking)
    })
  }

  onSaveCurrentUsageAnalyticsAndStopTracking() {
    this.updateCurrentUsageAnalytics().then((analyticData) => {
      this.saveData(analyticData, true)
    })
  }
}

export default AnalyticsTracker
