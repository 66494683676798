import $ from 'jquery'
import React, {Component} from 'react'
import '../../../styles/VideoPlayer.scss'
import {fetchVideoActivity} from '../../axios'
import {updateVideoInteractiveUserResponse} from '../../axios/'
import {ActivityType, LoadingState} from '../../enums'
import AnalyticsTracker from '../AnalyticsTracker'
import ImaginarySeekbar from './ImaginarySeekbar'
import ActivityPlaybackUsageAnalyticsModel from '../model/ActivityPlaybackUsageAnalyticsModel'
import VideoActivity from '../model/VideoActivity'
import InteractiveQuestionsView from './InteractiveQuestionsView'
import PlaybackIndicator from './PlaybackIndicator'

class VideoPlayer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingState: LoadingState.FETCHING,
      videoUrl: '',
      userSubmittedCorrectAnswers: [],
      interactiveQuestions: [],
      displaySeekForwardPopup: false,
      showTopView: false,
      thumbnailUrl: '',
      fullscreenView: false,
      questionToDisplay: null,
      userResponseMetaData: [],
    }
    this._progressEventTicker = 0
    this.playStart = false
    this.onTouchMove = this.onTouchMove.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onSubmitInteractiveQuestionAnswer = this.onSubmitInteractiveQuestionAnswer.bind(
      this,
    )
    this.rewatchClipFromPreviousCuepoint = this.rewatchClipFromPreviousCuepoint.bind(
      this,
    )

    this.imaginarySeekBar = React.createRef()
    this.videoActivity = React.createRef()
  }

  componentDidMount() {
    function getQueryStringValue(key) {
      return decodeURIComponent(
        window.location.search.replace(
          new RegExp(
            '^(?:.*[&\\?]' +
              encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
              '(?:\\=([^&]*))?)?.*$',
            'i',
          ),
          '$1',
        ),
      )
    }

    document.addEventListener('touchmove', this.onTouchMove)
    document.addEventListener('touchend', this.onTouchEnd)

    document.addEventListener('touchstart', this.onTouchStart)

    this.analyticsTracker = null

    // if (!getQueryStringValue("isTest")) {
    //   document.addEventListener("message", this.receivePostMessages, false);
    //   window.addEventListener("message", this.receivePostMessages, false);
    //   setTimeout(() => {
    //     window.parent.postMessage(
    //       JSON.stringify({
    //         type: "disprz_getVideoData",
    //       }),
    //       "*"
    //     );
    //   }, 1000);
    // } else {
    fetchVideoActivity(this.props.activityId)
      .then((res) => {
        const resp = new VideoActivity(res)
        const interactiveQuestions = this.initInteractiveQuestions(
          res.interactiveQuestions,
        )

        const usageAnalyticsModel = new ActivityPlaybackUsageAnalyticsModel({
          previousUsageAnalytics: resp.usageAnalytics,
          activityMetaInformation: resp.activityMetaInformation,
          playbackDuration: 31,
        })

        this.setState(
          {
            videoUrl: resp.convertedPagesInfo[0].pageUrl,
            loadingState: LoadingState.AVAILABLE,
            interactiveQuestions: interactiveQuestions,
            videoActivity: resp,
            usageAnalyticsModel: usageAnalyticsModel,
            thumbnailUrl: resp.convertedPagesInfo[0].thumbnailUrl,
          },
          () => {
            this.analyticsTracker = new AnalyticsTracker({
              updateCurrentUsageAnalytics: this.updateCurrentUsageAnalytics,
              analyticsId: res.analyticsId,
              activityId: this.props.activityId,
              activityType: ActivityType.VIDEO,
            })
            this.analyticsTracker.setControlTimeout()
            this.updateCurrentUsageAnalytics()
            const fileType = resp.convertedPagesInfo[0].pageUrl
              .split('?')[0]
              .split('.')
              .pop()
              .toLowerCase()

            this.initializePlayerWithVideo(
              this.formatUrl(resp.convertedPagesInfo[0].pageUrl),
              fileType,
            )
          },
        )
      })
      .catch((e) => {
        console.log(e)
        this.setState({
          loadingState: LoadingState.EMPTY,
        })
      })
    // }
  }

  componentWillUnmount() {
    this.analyticsTracker?.stopTracking()
    this.analyticsTracker = null
    document.removeEventListener('touchmove', this.onTouchMove)
    document.removeEventListener('touchend', this.onTouchEnd)
    this.unBindEvents()
  }

  onTouchMove = (e) => {
    if (this.state.fullscreenView) {
      this.attemptedSwipe = true
    }
  }

  onTouchStart = (e) => {
    if (this.state.fullscreenView) {
      if ($(e.target).parents('fp-controls').length > 0) {
        this.isSwipeDisabled = true
        window.parent.postMessage(
          JSON.stringify({
            type: 'disprz_videoSeeking',
            value: true,
          }),
          '*',
        )
      }
    }
  }
  onTouchEnd = (e) => {
    if (this.state.fullscreenView) {
      if (
        !this.attemptedSwipe &&
        !['radio', 'button', 'input'].includes(e.target.type) &&
        e.target.offsetHeight - (e.pageY || 0) > 50 &&
        this.playStart
      ) {
        this.videoActivity.current.classList.remove('hidecontrols')
        if (this.hideControlsTimer) {
          clearTimeout(this.hideControlsTimer)
        }

        this.hideControlsTimer = setTimeout(() => {
          if (this.videoActivit.current) {
            this.videoActivity.current.classList.add('hidecontrols')
          }
        }, 5000)

        if (this.state.flowplayerInstance.playing) {
          this.state.flowplayerInstance.pause()
        } else {
        }
      }

      if (this.isSwipeDisabled) {
        this.isSwipeDisabled = false
      }
      this.attemptedSwipe = false
    }
  }

  /*receivePostMessages = (event) => {
    if (event.data) {
      const dataObj =
        typeof event.data === 'string' ? JSON.parse(event.data) : event.data
      if (dataObj.type) {
        if (dataObj.type === 'disprz_videodata') {
          const res = JSON.parse(dataObj.response)
          const resp = new VideoActivity(res)
          const interactiveQuestions = this.initInteractiveQuestions(
            res.interactiveQuestions,
          )
          console.log('Time', dataObj.totalDuration)
          this.setState(
            {
              fullscreenView: dataObj.fullscreenView,
              showDownload: dataObj.showDownload,
              videoUrl: this.formatUrl(resp.convertedPagesInfo[0].pageUrl),
              loadingState: 2,
              interactiveQuestions: interactiveQuestions,
              videoActivity: resp,
              usageAnalyticsModel: new ActivityPlaybackUsageAnalyticsModel({
                previousUsageAnalytics: resp.usageAnalytics,
                activityMetaInformation: resp.activityMetaInformation,
                playbackDuration: parseInt(dataObj.totalDuration),
              }),
              totalDuration: parseInt(dataObj.totalDuration),
              thumbnailUrl: resp.convertedPagesInfo[0].thumbnailUrl,
            },
            () => {
              const fileType = resp.convertedPagesInfo[0].pageUrl
                .split('?')[0]
                .split('.')
                .pop()
                .toLowerCase()

              this.initializePlayerWithVideo(
                this.formatUrl(resp.convertedPagesInfo[0].pageUrl),
                fileType,
              )
            },
          )
        } else if (dataObj.type === 'disprz_rewatchclip') {
          this.rewatchClipFromPreviousCuepoint(dataObj.timeToProjectQuestion)
        } else if (dataObj.type === 'disprz_correctanswer') {
          const userSubmittedCorrectAnswers = [
            ...this.state.userSubmittedCorrectAnswers,
          ]
          userSubmittedCorrectAnswers.push(dataObj.timeToProjectQuestion)
          this.setState({
            userSubmittedCorrectAnswers: userSubmittedCorrectAnswers,
          })
        } else if (dataObj.type === 'disprz_closeQuestion') {
          this.setState(
            {
              toShowInteractiveQuestion: false,
            },
            () => {
              this.state.flowplayerInstance.play()
            },
          )
        } else if (dataObj.type === 'disprz_getAnalyticsData') {
          this.updateCurrentUsageAnalytics()
        } else if (dataObj.type === 'disprz_pauseVideo') {
          if (this.state.flowplayerInstance) {
            this.state.flowplayerInstance.pause()
          }
        } else if (dataObj.type === 'disprz_toggleVideo') {
          if (this.state.flowplayerInstance) {
            this.state.flowplayerInstance.toggle()
          }
        }
      }
    }
  }*/

  getFlowPlayerKey = () => {
    const fpKeys = [
      '$863732616083910', // for localhost per https://flowplayer.org/i/flowplayer/setup:licence-for-localhost
      '$694570941863278', // talentedge.in
      '$715714643137655', // learntron.net
      '$157203594749916', // luckyinternationalschool.org
      '$546036332910781', // disprz.com
    ]
    return fpKeys.join(', ')
  }

  initializePlayerWithVideo = (videoUrl, videoType) => {
    // Prepare the sources object for instantiating a flowplayer
    const sources = [],
      userSubmittedCorrectAnswers = []
    this.state.videoActivity.interactiveQuestions.forEach((questionItem) => {
      // user's already submitted answers
      if (
        this.state.videoActivity.userAnsweredQuestionIds.includes(
          questionItem.questionId,
        )
      ) {
        userSubmittedCorrectAnswers.push(
          questionItem.timeToProjectQuestion / 1000,
        )
      }
    })

    if (videoType === 'm3u8') {
      videoType = 'application/x-mpegurl'
    } else {
      videoType = 'video/' + videoType
    }
    sources.push({type: videoType, src: videoUrl})

    // TODO: Can we do this inside VideoActivity class?
    /*	this.props.videoActivity.interactiveQuestions.forEach(function(
			questionItem
		) {
			// user's already submitted answers
			if (
				this.props.videoActivity.userAnsweredQuestionIds.contains(
					questionItem.questionId
				)
			) {
				userSubmittedCorrectAnswers.push(
					questionItem.timeToProjectQuestion / 1000
				);
			}
		},
		this);
*/
    const fpKeyValue = this.getFlowPlayerKey()

    // Create a flowplayer instance and set it in state
    const flowplayerInstance = window.flowplayer('#flowplayerEl', {
      clip: {
        hlsjs: {
          safari: true,
          startLevel: 8,
        },
        sources: sources,
      },
      key: fpKeyValue,
      embed: false,
      share: false,
      keyboard: false,
      fullscreenView: this.state.fullscreenView,
      recoverNetworkError: true,
      recoverMediaError: true,
      cuepoints: this.state.interactiveQuestions.map((quizQuestion) => {
        return quizQuestion.timeToProjectQuestion / 1000
      }),
    })
    this.setState(
      {
        flowplayerInstance: flowplayerInstance,
        userSubmittedCorrectAnswers: userSubmittedCorrectAnswers,
      },
      function () {
        this.clearContextMenu()
      },
    )
    flowplayerInstance.bind('ready', this.setVideoDuration)
    flowplayerInstance.bind('quality', this.setVideoQualityLabel)
    flowplayerInstance.bind('resume', this.onResume)
    flowplayerInstance.bind('pause', this.onPause)
    flowplayerInstance.bind('beforeseek', this.onBeforeSeek)
    // flowplayerInstance.bind("fullscreen", this.onFullScreen);
    // flowplayerInstance.bind("fullscreen-exit", this.onFullScreenExit);
    flowplayerInstance.bind('seek', this.onSeek)
    flowplayerInstance.bind('finish', this.updateLastUsageAnalyticsPacketFinish)
    flowplayerInstance.bind('cuepoint', this.displayInteractiveQuestion)
    //if (!this.state.videoActivity.isComplete) {
    flowplayerInstance.bind('progress', this.handlePlaybackProgress)
    //  }
    // if (this.toDisplayLogoOnContent()) {
    // 	flowplayerInstance.bind("progress", this.positionBranding);
    // }
    // if (this.props.shouldTrackAnalytics) {
    // 	Skilltron.app.AppHelper.bind(
    // 		"appflow:analyticsReadyToSave",
    // 		this.updateCurrentUsageAnalytics,
    // 		this
    // 	);
    // 	Skilltron.app.AppHelper.bind(
    // 		"appflow:beforeBack",
    // 		this.checkAndPauseVideo
    // 	);
    // }
  }

  unBindEvents() {
    if (this.state.flowplayerInstance) {
      this.state.flowplayerInstance.unbind('ready', this.setVideoDuration)
      this.state.flowplayerInstance.unbind('quality', this.setVideoQualityLabel)
      this.state.flowplayerInstance.unbind('resume', this.onResume)
      this.state.flowplayerInstance.unbind('beforeseek', this.onBeforeSeek)
      this.state.flowplayerInstance.unbind(
        'progress',
        this.handlePlaybackProgress,
      )
      this.state.flowplayerInstance.unbind('seek', this.onSeek)
      this.state.flowplayerInstance.unbind(
        'cuepoint',
        this.displayInteractiveQuestion,
      )
      this.state.flowplayerInstance.unbind('pause', this.onPause)
      this.state.flowplayerInstance.unbind(
        'finish',
        this.updateLastUsageAnalyticsPacketFinish,
      )
      // https://github.com/flowplayer/flowplayer-hlsjs/issues/57
      this.state.flowplayerInstance.stop()
      if (
        this.state.flowplayerInstance.engine &&
        this.state.flowplayerInstance.engine.hlsjs !== undefined
      ) {
        this.state.flowplayerInstance.engine.hlsjs.stopLoad()
      }
      this.state.flowplayerInstance.shutdown()
    }
  }

  onSeek = (e, player) => {
    console.log('SEEK fired: seeking = ' + player.seeking)

    if (player.playing) {
      this.createNewUsageAnalyticsPacket(e, player)
    }

    // window.parent.postMessage(
    //   JSON.stringify({
    //     type: "disprz_videoSeeking",
    //     value: false
    //   }),
    //   "*"
    // );
  }

  onResume = (e, player) => {
    this.hideControlsTimer = setTimeout(() => {
      if (this.videoActivity.current) {
        this.videoActivity.current.classList.add('hidecontrols')
      }
    }, 1000)
    this.createNewUsageAnalyticsPacket(e, player)
  }

  onPause = (e, player) => {
    if (this.hideControlsTimer) {
      clearTimeout(this.hideControlsTimer)
    }
    this.videoActivity.current?.classList.remove('hidecontrols')
    this.updateLastUsageAnalyticsPacket(e, player)
  }

  createNewUsageAnalyticsPacket = (e, player) => {
    const usageAnalyticsModel = this.state.usageAnalyticsModel
    //player.video.time is null for android devices
    usageAnalyticsModel.updateCurrentUsageAnalytics(
      true,
      player.video.time || 0,
    )
    this.setState({
      usageAnalyticsModel: usageAnalyticsModel,
    })
  }

  setVideoQualityLabel = (e, player, index) => {
    const flowplayerEl = document.getElementById('flowplayerEl')
    const qMenu = flowplayerEl.querySelector('.fp-controls > .fp-qsel')
    if (qMenu) {
      const newQuality = player.video.qualities.find((item) => {
        return item.value === index
      })
      qMenu.innerHTML = newQuality.label
    }
  }

  onBeforeSeek = (e, player, target) => {
    //    console.log("BEFORESEEK fired: seeking = " + player.seeking);

    // for learner prevent forward seeking, when there are interactive questions
    const locationToSeek = parseFloat(target) * 1000 // Converting target to a millisecond value
    if (
      this.state.interactiveQuestions.length > 0 &&
      !this.state.videoActivity.isComplete &&
      !this.state.usageAnalyticsModel.hasLocationBeenSeen(locationToSeek)
    ) {
      e.preventDefault()
      this.setState({displaySeekForwardPopup: true})
      // this.state.flowplayerInstance.pause();

      setTimeout(() => {
        this.setState({displaySeekForwardPopup: false})
        this.state.flowplayerInstance.play()
      }, 3000)
    }

    if (player.playing) {
      this.updateLastUsageAnalyticsPacket(e, player)
    }
  }

  handlePlaybackProgress = (e, player, index) => {
    if (!this.playStart) {
      /*window.parent.postMessage(
        JSON.stringify({
          type: 'disprz_videoStarted',
        }),
        '*',
      )*/
    }
    this.playStart = true

    // https://demos.flowplayer.com/plugins/hlsjs.html
    const hlsengine = player.engine.hlsjs
    if (hlsengine) {
      const current = hlsengine.currentLevel,
        level = hlsengine.levels[current]
      if (level) {
        if (player.video.quality === -1) {
          const flowplayerEl = document.getElementById('flowplayerEl')
          const qMenu = flowplayerEl.querySelector('.fp-controls > .fp-qsel')
          if (qMenu) {
          }
        }
      }
      this.checkAndSetWarningForBuffering(e, player, index)
    }
    if (!this.state.videoActivity.isComplete) {
      this.monitorForPlaybackProgress(e, player)
    }
  }

  checkAndSetWarningForBuffering = (e, player, index) => {
    let flowplayerEl, qMenu
    if (
      player.video.buffer === undefined ||
      player.video.time > player.video.buffer
    ) {
      flowplayerEl = document.getElementById('flowplayerEl')
      flowplayerEl.querySelector('.fp-header')
      if (qMenu) {
        const qWarning = flowplayerEl.querySelector('.fp-header > .fp-warning')
        if (qWarning) {
          qWarning.innerHTML =
            'Your current bandwidth may not give you optimal viewing experience for the playing resolution.'
        } else {
          qMenu.innerHTML +=
            "<span class='fp-warning'>Your current bandwidth may not give you optimal viewing experience for the playing resolution.</span>"
        }
      }
    } else {
      flowplayerEl = document.getElementById('flowplayerEl')
      qMenu = flowplayerEl.querySelector('.fp-header > .fp-warning')
      if (qMenu) {
        qMenu.parentNode.removeChild(qMenu)
      }
    }
  }

  monitorForPlaybackProgress = (e, player) => {
    console.log(
      `PROGRESS fired: seeking=${player.seeking}; playing=${player.playing}; paused=${player.paused}`,
    )
    this._progressEventTicker++
    if (this._progressEventTicker === 1) {
      if (player.seeking !== true && player.paused !== true) {
        // Progress event is fired with seeking without pausing the video
        console.log('Updating usage analytics for rendering playback.')
        this.updateLastUsageAnalyticsPacket(e, player)
      }
      this._progressEventTicker = 0
    }
  }

  updateLastUsageAnalyticsPacket = (e, player) => {
    const usageAnalyticsModel = this.state.usageAnalyticsModel
    usageAnalyticsModel.updateCurrentUsageAnalytics(false, player.video.time)
    this.setState({
      usageAnalyticsModel: usageAnalyticsModel,
    })
  }

  updateLastUsageAnalyticsPacketFinish = (e, player) => {
    const usageAnalyticsModel = this.state.usageAnalyticsModel
    usageAnalyticsModel.updateCurrentUsageAnalytics(false, player.video.time)
    this.setState(
      {
        usageAnalyticsModel: usageAnalyticsModel,
      },
      () => {},
    )
  }

  displayInteractiveQuestion = (e, player, cuepoint) => {
    if (
      !this.state.userSubmittedCorrectAnswers.includes(cuepoint.time * 1000)
    ) {
      player.pause()
      this.findAndDisplayQuestionFromTime(cuepoint.time * 1000)
    }
  }

  updateCurrentUsageAnalytics = () => {
    return new Promise((resolve, reject) => {
      if (
        this.state.flowplayerInstance &&
        this.state.flowplayerInstance.playing
      ) {
        this.updateLastUsageAnalyticsPacket({}, this.state.flowplayerInstance)
      }

      // Trigger the callback with usage analytics data
      const usageAnalyticsModel = this.state.usageAnalyticsModel

      const analyticsData = {
        metaData: usageAnalyticsModel.getCurrentUsageAnalytics(),
        playbackDuration: usageAnalyticsModel.getTotalPlaybackPercentage(),
        viewedCount: this.state.videoActivity.viewedCount,
        markComplete:
          !this.state.videoActivity.isComplete &&
          (usageAnalyticsModel.isUsageAlreadyComplete() ||
            usageAnalyticsModel.isUsageComplete()),
      }

      /*window.parent.postMessage(
        JSON.stringify({
          type: 'disprz_saveAnalyticsData',
          analyticsData: analyticsData,
        }),
        '*',
      )*/
      resolve(analyticsData)
    })
  }

  findAndDisplayQuestionFromTime = (time) => {
    const questionToDisplayIndex = this.state.interactiveQuestions.findIndex(
      function (q) {
        return (
          Math.round(q.timeToProjectQuestion * 100) / 100 ===
          Math.round(time * 100) / 100
        )
      },
    )

    // console.log("Storing object for reverting",questionToDisplay);

    //		alert(questionToDisplayIndex + 1);
    if (
      questionToDisplayIndex >= 0 &&
      !this.state.userSubmittedCorrectAnswers.includes(
        this.state.interactiveQuestions[questionToDisplayIndex]
          .timeToProjectQuestion,
      )
    ) {
      /*window.parent.postMessage(
        JSON.stringify({
          type: 'disprz_displayQuestion',
          currentQuestionNumber: this.state.interactiveQuestions[
            questionToDisplayIndex
          ].questionId,
        }),
        '*',
      )*/
      //TODO: We have to open a popup
      this.setState({
        questionToDisplay: this.state.interactiveQuestions[
          questionToDisplayIndex
        ],
      })
    }
  }

  clearContextMenu = () => {
    const flowplayerEl = document.getElementById('flowplayerEl')
    const cMenu = flowplayerEl.querySelector('.fp-context-menu')
    if (cMenu) {
      cMenu.innerHTML = ''
    }
  }

  initInteractiveQuestions = (interactiveQuestions = []) => {
    const toReturn = []
    interactiveQuestions.forEach(function (questionData) {
      const videoInteractiveQuestion = this.deserializeFromSavedData(
        questionData,
      )
      toReturn.push(videoInteractiveQuestion)
    }, this)
    return toReturn
  }

  deserializeFromSavedData = (data) => {
    const questionData = {...JSON.parse(data.questionObject)} //
    questionData.questionId = data.questionId
    return questionData
  }
  onDownload = () => {
    window.parent.postMessage(
      JSON.stringify({
        type: 'disprz_downloadVideo',
      }),
      '*',
    )
  }
  displayImaginarySeekbar = () => {
    return (
      <ImaginarySeekbar
        ref={this.imaginarySeekBar}
        interactiveQuestions={this.state.interactiveQuestions}
        onQuestionMarkerSelection={this.displayQuestion}
        editable={false}
        isPublished={true}
        allowDownload={
          this.state.showDownload && this.state.videoActivity.isDownloadable
        }
        userSubmittedCorrectAnswers={this.state.userSubmittedCorrectAnswers}
        totalDuration={this.state.totalDuration * 1000}
        onDownload={this.onDownload}
      />
    )
  }

  rewatchClipFromPreviousCuepoint(currentCuePoint) {
    let highestCuepoint = 0
    this.state.flowplayerInstance.cuepoints.forEach((cuepoint) => {
      if (cuepoint * 1000 < currentCuePoint) {
        highestCuepoint = Math.max(cuepoint, highestCuepoint)
      }
    })

    if (highestCuepoint > 0) {
      highestCuepoint += 0.1
    }
    this.setState(
      {
        toShowInteractiveQuestion: false,
        isRewatchedClip: true,
        questionToDisplay: null,
      },
      function () {
        this.state.flowplayerInstance.seek(highestCuepoint)
        this.state.flowplayerInstance.play()
      },
    )
  }

  setVideoDuration = (e, player, videoInfo) => {
    const durationInMs = parseInt(videoInfo.duration * 1000)
    this.state.usageAnalyticsModel.setPlaybackDuration(durationInMs)
    this.setState({
      videoMetaDataLoaded: true,
      totalDuration: videoInfo.duration,
    })

    if (!this.state.videoActivity.isComplete) {
      const lastPlayedTimelineMarkerInMs = this.state.usageAnalyticsModel.getLastPlayedTimelineMarkerInMs()
      if (lastPlayedTimelineMarkerInMs !== 0) {
        const seekTo = lastPlayedTimelineMarkerInMs / 1000 - 2
        if (seekTo > 0) {
          player.seek(seekTo)
        } else {
          player.seek(lastPlayedTimelineMarkerInMs / 1000)
        }
      }
    }
  }

  displayQuestion = (questionId) => {
    /*window.parent.postMessage(
      JSON.stringify({
        type: 'disprz_displayQuestion',
        currentQuestionNumber: questionId,
      }),
      '*',
    )*/
    //We're not showing video question when we click on the Question marker
  }

  renderSeekForwardPopup = () => {
    if (!this.state.displaySeekForwardPopup) {
      return null
    }
    return (
      <div className="seekforward-popup">
        <div className="seekforward-popup-block">
          <div className="summary-text">
            You are not allowed to skip sections of video
          </div>
        </div>
      </div>
    )
  }

  formatUrl = (url) => {
    const isAndroid = /(android)/i.test(navigator.userAgent)
    if (url.includes('sig=') && isAndroid) {
      if (url) {
        const search = url.split('?')[1]
      }
      const sigValue = url.split('sig=')[1].split('&se=')[0]

      return url.replace(sigValue, encodeURIComponent(sigValue))
    }
    return url
  }

  onSubmitInteractiveQuestionAnswer(metadata, questionId) {
    const displayingQuestion = this.state.interactiveQuestions.find(
      (q) => q.questionId === questionId,
    )
    const isQuestionSubmittedAlready = this.state.userSubmittedCorrectAnswers.find(
      (q) => q === displayingQuestion.questionId,
    )
    if (!isQuestionSubmittedAlready) {
      metadata.isRewatched = this.state.hasReWatchedClip
      this.setState(
        (prevState) => {
          // prevState.userResponseMetaData.push(metadata)
          return {
            userResponseMetaData: [metadata],
          }
        },
        () => {
          if (metadata.isCorrect) {
            const interactiveQuestionVideoToSave = {
              interactiveVideoId: parseInt(this.props.activityId),
              userResponseMetadata: JSON.stringify(
                this.state.userResponseMetaData,
              ),
              userId: this.props.userId,
              questionId: displayingQuestion.questionId,
              correctOption: metadata.answer[0],
            }
            updateVideoInteractiveUserResponse(
              interactiveQuestionVideoToSave,
            ).then(() => {
              this.setState(
                (prevState) => {
                  prevState.userSubmittedCorrectAnswers.push(
                    displayingQuestion.timeToProjectQuestion,
                  )
                  return {
                    hasReWatchedClip: false,
                    userResponseMetaData: [],
                    userSubmittedCorrectAnswers:
                      prevState.userSubmittedCorrectAnswers,
                    questionToDisplay: null,
                  }
                },
                () => {
                  this.state.flowplayerInstance?.play()
                },
              )
            })
          }
        },
      )
    }
  }

  renderVideoQuizOverlayView = () => {
    return (
      this.state.questionToDisplay && (
        <InteractiveQuestionsView
          onSubmit={this.onSubmitInteractiveQuestionAnswer}
          questionToDisplay={this.state.questionToDisplay}
          onRewatchClip={this.rewatchClipFromPreviousCuepoint}
        />
      )
    )
  }

  render() {
    if (this.state.loadingState === LoadingState.FETCHING) {
      return (
        <div className={'flexCentered background fullHeight'}>Fetching...</div>
      )
    }

    if (this.state.loadingState === LoadingState.EMPTY) {
      return (
        <div className={'flexCentered background fullHeight'}>
          Something went wrong
        </div>
      )
    }

    let viewProgressIndicator = ''

    if (this.state.videoActivity.isComplete) {
      viewProgressIndicator = (
        <PlaybackIndicator
          playbackData={[{start: 0, end: 100}]}
          playbackDuration={100}
        />
      )
    } else {
      viewProgressIndicator = (
        <PlaybackIndicator
          playbackData={this.state.usageAnalyticsModel.getPlaybackAnalytics()}
          playbackDuration={this.state.usageAnalyticsModel.getPlaybackDuration()}
        />
      )
    }

    const inStyle = {
      backgroundImage: 'url(' + this.state.thumbnailUrl + ')',
    }

    return (
      <div
        className={
          'video-activity ' +
          (this.state.fullscreenView ? 'progressbar-top' : 'video-nonswipe')
        }
        ref={this.videoActivity}>
        <div
          id="flowplayerEl"
          style={inStyle}
          className="flowplayer fp-full fixed-controls no-toggle video-activity-element position-relative">
          {this.state.flowplayerInstance && this.displayImaginarySeekbar()}
          {this.state.flowplayerInstance && viewProgressIndicator}
          {this.state.flowplayerInstance && this.renderVideoQuizOverlayView()}
        </div>
        {this.renderSeekForwardPopup()}
        {this.state.showTopView && (
          <div
            onClick={() => {
              this.setState(
                {
                  showTopView: false,
                },
                () => {
                  const ele = document.getElementsByClassName('fp-ui')
                  if (ele.length > 0) {
                    ele[0].click()
                  }
                },
              )
            }}
            className="tapview">
            Tap to play
          </div>
        )}
      </div>
    )
  }
}

export default VideoPlayer
