import AbstractQuizQuestion from './AbstractQuizQuestion'

class MultipleChoiceQuestion extends AbstractQuizQuestion {
  constructor(config = {}) {
    super(config)
    this.options = config.options || []
    this.correctAnswer = config.correctAnswer || 0
    this.z_correctAnswers = config.z_correctAnswers || [this.correctAnswer]
  }

  getConstructor() {
    return 'MultipleChoiceQuestion'
  }

  validateQuestion() {
    if (this.question.itemText.length > 0) {
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].itemText.length === 0) {
          return false
        }
        for (let j = i + 1; j < this.options.length; j++) {
          if (this.options[i].itemText === this.options[j].itemText) {
            return false
          }
        }
      }
    } else {
      return false
    }
    return true
  }

  curateData() {
    if (this.z_correctAnswers.length === 1 && this.z_correctAnswers[0] === 0) {
      this.z_correctAnswers = [this.correctAnswer]
    }
  }

  isCorrectAnswer(optionNumbers) {
    return this.z_correctAnswers.includes(optionNumbers)
  }

  setCorrectAnswers(optionNumbers) {
    this.z_correctAnswers = optionNumbers
  }

  getCorrectAnswers() {
    return this.z_correctAnswers
  }
}

export default MultipleChoiceQuestion
