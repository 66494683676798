import '../styles/ThankYouView.scss'
import {useHistory} from 'react-router-dom'

const ThankYouView = () => {
  const history = useHistory()

  const handleBackButtonClick = () => {
    history.push('/modules')
  }

  return (
    <>
      <div className="content-div">
        <div className="thankyou-text">
          You have successfully completed this module
        </div>
        <div className="button-div">
          <div className="tybutton-text" onClick={handleBackButtonClick}>
            Go Back
          </div>
        </div>
      </div>
    </>
  )
}

export default ThankYouView
