import QuizQuestion from './QuizQuestion'
import QuizQuestionDifficultyLevel from './QuizQuestionDifficultyLevel'

class QuizSettings {
  constructor(config = {}) {
    this.launchCount = config.launchCount
    this.answerTiming = config.answerTiming
    this.isRevealedBenchmarkReport = config.isRevealedBenchmarkReport || false
    this.allowPartialSubmission = config.allowPartialSubmission !== false
    this.allowedDuration = config.allowedDuration || -1 // In minutes, -1 for untimed quiz
    this.shouldJumbleQuestion = config.shouldJumbleQuestion || false
    this.shouldRevelExplanation = config.shouldRevelExplanation || false
    this.shouldRevealExplanationByQuestion =
      config.shouldRevealExplanationByQuestion || false
    this.passCriteria = config.passCriteria || ''
    this.markingScheme = (
      config.markingScheme || this.getDefaultMarkingScheme()
    ).map((item) => new QuizQuestionDifficultyLevel(item))
    this.selectAllQuestions = config.selectAllQuestions !== false // todo set default as false
    this.questionBreakdown = config.questionBreakdown || []
    this.selectedQuestionCollection = config.selectedQuestionCollection || []
    this.differentQuestionForLearners =
      config.differentQuestionForLearners || false
    this.questionRandomisedBasedOn =
      config.questionRandomisedBasedOn ||
      QuizSettings.RandomisedState.SECTION_DIFFICULTY_BOTH
    this.questionCount = config.questionCount || null
    this.reattemptsAllowed = config.reattemptsAllowed || 0
    this.isPassNeeded = config.isPassNeeded || false
    this.isReattemptToBeatScore = config.isReattemptToBeatScore || false
  }

  getDefaultMarkingScheme() {
    return [
      {
        difficultyLevelId: QuizQuestion.DifficultyLevels.EASY,
        difficultyLevelName: 'Easy',
        positivePoints: 1,
        negativePoints: 0,
      },
      {
        difficultyLevelId: QuizQuestion.DifficultyLevels.MEDIUM,
        difficultyLevelName: 'Medium',
        positivePoints: 2,
        negativePoints: 0,
      },
      {
        difficultyLevelId: QuizQuestion.DifficultyLevels.DIFFICULT,
        difficultyLevelName: 'Diifcult',
        positivePoints: 3,
        negativePoints: 0,
      },
    ]
  }

  setMarkingSchemePoints(difficultyLevelId, pointType, points) {
    const markingSchemeItem = this.getMarkingSchemeForDifficultyLevel(
      difficultyLevelId,
    )
    if (markingSchemeItem) {
      markingSchemeItem.setPoints(pointType, points)
    }
  }

  getMarkingScheme() {
    return this.markingScheme
  }

  getMarkingSchemeForDifficultyLevel(difficultyLevelId) {
    const markingSchemeItem = this.markingScheme.find(function (item) {
      return item.difficultyLevelId === difficultyLevelId
    })
    return markingSchemeItem
  }

  setAllowedDuration(newAllowedDuration) {
    this.allowedDuration = newAllowedDuration
  }

  setPassCriteriaValue(newPassCriteriaValue) {
    this.passCriteria = newPassCriteriaValue
  }

  setReattemptValue(value) {
    this.reattemptsAllowed = value
  }

  getAllowedDuration() {
    return this.allowedDuration
  }

  getSettingValue(settingCategory) {
    let settingValue
    switch (settingCategory) {
      /*case Skilltron.model.quiz.QuizSettings.Categories.LAUNCH_COUNT:
                settingValue = this.launchCount;
                break;
            case Skilltron.model.quiz.QuizSettings.Categories.ANSWER_TIMING:
                settingValue = this.answerTiming;
                break;*/
      case QuizSettings.Categories.ALLOWED_DURATION:
        settingValue = this.allowedDuration
        break
      case QuizSettings.Categories.MARKING_SCHEME:
        settingValue = this.markingScheme
        break
      default:
        console.warn(
          'No setting value available for category ' + settingCategory,
        )
        break
    }
    return settingValue
  }

  setSettingValue(settingCategory, settingValue) {
    switch (settingCategory) {
      /*case Skilltron.model.quiz.QuizSettings.Categories.LAUNCH_COUNT:
                this.launchCount = settingValue;
                break;
            case Skilltron.model.quiz.QuizSettings.Categories.ANSWER_TIMING:
                this.answerTiming = settingValue;
                break;*/
      case QuizSettings.Categories.ALLOWED_DURATION:
        this.allowedDuration = settingValue
        break
      case QuizSettings.Categories.MARKING_SCHEME:
        this.markingScheme = settingValue
        break
      default:
        console.warn('No setting available for category ' + settingCategory)
        break
    }
  }

  getNumberOfQuestionsToLaunch(questionCount) {
    return this.questionCount !== null ? this.questionCount : questionCount
  }

  shouldDisplayAllQuestionsToLearner() {
    return this.selectAllQuestions
  }

  isRandomisedOnSectionsAndDifficulty() {
    return (
      this.questionRandomisedBasedOn ===
      QuizSettings.RandomisedState.SECTION_DIFFICULTY_BOTH
    )
  }

  setRandomisedSelection(value) {
    this.questionRandomisedBasedOn = value
    this.questionBreakdown = []
  }

  setQuestionCount(value) {
    this.questionCount = value
  }

  isReattemptAllowed(currentAttemptCount) {
    return (
      (this.reattemptsAllowed !== 0 &&
        currentAttemptCount <= this.reattemptsAllowed) ||
      this.reattemptsAllowed === -1
    )
  }
}

QuizSettings.Categories = {
  ALLOWED_DURATION: 1,
  MARKING_SCHEME: 2,
}

QuizSettings.RandomisedState = {
  SECTION_DIFFICULTY_BOTH: 1,
  DIFFICULTY_ONLY: 2,
}

export default QuizSettings
