import '../../styles/ActivityView.scss'
import {BackIcon} from '../../icons'
import {useHistory} from 'react-router-dom'

const ActivityHeader = ({activityName}) => {
  const history = useHistory()
  return (
    <div className="activity-header-box">
      <div
        className="activity-back-button"
        onClick={() => {
          history.goBack()
        }}>
        <BackIcon fill={'black'} />
      </div>
      <div className="activity-name">{activityName}</div>
    </div>
  )
}

export default ActivityHeader
