class DocumentActivity {
  constructor(config = {}) {
    this.activityId = config.activityId || 0
    this.activityName = config.activityName || ''
    this.convertedPagesInfo = config.convertedPagesInfo
    this.isDownloadable = config.isDownloadable !== false
    this.isEmbedded = config.isEmbedded || false
    this.isPublished = config.isPublished || false
    this.isPersonalized = config.isPersonalized || false // Would apply only for Edu workflow

    this.isMandatory = config.isMandatory !== false
    this.extension = config.extension || ''
    this.usageAnalytics = this.parseAnalytics(config.usageAnalytics || [])
    this.isComplete = config.isComplete === true // Learner property
  }

  parseAnalytics(analyticsData) {
    const usageAnalytics = []
    analyticsData.forEach(function (data) {
      if (data && data.length > 0) {
        usageAnalytics.push(JSON.parse(data))
      } else {
        /*console.warn(
					`Encountered an empty analytics usage entry for document activityId =  ${
						this.activityId
					}. Ignoring.`
				);*/
      }
    })
    return usageAnalytics
  }
}

export default DocumentActivity
