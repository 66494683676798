import {useEffect, useState} from 'react'
import {useAuthContext} from '../auth'
import '../../styles/ModuleView.scss'
import ModuleDetails from './ModuleDetails'
import {BackIcon, ShareIcon} from '../../icons/index'
import {useHistory, useParams} from 'react-router-dom'
import {fetchActivities} from '../axios'
import {LoadingState, ActivityType} from '../enums'
import estimateTimeCalculation from '../helpers/estimateTimeCalculation'

const ModuleView = ({
  activityData,
  setActivityData,
  allId,
  setAllId,
  allType,
  setAllType,
  setNextActivity,
  setActivityName,
}) => {
  const history = useHistory()
  const {id} = useParams()
  const [loadingState, setLoadingState] = useState(LoadingState.FETCHING)
  const [showNotSupportedModal, setNotSupportedModal] = useState(false)

  const notSupportedModalBody = `This activity is not supported on mobile web - please download the app to view`

  const activitngWording = (length) => (length > 1 ? 'Activities' : 'Activity')

  const allowedActivityType = [
    ActivityType.DIGITAL_WHITEBOARD,
    ActivityType.QUIZ,
    ActivityType.VIDEO,
    ActivityType.INTERACTIVE,
  ]

  const {appUser} = useAuthContext()

  useEffect(() => {
    if (appUser) {
      const retrieveActivities = async () => {
        const response = await fetchActivities(id)
        if (response) {
          // const filterActivities = response.activities.filter((a) =>
          //   allowedActivityType.includes(a.activityType),
          // )
          // const result = {...response, activities: filterActivities}
          setActivityData(response)
          const ids = response?.activities.map((a) => a.activityId)
          const types = response?.activities.map((a) => a.activityType)
          setAllId(ids)
          setAllType(types)
          setLoadingState(LoadingState.AVAILABLE)
        } else {
          setLoadingState(LoadingState.EMPTY)
        }
      }
      retrieveActivities()
    }
  }, [appUser])

  if (loadingState === LoadingState.FETCHING) {
    return (
      <div className={'flexCentered background fullHeight'}>
        <span>Fetching...</span>
      </div>
    )
  }

  if (loadingState === LoadingState.EMPTY) {
    return (
      <div className={'flexCentered background fullHeight'}>
        Something went wrong. Try again
      </div>
    )
  }

  const handleActivityClick = (activityId, type, activityName) => {
    if (allowedActivityType.includes(type)) {
      const loc = allId.indexOf(activityId)
      const nextActivityId =
        loc !== activityData?.activities.length - 1 ? allId[loc + 1] : null
      const nextType =
        loc !== activityData?.activities.length - 1 ? allType[loc + 1] : null
      const nextActivity = {
        id: nextActivityId,
        current: loc,
        totalActivity: allId.length,
        type: nextType,
      }
      setNextActivity(nextActivity)
      setActivityName(activityName)
      history.push(`/activity/${type}/${activityId}`)
    } else {
      setNotSupportedModal(true)
    }
  }

  const getAssessedDuration = (
    activityType,
    volume,
    estimatedDuration,
    metaInformation,
  ) => {
    const meta = estimateTimeCalculation(
      activityType,
      volume,
      estimatedDuration,
      metaInformation,
    )
    return meta.estimatedTime === -1 ? 0 : meta.estimatedTime
  }

  const estimatedDurationForAllActivities = () => {
    let duration = 0
    activityData.activities.forEach(function (a) {
      duration += getAssessedDuration(
        a?.activityType,
        a?.volume,
        a?.estimatedDuration,
        a?.metaInformation,
      )
    })
    return duration
  }

  const handleOK = () => {
    setNotSupportedModal(false)
  }

  return (
    <div className="container-module">
      <div className="header">
        <img
          src={activityData?.moduleImageUrl}
          alt=""
          className="module-image"
        />
        <div className={'gradient'} />
        <div
          className="top-bar-icons left"
          onClick={() => {
            history.goBack()
          }}>
          <BackIcon className="back-button" />
        </div>
        <div className="module-name">{activityData?.name}</div>
        <div className="module-size">{`${activityData?.activities.length} 
        ${activitngWording(
          activityData?.activities.length,
        )} | ${estimatedDurationForAllActivities()} Minutes`}</div>
      </div>
      <ModuleDetails
        activityData={activityData}
        handleActivityClick={handleActivityClick}
      />
      {showNotSupportedModal && (
        <div className="not-supported-background">
          <div className="not-supported-modal">
            <div className="not-supported-content-div">
              <div className="not-supported-modal-body">
                {notSupportedModalBody}
              </div>
              <div className="not-supported-action-div">
                <div className="not-supported-action-button" onClick={handleOK}>
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ModuleView
