import axios from 'axios'

const fetchModuleListing = async (offset = 0) => {
  try {
    const loggingEnabled = false
    const logSender = 'MyPendingCourses'
    const url = `/api/module/getPagedToDoList?limit=10&offset=${offset}&sortBy=enrollmentDateDesc`
    const response = await axios.get(url, {
      headers: {
        loggingEnabled,
        logSender
      },
    })
    console.log(response)
    return {
      pendingModules: response.data.pendingModules,
      totalCount: response.data.totalCount,
    }
  } catch (e) {
    console.log(e)
  }
}

export {fetchModuleListing}
