import {ActivityType} from '../enums'

const formatToTimeString = (milliseconds) => {
  const totalSeconds = Math.round(milliseconds / 1000)
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60

  // Converting 0:60 to 1:00
  if (seconds === 60) {
    seconds = 0
    minutes++
  }

  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }

  return `${minutes}:${seconds}`
}

const getNumberOfQuestionsToLaunch = (questionCount) => {
  return questionCount !== null ? questionCount : null
}

const estimateTimeCalculation = (
  activityType,
  volume,
  estimatedDuration,
  metaInformation,
) => {
  let etcValue = 0,
    volumeDisplay = volume,
    typeName,
    unit = ''
  switch (activityType) {
    case ActivityType.DIGITAL_WHITEBOARD:
      typeName = 'Document'
      etcValue =
        estimatedDuration === 0 ? volume * 2 : Math.ceil(estimatedDuration / 60)
      unit = 'en'
      break
    case ActivityType.QUIZ:
      typeName = 'Quiz'
      if (metaInformation && metaInformation.length > 0) {
        const activityMetaInfo = JSON.parse(metaInformation)
        etcValue = activityMetaInfo?.allowedDuration || -1
        etcValue = etcValue === -1 ? 0 : etcValue
        volumeDisplay = getNumberOfQuestionsToLaunch(volume)
      }
      unit = 'en'
      break
    case ActivityType.VIDEO:
      typeName = 'Video'
      etcValue =
        estimatedDuration === 0
          ? Math.ceil(volume / 60)
          : Math.ceil(estimatedDuration / 60) //Taking ceiling on minutes
      unit = 'Runtime'
      volumeDisplay = formatToTimeString(volume * 1000)
      break
    case ActivityType.INTERACTIVE:
      typeName = 'Interactive'
      etcValue =
        estimatedDuration === 0
          ? volume * 10
          : Math.ceil(estimatedDuration / 60) // TODO: HARDCODED VALUE
      unit = 'en'
      break
    default:
      etcValue = 0
  }
  return {
    typeName: typeName,
    estimatedTime: etcValue,
    unit,
    volumeDisplay,
  }
}

export default estimateTimeCalculation
