import {useCallback, useState, useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import '../../../styles/VideoPlayer.scss'

const InteractiveQuestionsView = ({
  questionToDisplay,
  onSubmit,
  onRewatchClip,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([])
  const [
    hasUserSubmittedTheQuestion,
    setHasUserSubmittedTheQuestion,
  ] = useState(false)
  const onSubmitTimeout = useRef(null)

  const parsedQuestionObject = JSON.parse(questionToDisplay.questionObject)

  const questionTag = parsedQuestionObject?.question?.itemText
  const question = questionTag?.replace('auto', '100%')

  const isAnswerCorrect = parsedQuestionObject.z_correctAnswers.includes(
    selectedOptions[0],
  )

  useEffect(() => {
    return () => clearTimeout(onSubmitTimeout.current)
  }, [])

  const onSubmitWrapper = useCallback(() => {
    const isCorrect = parsedQuestionObject.z_correctAnswers.includes(
      ...selectedOptions,
    )
    const metaDataToSend = {
      answer: selectedOptions,
      isCorrect,
    }
    setHasUserSubmittedTheQuestion(true)
    if (isCorrect) {
      onSubmitTimeout.current = setTimeout(() => {
        console.log('Debug - onSubmit is called now', metaDataToSend)
        onSubmit(metaDataToSend, questionToDisplay.questionId)
        //TODO: Close the popup here after a 3 secs timeout
      }, 3000)
    }
  }, [questionToDisplay?.questionId, selectedOptions])

  if (selectedOptions.length > 0 && hasUserSubmittedTheQuestion) {
    if (isAnswerCorrect) {
      return (
        <div className="interactive-questions outer-div">
          <div className="inner-div">
            <div className={'flexCentered'}>
              Brilliant, That is the right answer! Happy Learning!
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="interactive-questions outer-div">
          <div className="inner-div flexCentered">
            <div>Oops! That is not the right answer! Try Again</div>
            <div className="actions-container">
              <div
                className="video-quiz-action-button"
                onClick={() => {
                  onRewatchClip(questionToDisplay.timeToProjectQuestion)
                }}>
                <div className="video-quiz-button-content">Rewatch Clip</div>
              </div>
              <div
                className="video-quiz-action-button"
                onClick={() => {
                  setSelectedOptions([])
                  setHasUserSubmittedTheQuestion(false)
                }}>
                <div className="video-quiz-button-content">Retake Question</div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  const handleOptionClick = (i) => {
    setSelectedOptions([i])
  }

  const renderQuiz = () => {
    return (
      <>
        <div
          className="question"
          dangerouslySetInnerHTML={{
            __html: question,
          }}
        />
        {parsedQuestionObject?.options.map((o, i) => (
          <div
            className={
              selectedOptions.includes(i + 1)
                ? 'selected-options-div options-div'
                : 'options-div'
            }
            onClick={() => handleOptionClick(i + 1)}>
            <div
              className={
                selectedOptions.includes(i + 1)
                  ? 'selected-option-name-div option-name-div'
                  : 'option-name-div option-name-div'
              }>
              <div
                className={
                  selectedOptions.includes(i + 1)
                    ? 'selected-option-name option-name'
                    : 'option-name'
                }>
                {String.fromCharCode(65 + i)}
              </div>
            </div>
            <div
              className={
                selectedOptions.includes(i + 1)
                  ? 'selected-option-text option-text'
                  : 'option-text'
              }
              dangerouslySetInnerHTML={{
                __html: o.itemText,
              }}
            />
          </div>
        ))}
        <div className="action-button">
          <div
            className={'navigation-button submit-text'}
            onClick={onSubmitWrapper}>
            Submit
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="interactive-questions outer-div">
        <div className="inner-div">{renderQuiz()}</div>
      </div>
    </>
  )
}

InteractiveQuestionsView.propTypes = {}

export default InteractiveQuestionsView
