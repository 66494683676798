import {
  CompletedIcon,
  IncompleteIcon,
  WebLinkIcon,
  TimeIcon,
  AssignmentIcon,
  YouTubeIcon,
  AssessmentIcon,
  FlashCardIcon,
  DocumentIcon,
  VideoIcon,
  InteractiveIcon,
  NotStartedIcon,
} from '../../icons/index'
import {ActivityType} from '../enums'
import estimateTimeCalculation from '../helpers/estimateTimeCalculation'

import '../../styles/DisplayActivity.scss'

const DisplayActivity = ({activity, handleActivityClick}) => {
  const isCompleted =
    activity?.isCompleted && activity?.isInProgress ? (
      <CompletedIcon />
    ) : !activity?.isCompleted && activity?.isInProgress ? (
      <IncompleteIcon />
    ) : (
      <NotStartedIcon />
    )

  const identifyActivity = () => {
    if (activity.activityType === ActivityType.DIGITAL_WHITEBOARD) {
      return <DocumentIcon />
    } else if (activity.activityType === ActivityType.ASSIGNMENT) {
      return <AssessmentIcon />
    } else if (activity.activityType === ActivityType.QUIZ) {
      return <AssignmentIcon />
    } else if (activity.activityType === ActivityType.WEBLINK) {
      return <WebLinkIcon />
    } else if (activity.activityType === ActivityType.VIDEO) {
      return <VideoIcon />
    } else if (activity.activityType === ActivityType.INTERACTIVE) {
      return <InteractiveIcon />
    } else if (activity.activityType === ActivityType.YOUTUBE_VIDEO) {
      return <YouTubeIcon />
    } else if (activity.activityType === ActivityType.FLASHCARD) {
      return <FlashCardIcon />
    }
  }

  const estimateTime = () => {
    const estimateObject = estimateTimeCalculation(
      activity?.activityType,
      activity?.volume,
      activity?.estimatedDuration,
      activity?.metaInformation,
    )
    return estimateObject.estimatedTime
  }

  return (
    <div
      className="activity-box"
      onClick={() =>
        handleActivityClick(
          activity.activityId,
          activity.activityType,
          activity.name,
        )
      }>
      <div className="activity-icon-div">
        <div className="activity-icon">{identifyActivity()}</div>
      </div>
      <div className="activity-name-div">{activity.name}</div>
      {estimateTime() !== 0 ? (
        <>
          <div className="activity-time-icon">
            <TimeIcon />
          </div>
          <div className="activity-time">{`${estimateTime()}:00`}</div>
        </>
      ) : null}
      <div className="progress-icon">{isCompleted}</div>
    </div>
  )
}

export default DisplayActivity
