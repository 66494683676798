import React from 'react'
import {
  reAttemptQuizForParticipant,
  retrieveQuizForParticipant,
  saveQuizResponsesForParticipant,
  submitQuizResponsesForParticipant,
} from '../../axios'
import QuizParticipantAnswerSummary from './model/QuizParticipantAnswerSummary'
import QuizCountDownTimer from './QuizCountDownTimer'
import QuizInstruction from './QuizInstrution'
import {ActivityType, LoadingState, QuizParticipantViewState} from '../../enums'
import AnalyticsTracker from '../AnalyticsTracker'
import ActivityItemsUsageAnalyticsModel from '../model/ActivityItemsUsageAnalyticsModel'
import ActivityHeader from '../ActivityHeader'
import DisplayQuestions from './DisplayQuestions'
import ResultView from './ResultView'

class QuizActivityView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displaySubmit: false,
      askPermission: false,
      selectedOptions: [],
      quizSettings: {},
      quizQuestionAnswers: [],
      loadingState: LoadingState.FETCHING,
      quizViewMode: null,
      usageAnalyticsModel: null,
      usageTimeStamp: new Date().valueOf(),
      answeringTimeStamp: new Date().valueOf(),
      currentQuestionNumber: 0,
      attemptCount: 0,
      multiSelectOptionEnabled: false,
      isRevealed: false,
      isCompleted: false,
      showValidationError: false,
    }
    this.analyticsTracker = null
    this.currentLanguageActivityId = this.props.activityId

    this.onUpdateQuizParticipantStatus = this.onUpdateQuizParticipantStatus.bind(
      this,
    )
    this.onNext = this.onNext.bind(this)
    this.onPrevious = this.onPrevious.bind(this)
    this.handleOptionClick = this.handleOptionClick.bind(this)
    this.onSubmitQuiz = this.onSubmitQuiz.bind(this)
    this.hasUserPassed = this.hasUserPassed.bind(this)
    this.onReviewClick = this.onReviewClick.bind(this)
    this.handleValidationOK = this.handleValidationOK.bind(this)
    this.handleCancelAskPermission = this.handleCancelAskPermission.bind(this)
  }

  componentDidMount() {
    this.fetchQuizParticipantData(this.props.activityId)
  }

  componentWillUnmount() {
    this.analyticsTracker?.stopTracking()
    this.analyticsTracker = null
  }

  fetchQuizParticipantData = async (
    activityId = this.props.activityId,
    isReattempt = false,
  ) => {
    const response = await retrieveQuizForParticipant(activityId, isReattempt)
    if (response) {
      const usageAnalyticsModel = new ActivityItemsUsageAnalyticsModel({
        activityItemsCount: response.quizQuestionAnswers.length,
      })
      this.setState(
        {
          quizSettings: response.quizSettings,
          quizQuestionAnswers: response.quizQuestionAnswers,
          questionToDisplay: 0,
          isRevealed: response.isRevealed,
          attemptCount: response.attemptCount,
          quizViewMode: response.viewStatus,
          isCompleted:
            response.viewStatus === QuizParticipantViewState.SUBMITTED,
          loadingState: LoadingState.FETCHED_AND_AVAILABLE,
          usageAnalyticsModel: usageAnalyticsModel,
          usageTimeStamp: new Date().valueOf(),
          answeringTimeStamp: new Date().valueOf(),
          multiSelectOptionEnabled: response.isMultiSelectEnabledForLearner,
          askPermission: false,
          displaySubmit: false,
          selectedOptions: [],
        },
        () => {
          this.analyticsTracker = new AnalyticsTracker({
            updateCurrentUsageAnalytics: this.updateCurrentUsageAnalytics,
            analyticsId: response.analyticsId,
            activityId: activityId,
            activityType: ActivityType.QUIZ,
          })
          this.analyticsTracker.setControlTimeout()
          if (response.viewStatus === QuizParticipantViewState.ANSWERING) {
            this.updateCurrentUsageAnalytics()
          }
        },
      )
    } else {
      this.setState({
        loadingState: LoadingState.EMPTY,
      })
    }
  }

  updateCurrentUsageAnalytics = () => {
    return new Promise((resolve, reject) => {
      // Analytics to be captured only in Answering mode when an analyticsModel has been initialized post-fetch of quiz questions
      if (this.state.quizViewMode !== QuizParticipantViewState.ANSWERING) {
        if (this.state.usageAnalyticsModel) {
          const isPassed = this.state.quizSettings.isPassNeeded
            ? this.hasUserPassed()
            : true
          const isCompleted =
            (this.state.quizViewMode === QuizParticipantViewState.SUBMITTED ||
              this.state.quizViewMode === QuizParticipantViewState.VIEWING) &&
            isPassed
          if (isCompleted) {
            this.setState({
              isCompleted: true,
            })
            //Not sending this.currentLanguageActivityId here as this might not be same as this.props.activityId
            /*this.props.onUpdateActivityConsumed(
              this.props.activityId,
              this.currentLanguageActivityId,
              this.props.displayedActivityId
            );*/
          }
          resolve({
            metaData: this.state.usageAnalyticsModel.getCurrentUsageAnalytics(),
            markComplete: isCompleted,
          })
        } else {
          resolve({})
        }
        return
      }

      const usageAnalyticsModel = this.state.usageAnalyticsModel
      const now = new Date().valueOf()
      usageAnalyticsModel.updateCurrentUsageAnalytics(
        this.state.currentQuestionNumber + 1,
        now - this.state.usageTimeStamp,
      )
      this.setState({
        usageAnalyticsModel: usageAnalyticsModel,
        usageTimeStamp: now,
      })

      // Trigger the callback with usage analytics data
      resolve({
        metaData: usageAnalyticsModel.getCurrentUsageAnalytics(),
        markComplete: false,
      })
    })
  }

  onUpdateQuizParticipantStatus = (updatedQuiz) => {
    this.setState(
      {
        quizViewMode: QuizParticipantViewState.ANSWERING,
        attemptCount: this.state.attemptCount,
        usageAnalyticsModel: new ActivityItemsUsageAnalyticsModel({
          activityItemsCount: this.state.quizQuestionAnswers.length,
        }),
        usageTimeStamp: new Date().valueOf(),
      },
      () => {
        this.updateCurrentUsageAnalytics()
      },
    )
  }

  onNext = () => {
    if (
      (this.state.quizQuestionAnswers[this.state.questionToDisplay]
        .participantAnswerSaved &&
        !this.state.displaySubmit) ||
      !this.state.quizSettings.shouldRevealExplanationByQuestion
    ) {
      this.setState((prevState) => {
        const questionAnswer = prevState.quizQuestionAnswers

        questionAnswer[this.state.questionToDisplay].participantAnswerData = {
          answers: this.state.selectedOptions,
        }
        return {
          quizQuestionAnswers: questionAnswer,
        }
      })
      if (
        this.state.quizViewMode === QuizParticipantViewState.VIEWING &&
        this.state.displaySubmit
      ) {
        this.setState({
          quizViewMode: QuizParticipantViewState.SUBMITTED,
          displaySubmit: false,
        })
      }
      if (this.state.displaySubmit) {
        if (this.isSubmissionAllowed()) {
          this.setState({
            askPermission: true,
          })
        } else {
          this.setState({
            showValidationError: true,
          })
          //TODO: Show UI to `Please answer all the questions!`
        }
      } else {
        if (
          this.state.questionToDisplay + 1 ===
          this.state.quizQuestionAnswers.length - 1
        ) {
          this.setState({displaySubmit: true})
        }
        this.setState({
          questionToDisplay: this.state.questionToDisplay + 1,
          selectedOptions:
            this.state.quizQuestionAnswers[this.state.questionToDisplay + 1]
              ?.participantAnswerData?.answers || [],
        })
      }
    } else {
      this.setState({
        quizViewMode: QuizParticipantViewState.SUBMITTED,
      })
    }
  }

  onPrevious = () => {
    this.setState((prevState) => {
      const questionAnswer = prevState.quizQuestionAnswers

      questionAnswer[this.state.questionToDisplay].participantAnswerData = {
        answers: this.state.selectedOptions,
      }
      return {
        quizQuestionAnswers: questionAnswer,
        questionToDisplay: this.state.questionToDisplay - 1,
        selectedOptions:
          this.state.quizQuestionAnswers[this.state.questionToDisplay - 1]
            ?.participantAnswerData?.answers || [],
        displaySubmit: false,
      }
    })
  }

  handleOptionClick = (i) => {
    if (this.state.quizViewMode !== QuizParticipantViewState.VIEWING) {
      if (
        this.state.multiSelectOptionEnabled &&
        this.state.quizQuestionAnswers[
          this.state.questionToDisplay
        ].doesQuestionHaveMultipleCorrectAnswers()
      ) {
        if (!this.state.selectedOptions.includes(i)) {
          this.setState((prevState) => {
            const questionAnswer = prevState.quizQuestionAnswers
            const selectedItem = [...this.state.selectedOptions, i]
            questionAnswer[
              this.state.questionToDisplay
            ].participantAnswerData = {
              answers: selectedItem,
            }
            return {
              quizQuestionAnswers: questionAnswer,
              selectedOptions: selectedItem,
            }
          })
        } else {
          const index = this.state.selectedOptions.indexOf(i)
          this.state.selectedOptions.splice(index, 1)
          this.setState((prevState) => {
            const questionAnswer = prevState.quizQuestionAnswers
            const selectedItem = this.state.selectedOptions
            questionAnswer[
              this.state.questionToDisplay
            ].participantAnswerData = {
              answers: selectedItem,
            }
            return {
              quizQuestionAnswers: questionAnswer,
              selectedOptions: selectedItem,
            }
          })
        }
      } else {
        if (!this.state.selectedOptions.includes(i)) {
          this.setState((prevState) => {
            const questionAnswer = prevState.quizQuestionAnswers
            const selectedItem = [i]
            questionAnswer[
              this.state.questionToDisplay
            ].participantAnswerData = {
              answers: selectedItem,
            }
            return {
              quizQuestionAnswers: questionAnswer,
              selectedOptions: selectedItem,
            }
          })
        } else {
          this.setState((prevState) => {
            const questionAnswer = prevState.quizQuestionAnswers
            const selectedItem = []
            questionAnswer[
              this.state.questionToDisplay
            ].participantAnswerData = {
              answers: selectedItem,
            }
            return {
              quizQuestionAnswers: questionAnswer,
              selectedOptions: selectedItem,
            }
          })
        }
      }
    }
  }

  hasUserPassed = () => {
    const threshold = this.state.quizSettings.passCriteria
    const answerSummary = new QuizParticipantAnswerSummary(
      this.state.question,
      this.state.quizSettings,
    )
    if (threshold !== '') {
      const studentScore =
        (answerSummary.getParticipantScore() * 100) /
        answerSummary.getTotalScore()
      return studentScore >= threshold
    }
    return true
  }

  onSubmitQuiz = () => {
    //setSubmittedConfirmationStatus
    const now = new Date().valueOf()
    const timeSpent = now - this.state.answeringTimeStamp
    const isLastAttempt =
      !this.state.quizSettings.isReattemptAllowed(this.state.attemptCount) &&
      !this.hasUserPassed()

    submitQuizResponsesForParticipant(
      this.currentLanguageActivityId,
      this.props.appUser?.userId,
      this.state.quizQuestionAnswers,
      timeSpent,
      isLastAttempt,
    ).then(({evaluatedQuestionAnswers}) => {
      this.setState(
        {
          quizViewMode: QuizParticipantViewState.SUBMITTED,
          quizQuestionAnswers: evaluatedQuestionAnswers,
        },
        () => {
          this.analyticsTracker?.stopTracking()
          this.analyticsTracker = null
        },
      )
    })
  }

  onQuizReattempt = () => {
    this.analyticsTracker?.stopTracking()
    this.analyticsTracker = null
    reAttemptQuizForParticipant(this.currentLanguageActivityId)
      .then((data) => {
        this.setState({loadingState: LoadingState.FETCHING}, () =>
          this.fetchQuizParticipantData(this.currentLanguageActivityId, true),
        )
      })
      .catch((error) => {
        console.log('The error is ', error)
      })
  }

  getTotalAnsweredCount = () => {
    let totalAnsweredQuestions = 0
    this.state.quizQuestionAnswers.forEach((question) => {
      if (question.isAnswered() && question.isAnswerSaved())
        totalAnsweredQuestions++
    })
    return totalAnsweredQuestions
  }

  onSubmitQuestion = () => {
    const questionAnswer = this.state.quizQuestionAnswers
    questionAnswer[this.state.questionToDisplay].participantAnswerData = {
      answers: this.state.selectedOptions,
    }
    if (
      this.state.quizQuestionAnswers[this.state.questionToDisplay].isAnswered()
    ) {
      const totalAnsweredQuestionsCount = this.getTotalAnsweredCount()
      if (
        totalAnsweredQuestionsCount ===
        this.state.quizQuestionAnswers.length - 1
      ) {
        this.submitParticipantResponses(this.state.quizQuestionAnswers)
      } else {
        this.saveParticipantQuestionResponse(this.state.quizQuestionAnswers)
      }
      this.setState((prevState) => {
        prevState.quizQuestionAnswers[
          this.state.questionToDisplay
        ].participantAnswerSaved = true
        return {
          quizQuestionAnswers: prevState.quizQuestionAnswers,
          askPermission: false,
        }
      })
    }
  }

  submitParticipantResponses = (quizQuestionAnswers) => {
    const now = new Date().valueOf()
    let timeSpent = now - this.state.answeringTimeStamp
    let allowedDuration = 0
    if (this.state.quizSettings.getAllowedDuration() > 0) {
      allowedDuration = this.state.quizSettings.getAllowedDuration() * 60 * 1000
    }
    if (
      timeSpent > allowedDuration &&
      this.state.quizSettings.getAllowedDuration() > 0
    ) {
      timeSpent = allowedDuration
    }
    const isLastAttempt =
      !this.state.quizSettings.isReattemptAllowed(this.state.attemptCount) &&
      !this.hasUserPassed()

    submitQuizResponsesForParticipant(
      this.currentLanguageActivityId,
      this.props.appUser?.userId,
      quizQuestionAnswers,
      timeSpent,
      isLastAttempt,
    ).then(({evaluatedQuestionAnswers, assignedRemedialModules}) => {
      //Setting to Answering Mode after submitting during shouldRevealExplanation as the user wants to click on Report button
      this.setState(
        {
          quizViewMode: this.state.quizSettings
            .shouldRevealExplanationByQuestion
            ? QuizParticipantViewState.ANSWERING
            : QuizParticipantViewState.SUBMITTED,
          quizQuestionAnswers: evaluatedQuestionAnswers,
          currentQuestionNumber: 0,
        },
        () => {
          this.analyticsTracker.stopTracking()
        },
      )
    })
  }

  saveParticipantQuestionResponse = (quizQuestionAnswers) => {
    const now = new Date().valueOf()
    let timeSpent = now - this.state.answeringTimeStamp
    let allowedDuration = 0
    if (this.state.quizSettings.getAllowedDuration() > 0) {
      allowedDuration = this.state.quizSettings.getAllowedDuration() * 60 * 1000
    }
    if (
      timeSpent > allowedDuration &&
      this.state.quizSettings.getAllowedDuration() > 0
    ) {
      timeSpent = allowedDuration
    }
    const userId = this.props.appUser.userId
    saveQuizResponsesForParticipant(
      this.currentLanguageActivityId,
      userId,
      quizQuestionAnswers,
      timeSpent,
      (evaluatedQuestionsAnswer) => {
        this.setState({
          quizQuestionAnswers: evaluatedQuestionsAnswer,
        })
      },
    )
  }

  assessParticipantResponses = () => {
    if (this.isSubmissionAllowed()) {
      this.setState({isSavingQuiz: true}, () => {
        this.onSubmitQuiz()
      })
    } else {
    }
  }

  isSubmissionAllowed() {
    const hasAnyUnAnsweredQuestion = this.state.quizQuestionAnswers.some(
      (questionAnswer, index) => {
        return !questionAnswer.isAnswered()
      },
    )
    if (this.state.quizSettings.allowPartialSubmission) {
      //Not mandatory
      return true
    } else if (
      !this.state.quizSettings.allowPartialSubmission &&
      !hasAnyUnAnsweredQuestion
    ) {
      //Answered All Questions and Mandatory
      return true
    } else {
      //User didn't answer answer all questions
      return false
    }
  }

  handleValidationOK() {
    this.setState({
      showValidationError: false,
    })
  }

  onTimeUpNotification = () => {
    this.updateCurrentUsageAnalytics()
    this.setState(
      {quizViewMode: QuizParticipantViewState.TIMEUP_NOTIFICATION},
      () => {
        this.onSubmitQuiz()
      },
    )
  }

  onReviewClick() {
    this.setState((prevState) => {
      const selectedOptions =
        prevState.quizQuestionAnswers[0]?.participantAnswerData?.answers || []
      return {
        quizQuestionAnswers: prevState.quizQuestionAnswers,
        quizViewMode: QuizParticipantViewState.VIEWING,
        selectedOptions: selectedOptions,
        questionToDisplay: 0,
        displaySubmit: false,
      }
    })
  }

  handleCancelAskPermission = () => {
    this.setState({
      askPermission: false,
    })
  }

  render() {
    if (this.state.loadingState === LoadingState.FETCHING) {
      return (
        <div className={'flexCentered background fullHeight'}>Fetching...</div>
      )
    }

    if (this.state.loadingState === LoadingState.EMPTY) {
      return (
        <div className={'flexCentered background fullHeight'}>
          Something went wrong
        </div>
      )
    }

    const allowedDuration = this.state.quizSettings.getAllowedDuration()
    const isTimerApplicable =
      allowedDuration !== -1 /*&& !this.props.isRevealing*/

    return (
      <>
        {this.state.quizViewMode === QuizParticipantViewState.NOT_STARTED && (
          <QuizInstruction
            multiSelectOptionEnabled={this.state.multiSelectOptionEnabled}
            quizSettings={this.state.quizSettings}
            quizQuestionAnswers={this.state.quizQuestionAnswers}
            activityId={this.props.activityId}
            onUpdateQuizParticipantStatus={this.onUpdateQuizParticipantStatus}
          />
        )}
        {(this.state.quizViewMode === QuizParticipantViewState.ANSWERING ||
          this.state.quizViewMode === QuizParticipantViewState.VIEWING) && (
          <>
            <ActivityHeader activityName={this.props.name} />
            {isTimerApplicable &&
              this.state.quizViewMode ===
                QuizParticipantViewState.ANSWERING && (
                <QuizCountDownTimer
                  allowedDuration={allowedDuration}
                  onTimeUpNotification={this.onTimeUpNotification}
                />
              )}
            <DisplayQuestions
              questionToDisplay={
                this.state.quizQuestionAnswers[this.state.questionToDisplay]
              }
              showValidationError={this.state.showValidationError}
              quizSettings={this.state.quizSettings}
              quizQuestionAnswers={this.state.quizQuestionAnswers}
              isRevealed={this.state.isRevealed}
              quizViewMode={this.state.quizViewMode}
              multiSelectOptionEnabled={this.state.multiSelectOptionEnabled}
              selectedOptions={this.state.selectedOptions}
              askPermission={this.state.askPermission}
              displaySubmit={this.state.displaySubmit}
              handleOptionClick={this.handleOptionClick}
              onNext={this.onNext}
              onPrevious={this.onPrevious}
              quizSettings={this.state.quizSettings}
              onSubmitQuiz={this.onSubmitQuiz}
              onInstantSubmit={this.onSubmitQuestion}
              handleValidationOK={this.handleValidationOK}
              handleCancelAskPermission={this.handleCancelAskPermission}
            />
          </>
        )}
        {this.state.quizViewMode === QuizParticipantViewState.SUBMITTED && (
          <ResultView
            attemptCount={this.state.attemptCount}
            quizSettings={this.state.quizSettings}
            hasUserPassed={this.hasUserPassed}
            quizQuestionAnswers={this.state.quizQuestionAnswers}
            activityId={this.props.activityId}
            nextActivity={this.props.nextActivity}
            handleOnNextClick={this.props.handleOnNextClick}
            onReviewClick={this.onReviewClick}
            onQuizReattempt={this.onQuizReattempt}
          />
        )}
      </>
    )
  }
}

export default QuizActivityView
