import '../../../styles/SubmitPermission.scss'

const SubmitPermission = ({onSubmitQuiz, handleCancelAskPermission}) => {
  return (
    <div className="permission-div">
      <div className="permission-box">
        <div className="permssion-content">
          <div className="sure-text">Are you sure?</div>
          <div className="final-submission">This is your final submission.</div>
          <div className="permission-buttons">
            <div
              className="permission-cancel"
              onClick={handleCancelAskPermission}>
              <div className="permission-cancel-text">Cancel</div>
            </div>
            <div className="permission-submit" onClick={onSubmitQuiz}>
              <div className="permission-submit-text">Submit</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubmitPermission
