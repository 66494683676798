import {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useHistory, useParams} from 'react-router-dom'
import {getAuthTypeInfoFromUserRef} from '../axios'
import {AppButton} from '../core'
import {LoadingState} from '../enums'

const AuthenticationView = ({onAuthenticate}) => {
  let {authToken} = useParams()
  authToken = decodeURIComponent(authToken)
  // const authToken = 'HuIEZ+bvNG1pu2zTakxg7Vv7WdlFFTuL'
  const history = useHistory()
  const [loadingState, setLoadingState] = useState(LoadingState.FETCHING)
  const [retryKey, setRetryKey] = useState(new Date().valueOf())

  useEffect(() => {
    if (authToken && retryKey) {
      getAuthTypeInfoFromUserRef(authToken)
        .then((response) => {
          console.log('The response is ', response.data)
          setLoadingState(LoadingState.AVAILABLE)
          onAuthenticate(response.data, history)
        })
        .catch((error) => {
          //TODO: Show alert here
          setLoadingState(LoadingState.EMPTY)
          console.log('The response is ', error)
        })
    }
  }, [authToken, onAuthenticate, retryKey, history])

  if (!authToken) {
    return (
      <div className={'flexCentered background fullHeight'}>
        <span>Invalid</span>
      </div>
    )
  }

  if (loadingState === LoadingState.FETCHING) {
    return (
      <div className={'flexCentered background fullHeight'}>
        <span>Fetching...</span>
      </div>
    )
  }

  if (loadingState === LoadingState.EMPTY) {
    return (
      <div className={'flexCentered background fullHeight'}>
        <AppButton
          className={'secondary'}
          onClick={() => {
            setRetryKey(new Date().valueOf())
            setLoadingState(LoadingState.FETCHING)
          }}>
          Something went wrong. Try again
        </AppButton>
      </div>
    )
  }

  return (
    <div className={'flexCentered background fullHeight'}>
      <AppButton
        className={'primary'}
        onClick={() => {
          history.push('/modules')
        }}>
        Successfully Authenticated. Go to Module View
      </AppButton>
    </div>
  )
}

AuthenticationView.propTypes = {
  onAuthenticate: PropTypes.func,
}

AuthenticationView.defaultProps = {
  onAuthenticate: () => {},
}

export default AuthenticationView
