class ActivityItemsUsageAnalyticsModel {
  constructor(config = {}) {
    this.activityItemsCount = config.activityItemsCount || 0
    this.previousUsageAnalytics =
      this.curateBadDataInPreviousUsageAnalytics(
        config.previousUsageAnalytics,
      ) || [] // Array of equal-sized int arrays
    this.aggregatedPreviousUsageAnalytics = this.aggregateAnalytics(
      this.previousUsageAnalytics,
    )
    this.currentUsageAnalytics =
      config.currentUsageAnalytics || this.getBaseUsageAnalyticsObject() // int array
  }

  aggregateAnalytics(analyticsCollection) {
    const aggregatedAnalytics = this.getBaseUsageAnalyticsObject()
    analyticsCollection.forEach((analyticsItem) => {
      analyticsItem.forEach((analyticsDataPoint, i) => {
        aggregatedAnalytics[i] += analyticsDataPoint
      })
    })

    return aggregatedAnalytics
  }

  curateBadDataInPreviousUsageAnalytics(prevUsageAnalytics) {
    if (prevUsageAnalytics instanceof Array) {
      return prevUsageAnalytics.filter((analytics) => {
        const canInclude =
          analytics instanceof Array &&
          analytics.length === this.activityItemsCount &&
          analytics.every((item) => {
            return typeof item === 'number'
          })
        return canInclude
      })
    }
    return []
  }

  getBaseUsageAnalyticsObject() {
    const a = []
    for (let i = 0; i < this.activityItemsCount; i++) {
      a[i] = 0
    }
    return a
  }

  getCurrentUsageAnalytics() {
    return this.currentUsageAnalytics
  }

  updateCurrentUsageAnalytics(activityItemNumber, usageInMilliSeconds) {
    this.currentUsageAnalytics[activityItemNumber - 1] += usageInMilliSeconds
  }

  isUsageAlreadyComplete() {
    return this.getUsageCompletionPercentage(false) === 100
  }

  isUsageComplete() {
    return this.getUsageCompletionPercentage(true) === 100
  }

  hasUsageBegun() {
    return this.getUsageCompletionPercentage(true) === 0
  }

  getCurrentUsageIndex = () => {
    const index = this.aggregatedPreviousUsageAnalytics.findIndex(
      (item) => item === 0,
    )
    return index === -1 ? 0 : index
  }

  isActivityItemComplete(itemNumber, overallUsage) {
    const i = itemNumber - 1
    return overallUsage === true
      ? this.aggregatedPreviousUsageAnalytics[i] +
          this.currentUsageAnalytics[i] >
          0
      : this.aggregatedPreviousUsageAnalytics[i] > 0
  }

  getUsageCompletionPercentage(isOverallUsage) {
    let completedPagesCount = 0,
      timeSpendOnPage
    for (let i = 0; i < this.activityItemsCount; i++) {
      if (isOverallUsage === true) {
        timeSpendOnPage =
          this.aggregatedPreviousUsageAnalytics[i] +
          this.currentUsageAnalytics[i]
      } else {
        timeSpendOnPage = this.aggregatedPreviousUsageAnalytics[i]
      }
      if (timeSpendOnPage > 0) {
        completedPagesCount++
      }
    }
    return Math.ceil((100 * completedPagesCount) / this.activityItemsCount)
  }
}

export default ActivityItemsUsageAnalyticsModel
