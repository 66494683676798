import '../../../styles/QuizInstruction.scss'
import {useHistory} from 'react-router-dom'
import {CancelIcon, QuizIcon, QuizTimeIcon} from '../../../icons'
import {updateQuizParticipantStatus} from '../../axios'

const QuizInstruction = ({
  multiSelectOptionEnabled,
  quizSettings,
  quizQuestionAnswers,
  activityId,
  onUpdateQuizParticipantStatus,
}) => {
  const history = useHistory()

  const handleStartNow = async () => {
    const response = await updateQuizParticipantStatus(activityId)
    onUpdateQuizParticipantStatus(response)
  }

  const renderQuizTime = () => {
    let timeAllowed = quizSettings.allowedDuration
    if (timeAllowed === -1) {
      timeAllowed = 'Unlimited'
    } else {
      timeAllowed = `${timeAllowed} minutes`
    }
    return timeAllowed
  }

  const handleCancelClick = () => {
    history.goBack()
  }

  return (
    <div className="modal-container">
      <div className="modal">
        <div className="cancel-icon" onClick={handleCancelClick}>
          <CancelIcon />
        </div>
        <div className="quiz-instruction-title">Quiz Instruction</div>
        <div className="instruction-box">
          <div className="instruction">
            <div className="instruction-icon">
              <QuizIcon />
            </div>
            <div className="instruction-text">{`${quizQuestionAnswers.length} Questions`}</div>
          </div>
          <div className="instruction">
            <div className="instruction-icon">
              <QuizTimeIcon />
            </div>
            <div className="instruction-text">{renderQuizTime()}</div>
          </div>
          {multiSelectOptionEnabled ? (
            <div className="instruction">
              <div className="instruction-icon">
                <QuizIcon />
              </div>
              <div className="instruction-text">
                There might be more than one answer to a question
              </div>
            </div>
          ) : (
            ''
          )}
          {quizSettings.shouldRevealExplanationByQuestion ? (
            <div className="instruction">
              <div className="instruction-icon">
                <QuizIcon />
              </div>
              <div className="instruction-text">
                This is a self-revealing quiz
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="start-button" onClick={handleStartNow}>
          Start Now
        </div>
      </div>
    </div>
  )
}

export default QuizInstruction
