/* eslint-disable eqeqeq */
import {ApplicationSettingType, ClientSegment, UserContext} from '../../enums/'

let _userSearchWidgetConfig = null

class User {
  _walletConfig = null
  constructor(config = {}) {
    this.firstName = config.firstName || ''
    this.lastName = config.lastName || ''
    this.userName = config.userName || ''
    this.password = config.password || ''
    this.userId = config.userId || 0
    this.email = config.email || ''
    this.mobileNumber = config.mobileNumber || ''
    this.roleName = config.roleName || ''
    this.defaultMetaValue = config.defaultMetaValue
    this.userImage = config.userImage || ''
    this.profilePic = config.profilePic || ''
    this.userStatus = config.userStatus || 1
    this.allowedContexts = config.allowedContexts || [] // array of allowed user contexts per S.UserContexts
    this.productPermissions = config.productPermissions || []
    this.applicationSettings = config.applicationSettings || []
    this.metaInformation = config.metaInformation // Organizational MetaData - Object in JSON format
    this.organizationalMetaDataFields = config.organizationalMetaDataFields // Organizational MetaData - List of fields with values
    this.clientSegment = config.clientSegment || ClientSegment.CORPORATE
    this.dbPointer = config.dbPointer || ''
    this.userProfileUrlInfo = config.userProfileUrlInfo || {}
    this.isAssignedSkills = config.isAssignedSkills
    this.pendingSurveys = config.pendingSurveys || []
    this.walletBalance = config.walletBalance || 0
    this.organizationName = config.organizationName || ''

    this.contexts = config.contexts //For Disprz App

    // if (config.preference) {
    //     this.baseUserContext = this.determineUserContext(config.preference);
    // }
  }

  getSettingValue(settingIdToGet) {
    const setting = this.applicationSettings.find((setting) => {
      return setting.settingId === settingIdToGet
    })
    return setting ? setting.settingValue : ''
  }

  getLeaderboardConfiguration() {
    const leaderBoardConfig = this.getSettingValue(
      ApplicationSettingType.LEADERBOARD_CONFIGURATION,
    )
    return leaderBoardConfig ? JSON.parse(leaderBoardConfig) : ''
  }

  allowOffline() {
    const settingJson = this.getSettingValue(
      ApplicationSettingType.CLIENT_ENTITLEMENTS,
    )

    if (settingJson) {
      const entitlements = JSON.parse(settingJson)
      if (entitlements.appEntitlements) {
        return entitlements.appEntitlements.allowOffline
      }
    }

    return false
  }

  determineUserContext(preference) {
    /// sub-class will implement this method
  }

  getPermittedUserContexts() {
    /// sub-class will implement this method
  }

  getBaseUserContext() {
    return this.baseUserContext
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`
  }

  getThumbnailUrl() {
    // return SimpleFileHelper.getThumbnailUrl(this.userImage)
  }

  getUserProfileImage() {
    return this.userImage
  }

  getUserId() {
    return this.userId
  }

  getModuleCertificateConfiguration() {
    const certificationConfiguration = this.getSettingValue(
      ApplicationSettingType.MODULE_CERTIFICATE_PREFERENCES,
    )
    return certificationConfiguration
      ? JSON.parse(certificationConfiguration)
      : ''
  }

  setAllowedContexts(contextArray) {
    this.allowedContexts = [].concat(contextArray)
  }

  isK12Segment() {
    return this.clientSegment === ClientSegment.K12
  }

  canAddPrograms() {
    throw 'Client-segment Sub-class has to implement canAddPrograms()'
  }

  canAddSubjects() {
    throw 'Client-segment Sub-class has to implement canAddSubjects()'
  }

  canEditActivity(activityObject) {
    throw 'Client-segment Sub-class has to implement canEditActivity()'
  }

  canEditActivitySettings() {
    throw 'Client-segment Sub-class has to implement canEditActivitySettings()'
  }

  // Sub-class may implement this method to return a custom CSS
  getClientSegmentCls() {
    return ''
  }

  setFirstName(firstName) {
    this.firstName = firstName
  }

  setLastName(lastName) {
    this.lastName = lastName
  }

  setPassword(password) {
    this.password = password
  }

  setEmail(email) {
    this.email = email
  }

  setUserImage(userImage) {
    this.userImage = userImage
  }

  setOrganisationMetaFields(organizationalMetaDataFields) {
    this.organizationalMetaDataFields = organizationalMetaDataFields
  }

  setUserApplicationSettings(applicationSettings) {
    this.applicationSettings = applicationSettings
  }

  isSelfServiceEnabled() {
    const selfServiceEnabled = this.getSettingValue(
      ApplicationSettingType.SELF_SERVICE_ENABLED,
    )
    return selfServiceEnabled
      ? selfServiceEnabled.toLowerCase() === 'true'
      : false
  }

  static setUserSearchWidgetConfig(configString) {
    _userSearchWidgetConfig = configString ? JSON.parse(configString) : []
  }

  static getUserSearchWidgetConfig() {
    return _userSearchWidgetConfig
  }

  static isLearner(userContext) {
    return (
      userContext === UserContext.LEARNER || userContext === UserContext.STUDENT
    )
  }

  static isTeacher(userContext) {
    return (
      userContext === UserContext.SKILLMASTER ||
      userContext === UserContext.TEACHER
    )
  }

  static isAdministrator(userContext) {
    return (
      userContext === UserContext.ADMINISTRATOR ||
      userContext === UserContext.K12ADMINISTRATOR
    )
  }

  disablePasswordChange() {
    const passwordSettingValue = this.getSettingValue(
      ApplicationSettingType.DISALLOW_STUDENTS_CHANGING_PASSWORD,
    )
    return passwordSettingValue
      ? passwordSettingValue.toLowerCase() === 'true'
      : false
  }

  disableProfileEdit() {
    const passwordSettingValue = this.getSettingValue(
      ApplicationSettingType.DISALLOW_STUDENTS_CHANGING_PROFILE,
    )
    return passwordSettingValue
      ? passwordSettingValue.toLowerCase() === 'true'
      : false
  }

  isPasswordSalted() {
    const securitySettings = this.getSettingValue(
      ApplicationSettingType.SECURITY_SETTINGS,
    )
    return !!JSON.parse(securitySettings)?.isPasswordSalted
  }

  isMe(userName) {
    return this.userName.toLowerCase() === userName.toLowerCase()
  }

  getSPOCURL(skillId) {
    /*const SPOCConfigJson = this.getSettingValue(
      ApplicationSettingType.SPOC_CONFIG,
    )

    if (SPOCConfigJson) {
      const parsedJSON = JSON.parse(SPOCConfigJson)
      if (parsedJSON.freeAndPaidSkillsInfo) {
        const skillDetails = parsedJSON.freeAndPaidSkillsInfo.find(
          (s) => s.freeSkillId === skillId,
        )
        if (skillDetails) {
          const user = AppHelper.getAppUser()
          return skillDetails.paidSkillSpocUrl.replace('{0}', user.email)
        }
      }
    }
    return ''*/
  }

  isSPOCClient = () => {
    const SPOCConfigJson = this.getSettingValue(
      ApplicationSettingType.SPOC_CONFIG,
    )

    return SPOCConfigJson != ''
  }

  isNLPSearchEnabled() {
    const nlpSettings = this.getSettingValue(
      ApplicationSettingType.NLP_SEARCH_CONFIGURATION,
    )
    return !!nlpSettings
  }

  isLoggingEnabled() {
    return this.dbPointer.toLowerCase() === 'myacademy'
  }

  getPasswordComplexityRegex() {
    const passwordComplexityRegex = this.getSettingValue(
      ApplicationSettingType.PASSWORD_COMPLEXITY,
    )
    return passwordComplexityRegex ? passwordComplexityRegex : ''
  }

  get getLearningWalletConfig() {
    if (!this._walletConfig) {
      const settingJSON = this.getSettingValue(
        ApplicationSettingType.LEARNING_WALLET_CONFIGURATION,
      )
      this._walletConfig = settingJSON ? JSON.parse(settingJSON) : {}
    }
    return this._walletConfig
  }

  updateWalletBalance = (balance) => {
    this.walletBalance = balance
  }
}

export default User
