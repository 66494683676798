// The Permissions ENUM mirrors values in the Constants_Role_Permissions table. MANIPULATE WITH CARE!
const ProductPermissions = {
  // Corporate
  ADDING_SKILLS: 1,
  APP_ADMINISTRATION: 5,
  ADDING_PROGRAMS: 18,
  PERFORM_SYSTEM_ADMINISTRATION: 100,
  ADDING_K12_PROGRAMS: 101,
  ADDING_COURSES_AND_BATCHES: 102,
}

export default ProductPermissions
