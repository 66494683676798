import QuizQuestion from './QuizQuestion'

class QuizQuestionAnswer extends QuizQuestion {
  constructor(config = {}) {
    super(
      config.questionObject,
      config.difficultyLevel,
      config.sectionName,
      config.questionExplanationText,
    )
    this.isCorrect = config.isCorrect
    this.questionNo = config.questionNo
    /*New One*/
    this.participantAnswerData = config.participantAnswerData || {
      answers: [],
    }
    this.participantAnswerSaved = this.participantAnswerData.answers.length > 0
  }

  getParticipantAnswer() {
    return this.participantAnswer
  }

  getParticipantAnswerData() {
    return this.participantAnswerData
  }

  getParticipantAnswers() {
    return this.participantAnswerData.answers
  }

  setParticipantAnswer(answer) {
    this.participantAnswer = answer
  }

  setParticipantAnswers(answers) {
    this.participantAnswerData.answers = answers
  }

  isRight(evaluateNow) {
    return evaluateNow
      ? this.isCorrectAnswer(this.participantAnswerData.answers)
      : this.isCorrect
  }

  isAnswered() {
    return this.participantAnswerData.answers.length > 0
  }

  isAnswerSaved() {
    return this.participantAnswerSaved
  }

  checkIfParticipantAnswered(optionNumber) {
    return this.participantAnswerData.answers.includes(optionNumber)
  }

  setCorrect(isCorrect) {
    return (this.isCorrect = isCorrect)
  }

  getQuestionNo() {
    return this.questionNo
  }
}

export default QuizQuestionAnswer
