class QuizQuestionContentItem {
  constructor(config = {}) {
    this.itemText = config.itemText || ''
    this.items = config.items || []
  }

  getConstructor() {
    return 'QuizQuestionContentItem'
  }
}

export default QuizQuestionContentItem
