import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {fetchInteractiveActivity} from '../axios'
import {ActivityType, LoadingState} from '../enums'
import ActivityFooter from './ActivityFooter'
import ActivityHeader from './ActivityHeader'
import AnalyticsTracker from './AnalyticsTracker'
import ActivityInteractiveUsageAnalyticsModel from './model/ActivityInteractiveUsageAnalyticsModel'

class InteractiveActivityView extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingState: LoadingState.FETCHING,
      interactiveActivityDetails: {},
      launchUrl: '',
      isCompleted: false,
      activeLanguage: 'en',
    }
    this.analyticsTracker = null
    this.usageAnalyticsModel = null
    this.onTriggerWhenMovingAwayFromActivity = () => {}
    this.pubnubInstance = null
    this.channelName = this.getChannelName()
    this.isOpenInAppBrowser = false
    this.isScrom = false
    this.isAnimatedPpt = false
    this.currentLanguageActivityId = this.props.activityId
  }

  getChannelName = () => {
    return (
      `scrom:disprz:u-${this.props.appUser.userId}:a-${this.currentLanguageActivityId}:` +
      new Date().valueOf()
    )
  }

  componentDidMount() {
    this.getInteractiveActivityDetails()
    window.addEventListener('message', this.onReceiveScormStatements, false)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onReceiveScormStatements, false)
    this.analyticsTracker?.stopTracking()
    this.analyticsTracker = null
  }

  updateCurrentUsageAnalytics = () => {
    return new Promise((resolve, reject) => {
      const usageAnalyticsModel = this.usageAnalyticsModel
      const isCompleted =
        !this.state.interactiveActivityDetails.isComplete &&
        usageAnalyticsModel.isUsageComplete()
      if (isCompleted) {
        this.setState({
          isCompleted: true,
        })
        /*this.props.onUpdateActivityConsumed(
          this.props.activityId,
          this.currentLanguageActivityId,
          this.props.displayedActivityId,
        )*/
      }
      resolve({
        metaData: usageAnalyticsModel.getCurrentUsageAnalytics(),
        markComplete: isCompleted,
        isXAPI: this.state.interactiveActivityDetails.isXAPI,
        lrsConfig: this.state.interactiveActivityDetails.lrsConfig,
      })
    })
  }

  getInteractiveActivityDetails = (activityId = this.props.activityId) => {
    const user = this.props.appUser
    fetchInteractiveActivity(activityId)
      .then((interactiveActivityDetails) => {
        this.usageAnalyticsModel = new ActivityInteractiveUsageAnalyticsModel({
          userId: user.userName,
          userName: `${user.firstName} ${user.lastName}`,
          isScrom: interactiveActivityDetails.isScrom,
          isAnimatedPpt: interactiveActivityDetails.isAnimatedPpt,
          previousUsageAnalytics: interactiveActivityDetails.usageAnalytics,
        })
        const {convertedPagesInfo} = interactiveActivityDetails
        this.setState({
          interactiveActivityDetails: interactiveActivityDetails,
          launchUrl: convertedPagesInfo[0].pageUrl,
          loadingState: LoadingState.FETCHED_AND_AVAILABLE,
          isCompleted: interactiveActivityDetails.isComplete,
        })
        this.isScrom = this.state.interactiveActivityDetails?.isScrom
        this.isAnimatedPpt = this.state.interactiveActivityDetails?.isAnimatedPpt
        this.analyticsTracker = new AnalyticsTracker({
          updateCurrentUsageAnalytics: this.updateCurrentUsageAnalytics,
          analyticsId: interactiveActivityDetails.analyticsId,
          activityType: ActivityType.INTERACTIVE,
          activityId: activityId,
        })
        this.analyticsTracker.setControlTimeout()
      })
      .catch((error) => {
        console.log('The error is ', error)
      })
  }

  onReceiveScormStatements = (data) => {
    try {
      data = typeof data.data === 'string' ? JSON.parse(data.data) : data.data
      console.log(data)
      if (data.type && data.type === 'disprz_scromInit') {
        const studentData = this.usageAnalyticsModel.getCurrentUsageAnalytics()
        const postData = JSON.stringify({
          type: 'disprz_studentData',
          data: studentData,
          suppressLaunchScreen: false,
        })
        //this.publishMessage(postData)
        document
          .getElementById('scromFrame')
          .contentWindow.postMessage(postData, '*')
        //this.refs.scrom_frame.contentWindow.postMessage(postData, "*");
      } else if (data.type && data.type === 'disprz_scromClose') {
        console.log('disprz_scromClose triggered')
        if (this.onTriggerWhenMovingAwayFromActivity === (() => {})) {
          // this.onHandleActivityBack()
        } else {
          this.onTriggerWhenMovingAwayFromActivity()
        }
      } else {
        const usageAnalyticsModel = this.usageAnalyticsModel
        usageAnalyticsModel.updatecurrentUsageAnalytics(data)
        this.usageAnalyticsModel = usageAnalyticsModel
        if (
          this.analyticsTracker &&
          !this.state.interactiveActivityDetails.isComplete &&
          usageAnalyticsModel.isUsageComplete()
        ) {
          this.analyticsTracker.stopTracking()
          this.analyticsTracker = null
        }
      }
    } catch (e) {}
  }

  publishMessage = (message) => {
    if (this.pubnubInstance) {
      const publishConfig = {
        channel: this.channelName,
        message,
      }
      this.pubnubInstance.publish(publishConfig, function (status, response) {
        console.log(status, response)
      })
    } else {
      this.webView.postMessage(message)
    }
  }

  render() {
    if (this.state.loadingState === LoadingState.FETCHING) {
      return <div>Fetching...</div>
    }

    return (
      <div className={'interactive-container'} style={{flex: 1}}>
        <ActivityHeader
          activityName={this.state.interactiveActivityDetails.activityName}
        />
        <div className={'iframe-wrapper'}>
          <iframe
            title={'InteractiveActivityView'}
            id="scromFrame"
            src={this.state.launchUrl}
            allow="autoplay"
            frameBorder="0"
            allowFullScreen
          />
        </div>
        <ActivityFooter
          nextActivity={this.props.nextActivity}
          handleOnNextClick={this.props.handleOnNextClick}
        />
      </div>
    )
  }
}

InteractiveActivityView.propTypes = {}

export default InteractiveActivityView
