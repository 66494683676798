import QuizQuestionContentItem from '../../JSCore/activity/quiz/QuizQuestionContentItem'
import Base64 from '../../helpers/Base64'

class QuizQuestion {
  constructor(
    questionObject,
    difficultyLevel,
    sectionName,
    questionExplanationText,
    questionNo,
  ) {
    this.questionObject = questionObject
    this.difficultyLevel =
      difficultyLevel || QuizQuestion.DifficultyLevels.MEDIUM
    this.isQuestionValid = true
    this.sectionName = sectionName || 'Section 1'
    this.questionExplanationText = questionExplanationText || '{}'
    this.questionNo = questionNo

    // Assign Ids
    this.questionId = `q-${new Date().valueOf()}`
    this.questionObject.options.forEach(function (option, i) {
      option.tr_optionId = `${this.questionId}-o${
        i + 1
      }-${new Date().valueOf()}`
    }, this)
  }

  getQuestionId() {
    return this.questionId
  }

  getOptionId(optionNumber) {
    return this.questionObject.options[optionNumber].tr_optionId
  }

  getQuestionText() {
    return this.questionObject.question.itemText
  }

  setQuestionText(newText) {
    this.questionObject.question.itemText = newText
  }

  static curateAssetsInRichText(richText) {
    return richText.replace(/<img([\w\W]+?)>/g, (img) => {
      return img.replace(
        /src\s*=\s*['"]([A-Za-z0-9_.\/\-]+)['"]/,
        (match, src) => {
          //This regex will match only relative path in the src(without http/https)
          const assetUrl = '/api/account/getFile/' + Base64.btoa(src)
          return 'src="' + assetUrl + '"'
        },
      )
    })
  }

  getOptionCount() {
    return this.questionObject.options.length
  }

  getOptionText(optionNumber) {
    return this.questionObject.options[optionNumber].itemText
  }

  setOptionText(optionNumber, newText) {
    this.questionObject.options[optionNumber].itemText = newText
    this.questionObject.options[optionNumber].tr_isNew = false
  }

  setCorrectAnswer(optionNumber) {
    this.questionObject.correctAnswer = optionNumber
  }

  isCorrectAnswer(optionNumbers) {
    if (this.questionObject.z_correctAnswers.length !== optionNumbers.length) {
      return false
    } else {
      return this.questionObject.z_correctAnswers.every((item) =>
        optionNumbers.includes(item),
      )
    }
  }

  isOptionNew(optionNumber) {
    return this.questionObject.options[optionNumber].tr_isNew
  }

  getDifficultyLevel(level) {
    return this.difficultyLevel
  }

  setDifficultyLevel(level) {
    this.difficultyLevel = level
  }

  getQuestionExplanationObject() {
    if (this.questionExplanationText !== '') {
      return this.questionExplanationText
    }
    return ''
  }

  //// Utility Methods
  addOption(optionText) {
    const newOption = new QuizQuestionContentItem({
      itemText: optionText,
    })
    const optionsCount = this.questionObject.options.push(newOption)
    newOption.tr_optionId = '{0}-o{1}-{2}'.format(
      this.questionId,
      optionsCount,
      new Date().valueOf(),
    )
    newOption.tr_isNew = true
  }

  removeOption(optionNumber) {
    this.questionObject.options.splice(optionNumber, 1)
  }

  validate() {
    const msgs = []
    if (!this.getQuestionText()) {
      msgs.push('Empty')
    }
    for (let o = 1; o <= this.getOptionCount(); o++) {
      const optionText = this.getOptionText(o)
      if (!optionText || optionText.length === 0) {
        //msgs.push("Options".format(o));
      }
    }

    this.isQuestionValid = msgs.length === 0

    return msgs
  }

  isValid(revalidate) {
    if (revalidate === true) {
      this.validate()
    }

    return this.isQuestionValid
  }

  getSectionName() {
    return this.sectionName()
  }

  setCorrectAnswers(optionNumbers) {
    this.questionObject.z_correctAnswers = optionNumbers
    if (optionNumbers.length > 0) {
      this.questionObject.correctAnswer = optionNumbers[0]
    } else {
      this.questionObject.correctAnswer = 0
    }
  }

  doesQuestionHaveMultipleCorrectAnswers() {
    return this.questionObject.getCorrectAnswers().length > 1
  }

  getCorrectAnswers() {
    return this.questionObject.z_correctAnswers
  }

  resetAnswer() {
    this.participantAnswerData = {answers: []}
    this.participantAnswerSaved = false
    this.isCorrect = false
  }
}

//// ENUMs
QuizQuestion.DifficultyLevels = {
  EASY: 1,
  MEDIUM: 2,
  DIFFICULT: 3,
}

QuizQuestion.QuestionTypes = {
  MULTIPLECHOICE: 1,
}

export default QuizQuestion
