import QuizQuestion from '../../../model/quiz/QuizQuestion'

class QuizParticipantAnswerSummary {
  constructor(questions, quizSettings) {
    this.questions = questions || []
    this.quizSettings = quizSettings
    this.breakdown = {
      rightCount: 0,
      wrongCount: 0,
      unAnsweredCount: 0,
      score: 0,
      RightCounts: [0, 0, 0],
      WrongCounts: [0, 0, 0],
      UnAnsweredQuestionsInLevel: [0, 0, 0],
      QuestionsInLevel: [0, 0, 0],
    }
    this.init()
  }

  init() {
    for (let i = 0; i < this.questions.length; i++) {
      const level = this.questions[i].getDifficultyLevel(),
        levelIndex = level - 1 // ASSUMPTION: levelIndex = LevelId - 1
      this.breakdown.QuestionsInLevel[levelIndex]++
      if (this.questions[i].isRight()) {
        this.breakdown.RightCounts[levelIndex]++
        this.breakdown.rightCount++
      } else if (this.questions[i].participantAnswerData.answers.length !== 0) {
        this.breakdown.WrongCounts[levelIndex]++
        this.breakdown.wrongCount++
      }
      if (this.questions[i].participantAnswerData.answers.length === 0) {
        this.breakdown.UnAnsweredQuestionsInLevel[levelIndex]++
        this.breakdown.unAnsweredCount++
      }
    }
    this.breakdown.participantScore = this.getParticipantScore()
    this.breakdown.totalScore = this.getTotalScore()
  }

  getDifficultyLevelRightCount(difficultyLevelIndex) {
    return this.breakdown.RightCounts[difficultyLevelIndex]
  }

  getDifficultyLevelWrongCount(difficultyLevelIndex) {
    return this.breakdown.WrongCounts[difficultyLevelIndex]
  }

  getDifficultyLevelUnAnsweredQuestions(difficultyLevelIndex) {
    return this.breakdown.UnAnsweredQuestionsInLevel[difficultyLevelIndex]
  }

  getDifficultyLevelTotalCount(difficultyLevelIndex) {
    return (
      this.breakdown.RightCounts[difficultyLevelIndex] +
      this.breakdown.WrongCounts[difficultyLevelIndex]
    )
  }

  getDifficultyLevelScore(difficultyLevelIndex) {
    const difficultyLevelId = difficultyLevelIndex + 1, // ASSUMPTION: levelIndex + 1 = LevelId
      markingSchemeForLevel = this.quizSettings.getMarkingSchemeForDifficultyLevel(
        difficultyLevelId,
      )
    return (
      this.breakdown.RightCounts[difficultyLevelIndex] *
        markingSchemeForLevel.positivePoints +
      this.breakdown.WrongCounts[difficultyLevelIndex] *
        markingSchemeForLevel.negativePoints
    )
  }

  /*getQuestionsInLevel() {
            return this.breakdown.QuestionsInLevel;
    },*/

  getTotalScore() {
    let totalScore = 0
    const questionsInLevel = this.breakdown.QuestionsInLevel
    ;[0, 1, 2].forEach(function (difficultyLevelIndex) {
      const difficultyLevelId = difficultyLevelIndex + 1, // ASSUMPTION: levelIndex + 1 = LevelId
        markingSchemeForLevel = this.quizSettings.getMarkingSchemeForDifficultyLevel(
          difficultyLevelId,
        )
      totalScore +=
        questionsInLevel[difficultyLevelIndex] *
        markingSchemeForLevel.positivePoints
    }, this)

    return totalScore
  }

  getParticipantScore() {
    return (
      this.getDifficultyLevelScore(0) +
      this.getDifficultyLevelScore(1) +
      this.getDifficultyLevelScore(2)
    )
  }

  getDifficultyLevelText(difficultyLevelIndex) {
    switch (true) {
      case difficultyLevelIndex === QuizQuestion.DifficultyLevels.EASY:
        return 'Easy'
      case difficultyLevelIndex === QuizQuestion.DifficultyLevels.MEDIUM:
        return 'Medium'
      case difficultyLevelIndex === QuizQuestion.DifficultyLevels.DIFFICULT:
        return 'Difficult'
      default:
        return 'None'
    }
  }
}

export default QuizParticipantAnswerSummary
