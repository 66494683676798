import {useEffect, useState} from 'react'

const useInfiniteScroll = (callback) => {
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true)
    return () => window.removeEventListener('scroll', handleScroll, true)
  }, [])

  useEffect(() => {
    if (!isFetching) return
    callback(() => {
      console.log('called back')
    })
  }, [isFetching])

  function handleScroll() {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight ||
      isFetching
    ) {
      setIsFetching(true)
    }
  }
  return [isFetching, setIsFetching]
}

export default useInfiniteScroll
