import {useState, useEffect} from 'react'
import {useAuthContext} from '../auth'
import DisplayModule from './DisplayModule'
import {BackIcon} from '../../icons/index'
import {fetchModuleListing} from '../axios'
import {useHistory} from 'react-router-dom'
import '../../styles/ModuleListingView.scss'
import {useInfiniteScroll} from '../customhooks/'
import {LoadingState, ModuleType, ActivityType} from '../enums'

const ModuleListingView = ({onLogout}) => {
  const [moduleData, setModuleData] = useState({})
  const [offset, setOffset] = useState(0)
  const [loadingState, setLoadingState] = useState(LoadingState.FETCHING)

  const history = useHistory()

  const [isFetching, setIsFetching] = useInfiniteScroll(fetchMoreModules)

  const {appUser} = useAuthContext()

  const allowedActivityType = [
    ActivityType.DIGITAL_WHITEBOARD,
    ActivityType.QUIZ,
    ActivityType.VIDEO,
    ActivityType.INTERACTIVE,
  ]

  useEffect(() => {
    if (appUser) {
      const retrieveModuleData = async () => {
        const response = await fetchModuleListing()
        if (response) {
          const filterSelfPaced = response.pendingModules.filter(
            (modules) => modules.moduleType === ModuleType.SELFPACED,
          )
          // const filterModules = filterSelfPaced.reduce((a, c) => {
          //   const filterActivities = c.activities.filter((activity) => allowedActivityType.includes(activity.activityType))
          //   a.push({...c, activities: filterActivities})
          //   return a
          // }, [])
          response['pendingModules'] = filterSelfPaced
          setModuleData(response)
          setLoadingState(LoadingState.AVAILABLE)
        } else {
          setLoadingState(LoadingState.EMPTY)
        }
      }
      retrieveModuleData().then((r) => {})
    }
  }, [appUser])

  useEffect(() => {
    if (offset > 0) {
      fetchModuleListing(10 * offset).then((response) => {
        setModuleData((prevState) => ({
          ...prevState,
          pendingModules: [
            ...prevState.pendingModules,
            ...response.pendingModules,
          ],
        }))
        setIsFetching(false)
      })
    }
  }, [offset])

  function fetchMoreModules() {
    if (
      10 * (offset + 1) <=
      moduleData?.totalCount + (10 - (moduleData?.totalCount % 10))
    ) {
      setOffset((prevState) => prevState + 1)
    } else {
      setIsFetching(false)
    }
  }

  const handleModuleClick = (id) => {
    history.push(`/module/${id}`)
  }

  if (loadingState === LoadingState.FETCHING) {
    return (
      <div className={'flexCentered background fullHeight'}>
        <span>Fetching...</span>
      </div>
    )
  }

  if (loadingState === LoadingState.EMPTY) {
    return (
      <div className={'flexCentered background fullHeight'}>
        Something went wrong. Try again
      </div>
    )
  }

  return (
    <div className="module-listing-view">
      <div className="sticky-wrapper sticky">
        <div
          className="back-icon"
          onClick={() => {
            onLogout(history)
          }}>
          <BackIcon fill={'white'} />
        </div>
        <div className="my-modules">My Modules</div>
      </div>
      <div className="background-div">
        {moduleData?.pendingModules?.map((module) => (
          <DisplayModule
            module={module}
            handleModuleClick={handleModuleClick}
          />
        ))}
      </div>
    </div>
  )
}

export default ModuleListingView
