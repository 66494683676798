import '../../styles/ActivityView.scss'
import {useHistory} from 'react-router-dom'

const ActivityFooter = ({nextActivity, handleOnNextClick}) => {
  const history = useHistory()

  const handleGoToButton = () => {
    history.goBack()
  }

  return (
    <div className="activity-footer">
      <div
        className="footer-text"
        onClick={handleGoToButton}>{`Go to Activity Listing`}</div>
    </div>
  )
}

export default ActivityFooter
