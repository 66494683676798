import minBy from 'lodash/minBy'
import maxBy from 'lodash/maxBy'

class ActivityPlaybackUsageAnalyticsModel {
  constructor(config) {
    config = config || {}

    this.playbackDuration = config.playbackDuration || 0
    this.viewedCount = config.viewedCount || 0
    this.activityMetaInformation = config.activityMetaInformation
    this.previousUsageAnalytics =
      this.curateBadDataInPreviousUsageAnalytics(
        config.previousUsageAnalytics,
      ) || [] // Array of int arrays
    this.viewCountAnalyticsData = JSON.parse(
      JSON.stringify(this.previousUsageAnalytics),
    )
    this.aggregatedPreviousUsageAnalytics = this.aggregateAnalytics(
      this.previousUsageAnalytics,
    )
    this.currentUsageAnalytics =
      config.currentUsageAnalytics || this.getBaseUsageAnalyticsObject() // int array
    if (this.activityMetaInformation) {
      this.initViewCountWithDurationThreshold()
    }
  }

  setPlaybackDuration = (duration) => {
    this.playbackDuration = duration
  }

  curateBadDataInPreviousUsageAnalytics(prevUsageAnalytics) {
    const curatedAnalytics = []
    if (prevUsageAnalytics instanceof Array) {
      prevUsageAnalytics.forEach((analytics) => {
        const curatedAnalyticsItem = []
        if (analytics instanceof Array) {
          analytics.forEach((analyticsItem) => {
            const canInclude =
              typeof analyticsItem === 'object' &&
              analyticsItem.hasOwnProperty('start') &&
              analyticsItem.hasOwnProperty('end')
            if (canInclude) {
              curatedAnalyticsItem.push(analyticsItem)
            }
          })
        }
        curatedAnalytics.push(curatedAnalyticsItem)
      })
      return curatedAnalytics
    }
  }

  getPlaybackDuration = () => {
    return this.playbackDuration
  }

  getStartPoint() {
    // Gives the last point viewed to start the video
    return (
      (
        maxBy(this.aggregatedPreviousUsageAnalytics, (item) => {
          return item.end
        }) || {}
      ).end || 0
    )
  }

  getLeastStartPoint() {
    // Gives the initial point viewed to start the video
    return (
      (
        minBy(this.aggregatedPreviousUsageAnalytics, (item) => {
          return item.end
        }) || {}
      ).end || 0
    )
  }

  getMaxViewedPoint() {
    const previousMaxPoint =
      maxBy(this.aggregatedPreviousUsageAnalytics, (item) => item.end)?.end ?? 0
    const currentMaxPoint =
      maxBy(this.currentUsageAnalytics, (item) => item.end)?.end ?? 0
    return Math.max(previousMaxPoint, currentMaxPoint)
  }

  getViewedCount = () => {
    return this.viewedCount
  }
  initViewCountWithDurationThreshold = () => {
    let restrictedViewConfigJson
    const allAnalyticsItems = []
    const overallAnalytics = []
    const segmentDuration = 5
    //Constructing empty analytics data.
    for (
      let i = 0;
      i <= Math.floor(this.playbackDuration / segmentDuration);
      i++
    ) {
      overallAnalytics[i] = 0
    }
    this.viewCountAnalyticsData.forEach((chunks, i) => {
      let lastUpdatedSegment = -1
      chunks.forEach(function (chunk, j) {
        for (
          let segment = Math.floor(
            Math.round(chunk.start / 1000) / segmentDuration,
          );
          segment <= Math.floor(Math.round(chunk.end / 1000) / segmentDuration);
          segment++
        ) {
          // eslint-disable-next-line eqeqeq
          if (chunk.start == chunk.end) continue // this ensure trivial views are not recorded
          // eslint-disable-next-line eqeqeq
          if (segment != lastUpdatedSegment) {
            // to ensure the same segment does not get updated again
            overallAnalytics[segment]++
            lastUpdatedSegment = segment
          }
        }
      }, this)
    }, this)
    for (
      let viewCount = 1;
      viewCount <= this.activityMetaInformation.allowedViewCount;
      viewCount++
    ) {
      let currentViewedSegments = 0
      for (
        let analytics = 0;
        analytics < overallAnalytics.length;
        analytics++
      ) {
        if (overallAnalytics[analytics] >= viewCount) {
          currentViewedSegments++
        }
      }
      if (
        (currentViewedSegments / overallAnalytics.length) * 100 >=
        this.activityMetaInformation.durationThresholdPercentage
      ) {
        this.viewedCount++
      }
    }
  }

  aggregateAnalytics = (analyticsCollection) => {
    const allAnalyticsItems = []
    analyticsCollection.forEach((analyticsCollectionItem) => {
      allAnalyticsItems.splice.apply(
        allAnalyticsItems,
        [allAnalyticsItems.length, 0].concat(analyticsCollectionItem),
      )
    })
    if (allAnalyticsItems.length <= 1) {
      // Just a safety check, for 0 items in allAnalyticsItems array
      return allAnalyticsItems
    }

    // First, Arrange in increasing order of each item's start property
    allAnalyticsItems.sort((item1, item2) => {
      return item1.start - item2.start
    })

    // Push the first item from allAnalyticsItems into the result collection
    const aggregatedAnalyticsItems = [allAnalyticsItems[0]]
    /* var toString = function (o) {
                return "{ " + o.start + " to " + o.end + " }";
            }; */

    // Define a routine to check and merge analytics items
    const checkAndMergeAnalyticsItemIntoResultCollection = (analyticsItem) => {
      let merged = false,
        currentItem
      for (let i = 0; i < aggregatedAnalyticsItems.length; i++) {
        currentItem = aggregatedAnalyticsItems[i]

        // If analyticsItem's start falls between currentItem's start and end, then merge
        if (
          currentItem.start <= analyticsItem.start &&
          analyticsItem.start <= currentItem.end
        ) {
          currentItem.start = Math.min(currentItem.start, analyticsItem.start)
          currentItem.end = Math.max(currentItem.end, analyticsItem.end)
          merged = true
          // console.log("currentItem {0} merged with analyticsItem {1}".format(toString(currentItem), toString(analyticsItem)));
          break
        } else {
          // Do nothing, proceed to assess the next item in aggregatedAnalyticsItems
          // console.log("currentItem {0} NOT merged with analyticsItem {1}".format(toString(currentItem), toString(analyticsItem)));
        }
      }

      if (!merged) {
        aggregatedAnalyticsItems.push(analyticsItem)
      }
    }

    // Iterate over all remaining items from allAnalyticsItems, and merge with result collection
    const remainingItems = allAnalyticsItems.slice(1)
    remainingItems.forEach(checkAndMergeAnalyticsItemIntoResultCollection)

    /* console.log("aggregatedAnalyticsItems : ");
            aggregatedAnalyticsItems.forEach(function (o) {
                console.log(toString(o));
            }); */
    return aggregatedAnalyticsItems
  }

  getBaseUsageAnalyticsObject = () => {
    return []
  }

  getCurrentUsageAnalytics = () => {
    return this.currentUsageAnalytics
  }
  getTotalPlaybackPercentage = () => {
    let duration = 0
    this.currentUsageAnalytics.forEach((usageData, i) => {
      duration += usageData.end - usageData.start
    })
    return parseInt((duration / this.playbackDuration) * 100)
  }
  updateCurrentUsageAnalytics = (newUsagePacket, usageMetric) => {
    usageMetric = parseInt(usageMetric * 1000)
    // If currentUsageAnalytics array is empty, add an item to it, when newUsagePacket value is false
    // Such a case seems to be triggered on flowplayer in a mobile device (viz Android chrome).
    if (newUsagePacket || this.currentUsageAnalytics.length === 0) {
      this.currentUsageAnalytics.push({start: usageMetric, end: usageMetric})
    } else {
      /*if (this.currentUsageAnalytics.length === 0) {
            console.log("ActivityPlaybackUsageAnalyticsModel.updateCurrentUsageAnalytics() " +
                "called on an empty array, to update 'end' to " + usageMetric);
            return;
        }*/
      this.currentUsageAnalytics[
        this.currentUsageAnalytics.length - 1
      ].end = usageMetric
    }
  }

  isUsageAlreadyComplete = () => {
    // Changing completion threshold to 95 percent to avoid users reporting issues related to
    // completion (due to potential seconds to milliseconds mismatch)
    return this.getUsageCompletionPercentage(false) >= 95 // === 100;
  }

  isUsageComplete = () => {
    // Changing completion threshold to 95 percent to avoid users reporting issues related to
    // completion (due to potential seconds to milliseconds mismatch)
    return this.getUsageCompletionPercentage(true) >= 95 // === 100;
  }

  hasUsageBegun = () => {
    return this.getUsageCompletionPercentage(true) === 0
  }

  hasLocationBeenSeen = (playbackLocation) => {
    playbackLocation = playbackLocation || 0
    const locationPercentage = (100 * playbackLocation) / this.playbackDuration
    return this.getUsageCompletionPercentage(true) >= locationPercentage
  }

  getUsageCompletionPercentage = (overallUsage) => {
    let aggregatedTimeSpent = 0,
      analyticsCollectionToEvaluate
    if (overallUsage) {
      analyticsCollectionToEvaluate = this.aggregateAnalytics([
        this.aggregatedPreviousUsageAnalytics,
        this.currentUsageAnalytics,
      ])
    } else {
      analyticsCollectionToEvaluate = this.aggregatedPreviousUsageAnalytics
    }

    analyticsCollectionToEvaluate.forEach((analyticsItem) => {
      aggregatedTimeSpent += analyticsItem.end - analyticsItem.start
    })

    return Math.ceil((100 * aggregatedTimeSpent) / this.playbackDuration)
  }

  getLastPlayedTimelineMarkerInMs = () => {
    let lastPlayedTimelineMarkerInMs = 0
    ;(this.previousUsageAnalytics || []).forEach(function (item) {
      item.forEach(function (obj) {
        if (lastPlayedTimelineMarkerInMs < obj.end) {
          lastPlayedTimelineMarkerInMs = obj.end
        }
      })
    })
    return lastPlayedTimelineMarkerInMs
  }

  getPlaybackAnalytics = () => {
    return this.aggregateAnalytics([
      this.aggregatedPreviousUsageAnalytics,
      this.currentUsageAnalytics,
    ])
  }
}

export default ActivityPlaybackUsageAnalyticsModel
