import {useState} from 'react'
import ActivityHeader from '../ActivityHeader'
import ActivityFooter from '../ActivityFooter'
import {
  BackIcon,
  CorrectIcon,
  SkippedIcon,
  WrongFilledIcon,
} from '../../../icons'
import QuizParticipantAnswerSummary from './model/QuizParticipantAnswerSummary'
import QuizQuestion from '../../model/quiz/QuizQuestion'
import '../../../styles/ResultView.scss'

const ResultView = ({
  attemptCount,
  isRevealed,
  quizSettings,
  quizQuestionAnswers,
  nextActivity,
  handleOnNextClick,
  onReviewClick,
  hasUserPassed,
  onQuizReattempt,
}) => {
  const [showScheme, setShowScheme] = useState(false)
  const [showScoreBreakup, setScoreBreakup] = useState(false)
  const [showReattemptModal, setReattemptModal] = useState(false)

  const reattemtModalBody = `Re-attempting this quiz will clear up your current quiz responses and score. Do you wish to proceed?`

  const handleSchemeClick = () => {
    setShowScheme((prevState) => !prevState)
  }

  const handleScoreBreakupClick = () => {
    setScoreBreakup(!showScoreBreakup)
  }

  const answerSummaryOverAll = new QuizParticipantAnswerSummary(
    quizQuestionAnswers,
    quizSettings,
  )

  const renderScheme = () => {
    const toRenderMarkingScheme = null
    const markingScheme = quizSettings.markingScheme
    return (
      <div className="scheme-div">
        <div className="scheme-row">
          <div className="scheme-content"></div>
          <div className="scheme-content">
            {markingScheme[0].difficultyLevelName}
          </div>
          <div className="scheme-content">
            {markingScheme[1].difficultyLevelName}
          </div>
          <div className="scheme-content">
            {markingScheme[2].difficultyLevelName}
          </div>
        </div>
        <div className="horizontal-line"></div>
        <div className="scheme-row">
          <div className="scheme-content">
            <CorrectIcon />
          </div>
          <div className="scheme-content">
            {markingScheme[0].positivePoints}
          </div>
          <div className="scheme-content">
            {markingScheme[1].positivePoints}
          </div>
          <div className="scheme-content">
            {markingScheme[2].positivePoints}
          </div>
        </div>
        <div className="horizontal-line"></div>
        <div className="scheme-row">
          <div className="scheme-content">
            <WrongFilledIcon />
          </div>
          <div className="scheme-content">
            {markingScheme[0].negativePoints}
          </div>
          <div className="scheme-content">
            {markingScheme[1].negativePoints}
          </div>
          <div className="scheme-content">
            {markingScheme[2].negativePoints}
          </div>
        </div>
      </div>
    )
  }

  const renderScoreBreakup = () => {
    let sections = quizQuestionAnswers.map((q, i) => q.sectionName)
    sections = Array.from(new Set(sections))
    return sections.map((section, count) => {
      const answerSummary = new QuizParticipantAnswerSummary(
        quizQuestionAnswers.filter((s) => s.sectionName === section),
        quizSettings,
      )
      const difficultyLevels = getDifficultyLevels(answerSummary)
      return (
        <>
          <div className="section-name-review">{`${section}`}</div>
          {renderDifficultyLevels(difficultyLevels)}
        </>
      )
    })
  }

  const renderDifficultyLevels = (difficultyLevels) => {
    const availableDifficultyLevels = []
    difficultyLevels.forEach((difficultyLevel, index) => {
      if (
        difficultyLevel.unansweredCount > 0 ||
        difficultyLevel.wrongCount > 0 ||
        difficultyLevel.rightCount > 0
      ) {
        availableDifficultyLevels.push(
          renderDifficultyLevelItem(difficultyLevels, difficultyLevel, index),
        )
      }
    })
    return availableDifficultyLevels
  }

  const renderDifficultyLevelItem = (
    difficultyLevels,
    difficultyLevel,
    index,
  ) => {
    return (
      <div className="score-breakup-div">
        <div className="score-breakup-row">
          <div className="score-breakup-type">{`${difficultyLevel.text} - ${difficultyLevel.totalCount}`}</div>
          <div className="score-breakup-score">{`Score ${difficultyLevel.score}`}</div>
        </div>
        <div className="score-breakup-row">
          <div className="print-score-div">
            <div className="print-score">
              <CorrectIcon />
              <div className="spaces"></div>
              {difficultyLevel.rightCount}
            </div>
            <div className="print-result">Correct</div>
          </div>
          <div className="print-score-div">
            <div className="print-score">
              <SkippedIcon />
              <div className="spaces"></div>
              {difficultyLevel.unansweredCount}
            </div>
            <div className="print-result">Skipped</div>
          </div>
          <div className="print-score-div">
            <div className="print-score">
              <WrongFilledIcon />
              <div className="spaces"></div>
              {difficultyLevel.wrongCount}
            </div>
            <div className="print-result">Wrong</div>
          </div>
        </div>
      </div>
    )
  }

  const getDifficultyLevels = (answerSummary) => {
    const difficultyLevels = QuizQuestion.DifficultyLevels
    const difficultyLevelArray = []
    for (const difficultyLevel in difficultyLevels) {
      difficultyLevelArray.push({
        text: answerSummary.getDifficultyLevelText(
          difficultyLevels[difficultyLevel],
        ),
        index: difficultyLevel - 1,
        score: answerSummary.getDifficultyLevelScore(
          difficultyLevels[difficultyLevel] - 1,
        ),
        rightCount: answerSummary.getDifficultyLevelRightCount(
          difficultyLevels[difficultyLevel] - 1,
        ),
        wrongCount: answerSummary.getDifficultyLevelWrongCount(
          difficultyLevels[difficultyLevel] - 1,
        ),
        unansweredCount: answerSummary.getDifficultyLevelUnAnsweredQuestions(
          difficultyLevels[difficultyLevel] - 1,
        ),
        totalCount: answerSummary.getDifficultyLevelTotalCount(
          difficultyLevels[difficultyLevel] - 1,
        ),
      })
    }
    return difficultyLevelArray
  }

  const isParticipantScoredFullMarks = () => {
    const answerSummary = new QuizParticipantAnswerSummary(
      quizQuestionAnswers,
      quizSettings,
    )
    return answerSummary.getParticipantScore() === answerSummary.getTotalScore()
  }

  const canShowReAttemptButton =
    quizSettings.isReattemptAllowed(attemptCount) &&
    (!hasUserPassed() || quizSettings.isReattemptToBeatScore) &&
    !isParticipantScoredFullMarks()

  const getReAttemptButtonTitle = () => {
    const {reattemptsAllowed, isReattemptToBeatScore} = quizSettings

    let buttonTitle = ''

    const _attemptCount = reattemptsAllowed - attemptCount + 1

    const attemptLabel = _attemptCount === 1 ? 'Attempt' : 'Attempts'

    if (reattemptsAllowed === -1) {
      buttonTitle = 'Reattempt'
      if (isReattemptToBeatScore && hasUserPassed()) {
        buttonTitle = 'Beat Score'
      }
    } else if (isReattemptToBeatScore && hasUserPassed()) {
      buttonTitle = `Beat score (${_attemptCount} ${attemptLabel} left)`
    } else {
      buttonTitle = `Reattempt (${_attemptCount} ${attemptLabel} left)`
    }

    return buttonTitle
  }

  const handleReattemptClick = () => {
    setReattemptModal(!showReattemptModal)
  }

  const handleYesReattempt = () => {
    onQuizReattempt()
  }

  return (
    <>
      <ActivityHeader activityName="Result" />
      <div className="result-div">
        <div className="result-inner-div">
          <div className="you-scored-text">You scored</div>
          <div className="display-score-div">
            <div className="circular-div">
              <div className="display-score">{`${answerSummaryOverAll.getParticipantScore()} / ${answerSummaryOverAll.getTotalScore()}`}</div>
            </div>
          </div>
          <div className="quiz-result-text">Your quiz result is :</div>
          <div className="qiuz-result-div">
            <div className="quiz-scores">
              <div className="result-score">
                <CorrectIcon />
                <div className="spaces"></div>
                {`${answerSummaryOverAll.breakdown.rightCount}/${answerSummaryOverAll.questions.length}`}
              </div>
              <div className="result-text">Correct</div>
            </div>
            <div className="straight-line"></div>
            <div className="quiz-scores">
              <div className="result-score">
                <WrongFilledIcon />
                <div className="spaces"></div>
                {`${answerSummaryOverAll.breakdown.wrongCount}/${answerSummaryOverAll.questions.length}`}
              </div>
              <div className="result-text">Wrong</div>
            </div>
            <div className="straight-line"></div>
            <div className="quiz-scores">
              <div className="result-score">
                <SkippedIcon />
                <div className="spaces"></div>
                {`${answerSummaryOverAll.breakdown.unAnsweredCount}/${answerSummaryOverAll.questions.length}`}
              </div>
              <div className="result-text">Skipped</div>
            </div>
          </div>
          <div className="marking-scheme" onClick={handleSchemeClick}>
            Marking scheme
            <div className={`toggle-marking-scheme ${showScheme && 'open'}`}>
              <BackIcon />
            </div>
          </div>
          {showScheme && renderScheme()}
          <div className="marking-scheme" onClick={handleScoreBreakupClick}>
            Score Breakup
            <div
              className={`toggle-marking-scheme ${showScoreBreakup && 'open'}`}>
              <BackIcon />
            </div>
          </div>
          {showScoreBreakup && renderScoreBreakup()}
          {!canShowReAttemptButton && (
            <div className="review-button-div" onClick={onReviewClick}>
              <div className="review-button">Review One-by-One</div>
            </div>
          )}
          {canShowReAttemptButton && (
            <div className="review-button-div" onClick={handleReattemptClick}>
              {showReattemptModal && (
                <div className="reattempt-background">
                  <div className="reattempt-modal">
                    <div className="reattempt-content-div">
                      <div className="reattempt-modal-header">Confirm</div>
                      <div className="reattempt-modal-body">
                        {reattemtModalBody}
                      </div>
                      <div className="reattempt-action-div">
                        <div
                          className="reattempt-action-button"
                          onClick={handleYesReattempt}>
                          YES
                        </div>
                        <div
                          className="reattempt-action-button"
                          onClick={handleReattemptClick}>
                          NO
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="review-button">{getReAttemptButtonTitle()}</div>
              <div>
                {quizSettings.isReattemptToBeatScore &&
                  'You can beat your present score. No matter what your score is in subsequent attempts, we record the highest score.'}
              </div>
            </div>
          )}
        </div>
      </div>
      <ActivityFooter
        nextActivity={nextActivity}
        handleOnNextClick={handleOnNextClick}
      />
    </>
  )
}

export default ResultView
