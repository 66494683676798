const UserContexts = {
  SKILLMASTER: 1,
  LEARNER: 2,
  ADMINISTRATOR: 3,
  TEACHER: 4,
  STUDENT: 5,
  PARENT: 6,
  K12ADMINISTRATOR: 7,
}

export default UserContexts
