import '../../styles/ModuleDetails.scss'
import DisplayActivity from './DisplayActivity'

const ModuleDetails = ({activityData, handleActivityClick}) => {
  return (
    <div className="detail-div">
      <div className="detail-name">{activityData?.description}</div>
      {activityData?.activities.length > 0 ? (
        activityData.activities.map((activity) => (
          <DisplayActivity
            activity={activity}
            handleActivityClick={handleActivityClick}
          />
        ))
      ) : (
        <div>
          These activities are not supported on mobile web - please download the
          app to view
        </div>
      )}
    </div>
  )
}

export default ModuleDetails
