import {ApplicationSettingType} from '../../enums'
import DocumentActivity from './DocumentActivity'
// import VideoInteractiveQuestion from './VideoInteractiveQuestion'

class VideoActivity extends DocumentActivity {
  constructor(config = {}, user) {
    super(config)
    this.interactiveQuestions = this.initInteractiveQuestions(
      config.interactiveQuestions || [],
    )
    //answers if previously submitted
    let restrictedViewConfigObj = {}
    if (user) {
      const restrictedViewsConfig = user.getSettingValue(
        ApplicationSettingType.RESTRICT_VIDEO_VIEWS,
      )
      if (restrictedViewsConfig) {
        restrictedViewConfigObj = JSON.parse(restrictedViewsConfig)
      }
    }
    this.activityMetaInformation = config.activityMetaInformation
      ? JSON.parse(config.activityMetaInformation)
      : restrictedViewConfigObj
    this.userAnsweredQuestionIds = config.userAnsweredQuestionIds || []
    this.allowedViewCount = config.allowedViewCount || 0
  }

  initInteractiveQuestions(interactiveQuestions) {
    const toReturn = []
    /*interactiveQuestions.forEach(function (questionData) {
      const videoInteractiveQuestion = VideoInteractiveQuestion.deserializeFromSavedData(
        questionData,
      )
      toReturn.push(videoInteractiveQuestion)
    }, this)*/
    return toReturn
  }

  toAllowLimitedViewsConfiguration() {
    return this.activityMetaInformation.allowedViewCount !== undefined
  }

  hasExceededViewingLimit(viewedCount) {
    return (
      this.allowedViewCount > 0 &&
      viewedCount >= this.activityMetaInformation.allowedViewCount
    )
  }

  getInteractiveQuestionLocations() {
    return this.interactiveQuestions.map(function (quizQuestion) {
      return quizQuestion.timeToProjectQuestion / 1000
    })
  }
}

export default VideoActivity

/*TODO: Extend this class from Activity instead of DocumentActivity*/
