/* eslint-disable */

import MultipleChoiceQuestion from '../activity/quiz/MultipleChoiceQuestion'
import QuizQuestionContentItem from '../activity/quiz/QuizQuestionContentItem'

// Class-aware serializer/deserializer
const Serializer = (function () {
  // The prefix for transient properties
  // (those that don't hold permanent state of an object and hence don't need to be serialized)
  var PREFIX_FOR_TRANSIENT_PROPS = 'tr_'

  var serVersion = 1

  var fieldSeparator = '\u2669'
  //var childObjSeparator = '\u266B';
  //var childObjOpeningParanth = '\u2523', childObjClosingParanth = '\u252B'; // Used only as visual aids
  var childObjOpeningParanth = '\u276A',
    childObjClosingParanth = '\u276B' // Used only as visual aids
  var arrayOpeningParanth = '\u2560',
    arrayClosingParanth = '\u2563',
    arraySeparator = '\u2550'
  var numberClassShortName = '\u220F',
    stringClassShortName = '\u2201',
    booleanClassShortName = '\u25CE',
    dateClassShortName = '\u25F4'
  var classNameStartCode = '\u2640'.charCodeAt(0),
    currentCodePropName = 'currentCode'
  var nullValue = '\u26AC'
  var restrictedCharacters = [
    fieldSeparator,
    childObjOpeningParanth,
    childObjClosingParanth,
    arrayOpeningParanth,
    arrayClosingParanth,
    arraySeparator,
    numberClassShortName,
    stringClassShortName,
    booleanClassShortName,
    dateClassShortName,
    classNameStartCode,
    nullValue,
  ]
  // This function returns all properties (excluding functions and transient properties) on an object traveling up the prototype chain.
  // It returns the properties as an array whose elements are sorted in alphabetical order.
  function props(o) {
    var props = new Array()
    for (var p in o) {
      if (
        'function' != typeof o[p] &&
        p.indexOf(PREFIX_FOR_TRANSIENT_PROPS) != 0
      )
        props.push(p)
    }
    props.sort()
    return props
  }

  // This function returns own properties (excluding functions and transient properties) on an object *without* traveling up the prototype chain.
  // It returns the properties as an array whose elements are sorted in alphabetical order.
  function ownProps(o) {
    var props = new Array()
    for (var p in o) {
      if (
        o.hasOwnProperty(p) &&
        'function' != typeof o[p] &&
        p.indexOf(PREFIX_FOR_TRANSIENT_PROPS) != 0
      )
        props.push(p)
    }
    props.sort()
    return props
  }

  // Returns the "class name" of a particular object
  function getClassName(o) {
    var classNameRegex = /\s*function\s*(\w+)\s*\(/
    var cstr = o.constructor.toString()
    var matches = cstr.match(classNameRegex)
    if (!matches) return o.getConstructor()
    return matches.length >= 2 ? matches[1] : null
  }

  //http://stackoverflow.com/questions/6473273/why-are-myarray-instanceof-array-and-myarray-constructor-array-both-false-wh

  function checkIsArray(o) {
    return Object.prototype.toString.call(o) === '[object Array]'
  }

  // Serializes the property p in object o as a string.
  // Note that o could be an array, in which we also need to serialize the data type of o.
  // If o is a non-array object however, we can save some space and not serialize the data type since we know the properties of a class.
  function _serProp(o, p, classNames) {
    //console.log("_serProp(" + JSON.stringify(o) + "," + p + ")");
    if (isHarnessObject(o) && o.customSerDeserProp(p)) {
      return o.serProp(p)
    } else {
      var type = typeof o[p]

      var isArray = checkIsArray(o)
      if (undefined === o[p]) {
        return undefined
      } else if (null === o[p]) {
        return nullValue
      } else if (isPrimitiveType(type)) {
        return serPrimitive(type, o[p], isArray)
      } else if ('object' == type) {
        var className = getClassName(o[p])
        //console.log("className=" + className);
        if ('Date' == className)
          return (
            (isArray ? dateClassShortName + fieldSeparator : '') +
            o[p].getTime()
          )
        else if ('Array' == className) {
          return _serArray(o[p], classNames)
        }
        //return childObjOpeningParanth + serWith(o[p], childObjSeparator) + childObjClosingParanth;
        //return  childObjOpeningParanth + serWith(o[p], fieldSeparator) + childObjClosingParanth;
        else return serNoVersion(o[p], classNames)
      }
    }
  }

  function isHarnessObject(o) {
    var curr = o
    while (curr) {
      if (getClassName(curr) == 'HarnessObject') return true
      curr = curr.prototype
    }
    return false
  }

  function isPrimitiveType(type) {
    return (
      'STRING' == type.toUpperCase() ||
      'NUMBER' == type.toUpperCase() ||
      'BOOLEAN' == type.toUpperCase() ||
      stringClassShortName == type ||
      numberClassShortName == type ||
      booleanClassShortName == type
    )
  }

  function serPrimitive(type, val, includeClassName) {
    switch (type.toUpperCase()) {
      case 'STRING':
        return (
          (includeClassName ? stringClassShortName + fieldSeparator : '') + val
        )
      case 'NUMBER':
        return (
          (includeClassName ? numberClassShortName + fieldSeparator : '') + val
        )
      case 'BOOLEAN':
        return (
          (includeClassName ? booleanClassShortName + fieldSeparator : '') +
          (val ? '1' : '0')
        )
    }
  }

  function _serArray(arr, classNames) {
    var serdstr = arrayOpeningParanth
    var first = true
    for (var n = 0; n < arr.length; n++) {
      if (!first) serdstr += arraySeparator
      first = false
      serdstr += _serProp(arr, n, classNames)
    }
    return serdstr + arrayClosingParanth
  }

  function _ser(o, classNames) {
    //console.log("_ser(" + JSON.stringify(o) + ")");
    var props = ownProps(o)
    var serd = new Array()
    props.forEach(function (p) {
      serd.push(_serProp(o, p, classNames))
    })

    var clsName = getClassName(o)
    //console.log("obtained classname",clsName);
    if (classNames[clsName] !== undefined) {
      clsName = classNames[clsName]
      //console.log("existing abbr class name=", classNames[clsName], ",abbr class name=", classNames[clsName]);
    } else {
      classNames[clsName] = String.fromCharCode(classNames[currentCodePropName])
      classNames[currentCodePropName] = classNames[currentCodePropName] + 1
      //console.log("new abbr class name=", classNames[clsName], ",currCode=", classNames[currentCodePropName]);
      clsName = classNames[clsName]
    }
    var serdstr =
      childObjOpeningParanth +
      clsName +
      fieldSeparator +
      serd.join(fieldSeparator) +
      childObjClosingParanth
    return serdstr
  }

  function serNoVersion(o, classNames) {
    var className = getClassName(o)
    if (!className) {
      throw "Unable to get the 'class name' of object " + o
    }
    if (isHarnessObject(o) && o.customSerDeserObject()) {
      return (
        childObjOpeningParanth +
        className +
        fieldSeparator +
        o.serObject(fieldSeparator) +
        childObjClosingParanth
      )
    } else if (isPrimitiveType(className)) {
      // Top-level "primitive"
      return serPrimitive(className, o, true)
    } else if ('Date' == className) {
      return dateClassShortName + fieldSeparator + o.getTime()
    } else {
      var serdstr =
        o instanceof Array ? _serArray(o, classNames) : _ser(o, classNames)
      //console.log(serdstr);
      return serdstr
    }
  }

  // Entry point for serialization
  function ser(o) {
    var classNames = new Object()
    classNames[currentCodePropName] = classNameStartCode
    var body = serNoVersion(o, classNames)
    //console.log("className=", JSON.stringify(classNames), ",index=", classNameIndex(classNames));
    var serdstr =
      serVersion + fieldSeparator + classNameIndex(classNames) + body
    //console.log("serdstr=" + serdstr);
    return serdstr
  }

  function classNameIndex(classNames) {
    var props = ownProps(classNames)
    var index = ''
    var num = 0
    props.forEach(function (cls) {
      if (currentCodePropName != cls) {
        index += classNames[cls] + fieldSeparator + cls + fieldSeparator
        num++
      }
    })
    return '' + num + fieldSeparator + index
  }

  //-------------------------------------END SERIALIZER-------------------------------------

  //-------------------------------------BEGIN DESERIALIZER---------------------------------
  // We don't need specific Deser subclasses for each serialization version if ALL clients run the same code.
  // classFactory is an optional callback function, that, if provided, will be called to create new instances of all non-built-in classes.
  // In production this need not be passed. For unit tests, it would be easier to mock class factories.
  function deSer(serdstr, classFactory) {
    var classNames = deserClassNames(serdstr.substring(2))
    //console.log("classNames=", JSON.stringify(classNames));
    // skip serialization version
    return _deSer(
      serdstr.substring(2 + classNames.ind),
      false,
      classNames.classNames,
      classFactory,
    )
  }

  function deserClassNames(serdstr) {
    var deser = new DeserHelper(serdstr)
    var numClasses = parseInt(deser.advanceField())
    var classNames = new Object()
    for (var n = 0; n < numClasses; n++)
      classNames[deser.advanceField()] = deser.advanceField()
    return {
      classNames: classNames,
      ind: deser.currPos(),
    }
  }

  function _deSer(serdstr, child, classNames, classFactory) {
    //console.log("_deser(" + serdstr + ","  + child + "," + JSON.stringify(classNames) + ")");
    var shouldHandleVideo = false
    var deser
    var className
    if (serdstr.charAt(0) == arrayOpeningParanth) {
      className = 'Array'
      deser = new DeserHelper(serdstr.substring(1, serdstr.length - 1))
    } else {
      deser = new DeserHelper(
        serdstr.charAt(0) == childObjOpeningParanth
          ? serdstr.substring(1, serdstr.length - 1)
          : serdstr,
      )
      className = deser.advanceField()
    }
    if ('Array' == className) {
      return deserArray(deser.fullSerdStr(), classNames, classFactory)
    } else if (isPrimitiveType(className)) {
      // Top-level "primitive"
      return deserPrimitive(className, deser.advanceField())
    } else if (dateClassShortName == className) {
      return new Date(parseInt(deser.advanceField()))
    } else {
      //console.log("Replacing abbr name ",className, " with ", classNames[className]);
      className = classNames[className]
      if (!className) {
        console.warn(
          String.format(
            'Unexpected charcter {0} found during deserialization. This will be ignored',
            serdstr,
          ),
        )
        return null
      }
      //var inst = classFactory ? classFactory.instantiate(className) : eval("new " + className + "()");
      var inst = getRespectiveES6Object(className)
      if (isHarnessObject(inst) && inst.customSerDeserObject()) {
        return inst.deserObject(fieldSeparator)
      }
      var props = ownProps(inst)
      var pLen = props.length
      for (var i = 0; i < pLen; i++) {
        var p = props[i]
        if (
          p == 'contentType' &&
          inst.getConstructor() == 'Harness.objecttypes.HtmlObject'
        ) {
          var firstValue = deser.advanceField()
          var secondValue = deser.advanceFieldConst(fieldSeparator)
          if (firstValue == secondValue) {
            inst[p] = deserProp(inst, p, firstValue, classNames, classFactory)
          } else {
            i = i + 1
            p = props[i]
            inst[p] = deserProp(inst, p, firstValue, classNames, classFactory)
          }
        } else {
          var currserString = deser.advanceField()
          try {
            inst[p] = deserProp(
              inst,
              p,
              currserString,
              classNames,
              classFactory,
            )
          } catch (e) {
            console.error(
              'Error in Property : ' +
                p +
                ' belonging to class : ' +
                inst.getConstructor(),
            )
            console.error('Property Value : ' + currserString)
            console.error(e.message)
            //ignore this property and proceed further
          }
        }
      }
      return inst
    }

    function getRespectiveES6Object(className) {
      switch (className) {
        case 'Heuristix.model.quiz.MultipleChoiceQuestion':
          return new MultipleChoiceQuestion()
        case 'Heuristix.model.quiz.QuizQuestionContentItem':
          return new QuizQuestionContentItem()
      }
    }

    function deserPrimitive(type, val) {
      switch (type) {
        case stringClassShortName:
          return val
        case numberClassShortName:
          return eval(val) // we use eval to return a non-boxed primitive number as opposed to an instance of Number class
        case booleanClassShortName:
          return '1' == val
      }
    }

    function deserProp(o, p, serdstr, classNames, classFactory) {
      if (isHarnessObject(o) && o.customSerDeserProp(p)) {
        return o.deserProp(deser.advanceField(), p)
      } else {
        var type = typeof o[p]
        //console.log("type=" + type);
        if ('' == serdstr) {
          return undefined
        } else if (nullValue == serdstr) {
          return null
        } else if ('string' == type) {
          return serdstr
        } else if ('number' == type) {
          return eval(serdstr) // we use eval to return a non-boxed primitive number as opposed to an instance of Number class
        } else if ('boolean' == type) {
          return '1' == serdstr
        } else if ('object' == type) {
          if ('Date' == getClassName(o[p])) {
            return new Date(parseInt(serdstr))
          } else {
            //return _deSer(serdstr.substring(1, serdstr.length - 1), childObjSeparator);
            return _deSer(serdstr, true, classNames, classFactory)
          }
        }
      }
    }

    function deserArray(arrSerdStr, classNames, classFactory) {
      var arr = new Array()
      var deser = new DeserHelper(arrSerdStr)
      while (true) {
        var elementSerdStr = deser.advanceArrayElement()
        if (!elementSerdStr) break
        arr.push(_deSer(elementSerdStr, true, classNames, classFactory))
      }
      return arr
    }
  }

  function removeRestrictedCharacters(value) {
    for (var c = 0, cLen = restrictedCharacters.length; c < cLen; c++) {
      if (value.indexOf(restrictedCharacters[c]) != -1) {
        value = value.replaceAll(restrictedCharacters[c], '')
      }
    }
    return value
  }

  function DeserHelper(serdstr) {
    var currpos = 0

    function advanceField() {
      return advanceTo(fieldSeparator)
    }

    function advanceArrayElement() {
      var f = advanceTo(arraySeparator)
      var ret
      if (f == null || !isPrimitiveType(f)) {
        ret = f
      } else {
        // For primitive types in an array, we also include the class name, so we need to advance 2 fields.
        ret = f + fieldSeparator + advanceTo(arraySeparator)
      }
      //console.log("advanceArrayElt returning " + ret);
      return ret
    }

    function advanceTo(separator) {
      var ret = ''
      var nestLevel = 0
      while (currpos < serdstr.length) {
        var chr = serdstr.charAt(currpos++)
        if (chr == childObjOpeningParanth || chr == arrayOpeningParanth) {
          nestLevel++
        } else if (
          chr == childObjClosingParanth ||
          chr == arrayClosingParanth
        ) {
          nestLevel--
        } else if (nestLevel == 0 && chr == separator) {
          //console.log("advanceTo(" +  separator + ") returning " + ret);
          return ret
        }
        ret += chr
      }
      //console.log("advanceTo(" +  separator + ") returning " + ret);
      return ret.length > 0 ? ret : null // last field
    }

    function advanceToConst(separator) {
      var ret = ''
      var nestLevel = 0
      var currposConst = currpos
      while (currposConst < serdstr.length) {
        var chr = serdstr.charAt(currposConst++)
        if (chr == childObjOpeningParanth || chr == arrayOpeningParanth) {
          nestLevel++
        } else if (
          chr == childObjClosingParanth ||
          chr == arrayClosingParanth
        ) {
          nestLevel--
        } else if (nestLevel == 0 && chr == separator) {
          //console.log("advanceTo(" +  separator + ") returning " + ret);
          return ret
        }
        ret += chr
      }
      //console.log("advanceTo(" +  separator + ") returning " + ret);
      return ret.length > 0 ? ret : null // last field
    }

    function fullSerdStr() {
      return serdstr
    }

    function currPos() {
      //console.log("currpos=", currpos);
      return currpos
    }

    return {
      advanceField: advanceField,
      advanceFieldConst: advanceToConst,
      advanceArrayElement: advanceArrayElement,
      fullSerdStr: fullSerdStr,
      currPos: currPos,
    }
  }

  return {
    serialize: ser,
    deSerialize: deSer,
    getClassName: getClassName,
    removeRestrictedCharacters: removeRestrictedCharacters,
    PREFIX_FOR_TRANSIENT_PROPS: PREFIX_FOR_TRANSIENT_PROPS, // this should be used in other HarnessObjects as opposed to harcoding the prefix
    fieldSeparator: fieldSeparator, // this could be used in objects that implement custom ser/deSer
  }
})()

export default Serializer
