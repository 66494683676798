import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import '../../styles/ActivityView.scss'
import '../../styles/VerticalCarousel.scss'
import {useAuthContext} from '../auth'
import {ActivityType} from '../enums'
import DocumentActivityView from './DocumentActivityView'
import InteractiveActivityView from './InteractiveActivityView'
import VideoActivityView from './video/VideoActivityView'
import QuizActivityView from './quiz/QuizActivityView'

const ActivityView = ({nextActivity, handleOnNextClick, activityName}) => {
  const {type, id} = useParams()
  const {appUser} = useAuthContext()

  const renderContent = () => {
    if (appUser) {
      switch (parseInt(type)) {
        case ActivityType.DIGITAL_WHITEBOARD:
          return (
            <DocumentActivityView
              activityId={id}
              appUser={appUser}
              nextActivity={nextActivity}
              handleOnNextClick={handleOnNextClick}
            />
          )
        case ActivityType.QUIZ:
          return (
            <QuizActivityView
              activityId={id}
              appUser={appUser}
              name={activityName}
              nextActivity={nextActivity}
              handleOnNextClick={handleOnNextClick}
            />
          )
        case ActivityType.VIDEO:
          return (
            <VideoActivityView
              activityId={id}
              appUser={appUser}
              name={activityName}
              nextActivity={nextActivity}
              handleOnNextClick={handleOnNextClick}
            />
          )
        case ActivityType.INTERACTIVE:
          return (
            <InteractiveActivityView
              activityId={id}
              appUser={appUser}
              nextActivity={nextActivity}
              handleOnNextClick={handleOnNextClick}
            />
          )
        default:
          return null
      }
    } else {
      return (
        <div className={'flexCentered background fullHeight'}>
          <span>Fetching...</span>
        </div>
      )
    }
  }

  return <div className="activity-view-container">{renderContent()}</div>
}

export default ActivityView
