// The ApplicationSettings ENUM mirrors values in the Application_Settings table. MANIPULATE WITH CARE!

const ApplicationSettingType = {
  // COMMON
  ACCESS_MANAGEMENT_CONFIGURATION: 1,
  CONTACT_US_NEEDED: 10,
  CONTACT_US_TEXT: 11,
  CONTACT_US_VALUE: 12,
  CLIENT_TIME_ZONE: 13,
  ALLOW_OFFLINE_ACCESS: 15,
  MORE_OPTIONS_MENU_NEEDED: 22,
  MORE_OPTIONS_MENU_TEXT: 23,
  MORE_OPTIONS_MENU_VALUE: 24,
  TOKEN_EXPIRY_DURATION: 26,
  PASSWORD_COMPLEXITY: 27,
  ALLOWED_LOGIN_ATTEMPT_FAILURES: 31,
  SECURITY_SETTINGS: 41,
  PEOPLE_EVALUATION_CONFIGURATION: 42,

  // CORPORATE FLOW
  LEARNTRON_V1_JOIN_SESSION_URL: 2,
  DEFAULT_CLASSROOM_SURVEY_TEMPLATE: 29,
  SELF_SERVICE_ENABLED: 30,
  PASSWORD_SALTED: 32,
  CLIENT_ENTITLEMENTS: 33,
  LANDING_PAGE_WIDGET_CONFIGURATION: 34,
  DISPLAY_MODULE_IMAGES: 35,
  OPEN_LIVE_CLASS_IN_IFRAME: 36,
  LEADERBOARD_CONFIGURATION: 18,
  SPOC_CONFIG: 37,
  MODULE_CERTIFICATE_PREFERENCES: 38,
  NLP_SEARCH_CONFIGURATION: 39,
  FLASHCARD_CONFIGURATION: 43,
  MODULES_CONFIGURATION: 44,
  TEXT_TO_SPEECH_CONFIGURATION: 45,
  LEARNING_WALLET_CONFIGURATION: 46,

  // EDUCATION FLOW
  DISALLOW_STUDENTS_CHANGING_PASSWORD: 3,
  DISALLOW_STUDENTS_CHANGING_PROFILE: 4,
  DISALLOW_TEACHERS_CHANGING_PASSWORD: 5,
  DISALLOW_TEACHERS_CHANGING_PROFILE: 6,
  DAILY_USAGE_REPORT_EMAIL_RECIPIENTS: 7,
  WEEKLY_USAGE_REPORT_EMAIL_RECIPIENTS: 8,
  END_OF_CLASS_REPORT_EMAIL_RECIPIENTS: 9,
  API_SOCKET_SERVER_URL: 14, // MOVE TO COMMON LATER
  GRADEBOOK_CONFIGURATION: 28,
  OVERALL_COURSE_ANALYTICS: 40,

  RESTRICT_VIDEO_VIEWS: 19,
}

/*export const ApplicationSettingValueType = {
  bool: 1,
  string: 2,
  object: 3,
  array: 4,
  number: 5,
}*/

export default ApplicationSettingType
