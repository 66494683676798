class ActivityInteractiveUsageAnalyticsModel {
  constructor(config) {
    this.isCompleted = false
    this.completionStationIdentifier = 'cmi.core.lesson_status'
    this.userIdIdentifier = 'cmi.core.student_id'
    this.userNameIdentifier = 'cmi.core.student_name'
    this.userId = config.userId
    this.userName = config.userName
    this.isScrom = config.isScrom
    this.isAnimatedPpt = config.isAnimatedPpt
    this.lastUsageAnalytics = this.getLastUsageAnalytics(
      config.previousUsageAnalytics,
    )

    this.currentUsageAnalytics =
      config.currentUsageAnalytics || this.getBaseUsageAnalyticsObject() // int array
  }

  getLastUsageAnalytics(analyticsCollection) {
    if ((this.isScrom || this.isAnimatedPpt) && analyticsCollection) {
      const items = analyticsCollection.filter(function (metaData) {
        return (
          typeof metaData === 'string' &&
          metaData !== '{}' &&
          metaData !== '' &&
          Boolean(JSON.parse(metaData)['cmi'])
        )
      })

      if (items && items.length > 0) {
        const val = items[items.length - 1]
        return typeof val === 'string' ? JSON.parse(val) : val
      }
    }
    return null
  }

  getBaseUsageAnalyticsObject() {
    if (this.isAnimatedPpt) {
      return this.lastUsageAnalytics || {}
    }
    if (this.isScrom) {
      return (
        this.lastUsageAnalytics || {
          cmi: {
            _version: '1.0',
            comments_from_learner: {
              _children: 'comment,location,timestamp',
              _count: '0',
            },
            comments_from_lms: {
              _children: 'comment,location,timestamp',
              _count: '0',
            },
            completion_status: 'unknown',
            completion_threshold: '0',
            credit: 'credit',
            entry: 'ab-initio',
            exit: '',
            interactions: {
              _children:
                'id,type,objectives,timestamp,correct_responses,weighting,learner_response,result,latency,description',
              _count: '0',
            },
            launch_data: '',
            learner_id: this.userId,
            learner_name: this.userName,
            learner_preference: {
              _children: 'audio_level,language,delivery_speed,audio_captioning',
              audio_level: '',
              language: '',
              delivery_speed: '',
              audio_captioning: '',
            },
            location: '',
            max_time_allowed: '',
            mode: 'normal',
            objectives: {
              _children:
                'id,score,success_status,completion_status,description',
              _count: '0',
            },
            progress_measure: '0',
            scaled_passing_score: '0',
            score: {
              _children: 'scaled,raw,min,max',
              scaled: '0',
              raw: '0',
              min: '0',
              max: '0',
            },
            session_time: 'PT0H0M0S',
            success_status: 'unknown',
            suspend_data: '',
            time_limit_action: '',
            total_time: 'PT0H0M0S',
          },
          adl: {
            nav: {
              request: '_none_',
              request_valid: {
                continue: 'false',
                previous: 'false',
                choice: {},
              },
            },
          },
          ssp: {
            allocate: '',
            _count: '0',
          },
        }
      )
    }
    return {}
  }

  getCurrentUsageAnalytics() {
    return this.currentUsageAnalytics
  }
  updatecurrentUsageAnalytics(data) {
    if (this.isScrom || this.isAnimatedPpt) {
      const usageAnalytics = typeof data === 'string' ? JSON.parse(data) : data
      if (
        usageAnalytics.type === 'disprz_scromupdate' &&
        usageAnalytics.studentData
      ) {
        console.log('postmessage', data)
        if (
          usageAnalytics.studentData.data &&
          usageAnalytics.studentData.data.cmi
        ) {
          const data = usageAnalytics.studentData.data
          data.cmi.learner_id = this.userId
          data.cmi.learner_name = this.userName
          this.currentUsageAnalytics = data
        }
      }
    } else {
      const usageAnalytics = typeof data === 'string' ? JSON.parse(data) : data
      usageAnalytics[this.userIdIdentifier] = this.userId
      usageAnalytics[this.userNameIdentifier] = this.userName
      this.currentUsageAnalytics = usageAnalytics
    }
  }
  isUsageComplete() {
    if (this.currentUsageAnalytics[this.completionStationIdentifier]) {
      return (
        this.currentUsageAnalytics[this.completionStationIdentifier] ===
          'passed' ||
        this.currentUsageAnalytics[this.completionStationIdentifier] ===
          'completed'
      )
    } else if (this.isScrom || this.isAnimatedPpt) {
      return (
        this.currentUsageAnalytics &&
        this.currentUsageAnalytics.cmi &&
        (this.currentUsageAnalytics.cmi.completion_status === 'completed' ||
          this.currentUsageAnalytics.cmi.completion_status === 'passed' ||
          this.currentUsageAnalytics.cmi.success_status === 'completed' ||
          this.currentUsageAnalytics.cmi.success_status === 'passed')
      )
    } else {
      // Hard-coded fix done here for RadioMirchi as their SCORM packages seem to fail on this block
      /*if (AppHelper.getAppUser().dbPointer === 'RadioMirchi') {
        return false
      }*/
      return true
    }
  }
}

export default ActivityInteractiveUsageAnalyticsModel
