import PropTypes from 'prop-types'
import { useEffect, useState } from 'react';
import {BackIcon} from '../../icons'

const CarouselNavigation = ({screenOrientation, totalPage, currentPageIndex, onNext, onPrev}) => {

  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    screenOrientation === 1 ? setBottom(70) : setBottom(85);
  }, [screenOrientation])

  return (
    <div className={'navigation-area'} style={{bottom: `${bottom}px`}}>
      <div
        className={`prev-button ${currentPageIndex === 0 && 'disabled'}`}
        onClick={() => {
          if (currentPageIndex !== 0) {
            onPrev()
          }
        }}>
        <BackIcon />
      </div>
      <div
        className={`next-button ${
          currentPageIndex === totalPage - 1 && 'disabled'
        } `}
        onClick={() => {
          if (currentPageIndex !== totalPage - 1) {
            onNext()
          }
        }}>
        <BackIcon />
      </div>
    </div>
  )
}

CarouselNavigation.propTypes = {}

export default CarouselNavigation
